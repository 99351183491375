import { get, post, put, deleteRequest } from '../utils/api/request';
import URLS from '../constants/urls';

const getEndpoints = token => get(URLS.API_ENDPOINTS, null, token);

const getEndpoint = (endpointId, token) => get(`${URLS.API_ENDPOINTS}/${endpointId}`, null, token);

const createEndpoint = (endpoint, token) => post(URLS.API_ENDPOINTS, endpoint, token);

const updateEndpoint = (endpoint, token) => put(URLS.API_ENDPOINTS, endpoint, token);

const deleteEndpoint = (endpointId, token) =>
  deleteRequest(`${URLS.API_ENDPOINTS}/${endpointId}`, null, token);

const endpointsApi = {
  createEndpoint,
  deleteEndpoint,
  getEndpoint,
  getEndpoints,
  updateEndpoint,
};

export default endpointsApi;
