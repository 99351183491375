import {
  START_ENDPOINT_SCOPES_LOADING,
  ERROR_ENDPOINT_SCOPES_LOADING,
  SUCCESS_ENDPOINT_SCOPES_LOADING,
} from '../../actions/action-types';
import {
  createEntityReducer,
  errorEntityLoading,
  startEntityLoading,
  successEntityLoading,
} from '../../../utils/reducer/reducer-utils';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const resolvers = {
  [START_ENDPOINT_SCOPES_LOADING]: startEntityLoading,
  [ERROR_ENDPOINT_SCOPES_LOADING]: errorEntityLoading,
  [SUCCESS_ENDPOINT_SCOPES_LOADING]: successEntityLoading,
};

const endpointsReducer = createEntityReducer({
  resolvers,
  initialState,
})

export default endpointsReducer;
