import { SET_ROUTE_PARAMS } from '../action-types';

const setRouteParams = (params, entity) => ({
  type: SET_ROUTE_PARAMS,
  payload: { params, entity },
});

export {
  setRouteParams,
};
