import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {
  authEmailSelector,
  authErrorPersonalAccountSelector,
  authLoggedSelector,
  authLoginSelector,
  authRequestInProgressSelector,
} from '../../../redux/selectors';
import {
  getPersonalAccount as getPersonalAccountAction,
  savePersonalAccount as savePersonalAccountAction,
} from '../../../redux/actions/auth';
import formModes from '../../../constants/form-modes';
import TextInput from '../../controls/TextInput';
import ChangePasswordModal from './ChangePasswordModal';

const { EDIT, NONE } = formModes;

const initialValidationData = {
  login: '',
  email: '',
};

const initialState = {
  login: '',
  email: '',
};

const PersonalAccount = ({
  email,
  error,
  getPersonalAccount,
  logged,
  login,
  requestInProgress,
  savePersonalAccount,
}) => {
  const [validationData, setValidationData] = useState({ ...initialValidationData });
  const [state, setState] = useState({ ...initialState, login, email });
  const [mode, setMode] = useState(NONE);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);

  useEffect(() => {
    getPersonalAccount(data => {
      setState({ login: data.login, email: data.email });
    });
  }, [getPersonalAccount]);

  const handleChangePassword = useCallback(() => {
    setChangePasswordOpen(true);
  }, []);

  const handleChangePasswordClose = useCallback(() => {
    setChangePasswordOpen(false);
  }, []);

  const handleEdit = useCallback(() => {
    setMode(EDIT);
  }, []);

  const handleCancel = useCallback(() => {
    setMode(NONE);
    getPersonalAccount(data => {
      setState({ ...initialState, ...data });
      setValidationData({ ...initialValidationData });
    });
  }, [getPersonalAccount]);

  const handleChange = useCallback(({ target: { value, name } }) => {
    setState({ ...state, [name]: value });
  }, [state]);

  const handleSave = useCallback(() => {
    savePersonalAccount(state, result => {
      if (result && (result.valid === false)) {
        setValidationData(result.data);
      } else {
        setValidationData({ ...initialValidationData });
        setMode(NONE);
      }
    });
  }, [savePersonalAccount, state]);

  const getTextInput = useCallback((field, label, type = 'text') => {
    const disabled = requestInProgress || (mode === NONE);

    return (
      <TextInput
        disabled={disabled}
        id={field}
        label={label}
        onChange={handleChange}
        type={type}
        validationMessage={validationData[field]}
        value={state[field]}
      />
    )}, [handleChange, mode, requestInProgress, state, validationData]);

  const loginField = useMemo(() => (
    getTextInput('login', 'Login')
  ), [getTextInput]);

  const emailField = useMemo(() => (
    getTextInput('email', 'E-mail', 'email')
  ), [getTextInput]);

  return (
    <>
      <Card sx={{ width: '100%', maxWidth: 500, m: '0 auto', p: 2 }}>
        <form action="" name="account">
          <CardContent>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Personal account
            </Typography>
            <Grid container spacing={2}>
              {requestInProgress && <div>Loading...</div>}
              {!requestInProgress && error && <div>Error: {error.message}</div>}
              {logged && !(requestInProgress || error) && [
                <Grid key="login" item xs={12}>
                  {loginField}
                </Grid>,
                <Grid key="email" item xs={12}>
                  {emailField}
                </Grid>
              ]}
              {!logged && !requestInProgress && (
                <div>Please, sign in</div>
              )}
            </Grid>
          </CardContent>
          <CardActions sx={{ mt: 3 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                {mode === NONE && (
                  <Button
                    aria-label="Change password"
                    color="primary"
                    disableElevation
                    size="small"
                    onClick={handleChangePassword}
                    disabled={false}
                    sx={{ mr: 2 }}
                    variant="text"
                  >
                    Change password
                  </Button>
                )}
              </Box>
              <Box>
                {mode === NONE && (
                  <Button
                    aria-label="Edit"
                    color="primary"
                    disableElevation
                    size="small"
                    onClick={handleEdit}
                    disabled={false}
                    sx={{ mr: 2 }}
                    variant="contained"
                  >
                    Edit
                  </Button>
                )}
                {mode === EDIT && [
                  <Button
                    key="cancel"
                    aria-label="Cancel"
                    color="inherit"
                    disableElevation
                    size="small"
                    onClick={handleCancel}
                    disabled={false}
                    sx={{ mr: 2 }}
                    variant="contained"
                  >
                    Cancel
                  </Button>,
                  <Button
                    key="save"
                    aria-label="Save"
                    color="primary"
                    disableElevation
                    size="small"
                    onClick={handleSave}
                    disabled={false}
                    sx={{ mr: 2 }}
                    variant="contained"
                  >
                    Save
                  </Button>
                ]}
              </Box>
            </Grid>
          </CardActions>
        </form>
      </Card>
      <ChangePasswordModal
        open={changePasswordOpen}
        onClose={handleChangePasswordClose}
      />
    </>
  );
};

const mapStateToProps = state => ({
  login: authLoginSelector(state),
  logged: authLoggedSelector(state),
  email: authEmailSelector(state),
  error: authErrorPersonalAccountSelector(state),
  requestInProgress: authRequestInProgressSelector(state),
});

const mapDispatchToProps = {
  getPersonalAccount: getPersonalAccountAction,
  savePersonalAccount: savePersonalAccountAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalAccount);
