import {
  START_UNIT_PHRASES_LOADING,
  ERROR_UNIT_PHRASES_LOADING,
  SUCCESS_UNIT_PHRASES_LOADING,
  START_UNIT_PHRASES_SAVING,
  ERROR_UNIT_PHRASES_SAVING,
  SUCCESS_UNIT_PHRASES_SAVING,
} from '../../actions/action-types';

const initialState = {
  unitUrl: '',
  data: null,
  loading: false,
  error: null,
  saving: false,
  savingError: null,
};

const unitPhrasesReducer = (state = { ...initialState }, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case START_UNIT_PHRASES_LOADING:
      return {
        ...state,
        data: null,
        error: null,
        loading: true,
        unitUrl: payload,
      };

    case ERROR_UNIT_PHRASES_LOADING:
      return {
        ...state,
        data: null,
        error: payload,
        loading: false,
      };

    case SUCCESS_UNIT_PHRASES_LOADING:
      return {
        ...state,
        data: payload,
        error: null,
        loading: false,
      };

    case START_UNIT_PHRASES_SAVING:
      return {
        ...state,
        saving: true,
      };

    case ERROR_UNIT_PHRASES_SAVING:
      return {
        ...state,
        saving: false,
        savingError: payload,
      };

    case SUCCESS_UNIT_PHRASES_SAVING:
      return {
        ...state,
        saving: false,
        savingError: null,
      };

    default:
      return state;
  }
};

export default unitPhrasesReducer;
