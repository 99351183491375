import { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import TileCard from '../../../TileCard';
import {
  scopeIdSelector,
  scopeNameSelector,
  scopeValueSelector,
  scopeDescriptionSelector,
} from '../../../../redux/selectors';
import {
  deleteScope,
  getScopes,
  openConfirmationModal as openConfirmationModalAction,
  saveScope,
} from '../../../../redux/actions';
import { useAdminScopeContext } from './admin-scopes-context';
import FormModes from '../../../../constants/form-modes';
import URLS from '../../../../constants/urls';

const initialState = {
  value: '',
  name: '',
  description: '',
};

const initialValidationData = {
  value: '',
  name: '',
  description: '',
};

const AdminScopesForm = props => {
  const {
    deleteItem,
    description,
    id,
    name,
    openConfirmationModal,
    refreshScopes,
    saveData,
    value,
  } = props;
  const navigate = useNavigate();
  const [state, setState] = useState({ ...initialState });
  const [validationData, setValidationData] = useState(initialValidationData);
  const { scopeUrl } = useParams();
  const {
    mode,
    handleEdit: onEdit,
    handleCancel: onCancel,
  } = useAdminScopeContext();

  const disabled = mode === FormModes.NONE;

  const handleEdit = useCallback(() => {
    onEdit();
    setState({ name, value, description });
  }, [name, value, description, onEdit]);

  const handleCancel = useCallback(() => {
    onCancel();
    setState({ ...initialState });
    if (scopeUrl === 'new') {
      navigate(`${URLS.ADMINISTRATION}/scopes`);
    }
  }, [navigate, onCancel, scopeUrl]);

  const handleSave = useCallback(() => {
    const scope = {
      name: state.name,
      value: state.value,
      description: state.description,
      ...(id && { id }),
    };
    saveData(scope, result => {
      if (result && result.valid === false) {
        setValidationData(result.data);
      } else {
        setValidationData({ ...initialValidationData });
        refreshScopes();
        onCancel();
        setState({ ...initialState });
        navigate(`${URLS.ADMINISTRATION}/scopes/${state.value}`);
      }
    });
  }, [id, navigate, onCancel, refreshScopes, saveData, state]);

  const handleDelete = useCallback( () => {
    openConfirmationModal({
      title: `Delete scope ${value}`,
      message: `Please confirm that you are going to delete scope ${value}?`,
      action: () => {
        deleteItem(value, () => {
          refreshScopes();
          onCancel();
          navigate(`${URLS.ADMINISTRATION}/scopes`);
        });
      },
    });
  }, [deleteItem, navigate, onCancel, openConfirmationModal, refreshScopes, value]);

  const handleChange = useCallback(({ target: { name, value } }) => {
    setState({ ...state, [name]: value });
  }, [setState, state]);

  const handleSubmit = useCallback(e => {
    e.preventDefault();
  }, []);

  const getTextField = useCallback((field, label) => (
    <TextField
      error={!!validationData[field]}
      disabled={disabled}
      fullWidth
      id={field}
      label={label}
      helperText={validationData[field] ? validationData[field] : ''}
      name={field}
      onChange={handleChange}
      variant="standard"
      value={(mode === FormModes.NONE ? props[field] : state[field]) || ''}
    />
  ), [disabled, handleChange, mode, props, state, validationData]);

  const nameTextField = useMemo(() => (
    getTextField('name', 'Name')
  ), [getTextField]);

  const valueTextField = useMemo(() => (
    getTextField('value', 'Value')
  ), [getTextField]);

  const descriptionTextField = useMemo(() => (
    getTextField('description', 'Description')
  ), [getTextField]);

  return (
    <TileCard sx={{ height: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flow: '0 1 auto' }}>
        <Typography variant="h6">
          {name || 'Scope'}
        </Typography>
        {mode === FormModes.NONE && (
          <Box>
            <Fab
              aria-label="Edit"
              color="primary"
              size="small"
              onClick={handleEdit}
              disabled={scopeUrl === 'new'}
            >
              <EditIcon />
            </Fab>
          </Box>
        )}
        {mode !== FormModes.NONE && (
          <Box>
            <Fab
              aria-label="Cancel"
              color="default"
              size="small"
              onClick={handleCancel}
              sx={{ mr: 1 }}
            >
              <CancelIcon />
            </Fab>
            <Fab
              aria-label="Delete"
              color="error"
              size="small"
              onClick={handleDelete}
              disabled={scopeUrl === 'new'}
              sx={{ mr: 1 }}
            >
              <RemoveCircleIcon />
            </Fab>
            <Fab
              aria-label="Save"
              color="success"
              size="small"
              onClick={handleSave}
              disabled={false}
            >
              <SaveIcon />
            </Fab>
          </Box>
        )}
      </Grid>
      <CardContent
        sx={{
          flex: '1 1 auto',
          height: 'calc(100% - 36px)',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        <form action="" name="unit" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {nameTextField}
            </Grid>
            <Grid item xs={12}>
              {valueTextField}
            </Grid>
            <Grid item xs={12}>
              {descriptionTextField}
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </TileCard>
  );
};

const mapStateToProps = state => ({
  id: scopeIdSelector(state),
  name: scopeNameSelector(state),
  value: scopeValueSelector(state),
  description: scopeDescriptionSelector(state),
});

const mapDispatchToProps = {
  openConfirmationModal: openConfirmationModalAction,
  deleteItem: deleteScope,
  refreshScopes: getScopes,
  saveData: saveScope,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminScopesForm);
