import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Fab from '@mui/material/Fab';
// import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
// import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TESTS from '../../../constants/tests';

const TESTS_LIST = Object.keys(TESTS)
  .map(key => ({ value: key, ...TESTS[key] }))
  .sort((p1, p2) => p1.index - p2.index);

const StatisticsHome = () => {
  const navigate = useNavigate();

  const handlePartitionClick = useCallback(value => () => {
    navigate(`/statistics/${value}`);
  }, [navigate]);

  const items = useMemo(() => {
    return TESTS_LIST.map(({ value, label, icon: Icon }) => (
      <Fab
        key={value}
        variant="extended"
        color="secondary"
        sx={{ m: 1 }}
        onClick={handlePartitionClick(value)}
      >
        <Icon sx={{ mr: 1 }} />
        {label}
      </Fab>
    ));
  }, [handlePartitionClick]);

  return (
    <div>{items}</div>
  );
};

export default StatisticsHome;
