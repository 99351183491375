import { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SaveIcon from '@mui/icons-material/Save';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import TileCard from '../../../TileCard';
import {
  phraseIdSelector,
  phraseUrlSelector,
  phraseEnQuestionSelector,
  phraseRuSelector,
  phraseAnswersSelector,
} from '../../../../redux/selectors';
import {
  deletePhrase,
  getPhrases,
  openConfirmationModal as openConfirmationModalAction,
  savePhrase,
} from '../../../../redux/actions';
import { useAdminPhrasesContext } from './admin-phrases-context';
import FormModes from '../../../../constants/form-modes';
import URLS from '../../../../constants/urls';
import InputsStack from '../../../controls/InputsStack';

const initialState = {
  answers: [],
  enQuestion: '',
  ru: '',
  url: '',
};

const initialValidationData = {
  enQuestion: '',
  answers: '',
  ru: '',
  url: '',
};

const AdminPhraseForm = props => {
  const {
    deleteItem,
    answers,
    enQuestion,
    id,
    openConfirmationModal,
    refreshPhrases,
    ru,
    saveData,
    url,
  } = props;
  const navigate = useNavigate();
  const [state, setState] = useState({ ...initialState });
  const [validationData, setValidationData] = useState({ ...initialValidationData });
  const { phraseUrl } = useParams();
  const {
    mode,
    handleEdit: onEdit,
    handleCancel: onCancel,
  } = useAdminPhrasesContext();

  const handleEdit = useCallback(() => {
    onEdit();
    setState({ url, enQuestion, ru, answers });
  }, [onEdit, url, enQuestion, ru, answers]);

  const handleClone = useCallback(() => {
    navigate(`${URLS.ADMINISTRATION}/phrases/new`);
    setState({ enQuestion, ru, url, answers });
  }, [answers, enQuestion, navigate, ru, url]);

  const handleCancel = useCallback(() => {
    onCancel();
    setState({ ...initialState });
    if (phraseUrl === 'new') {
      navigate(`${URLS.ADMINISTRATION}/phrases`);
    }
  }, [navigate, onCancel, phraseUrl]);

  const handleSave = useCallback(() => {
    const phrase = {
      url: state.url,
      enQuestion: state.enQuestion,
      ru: state.ru,
      answers: state.answers,
      ...(id && { id }),
    };
    saveData(phrase, result => {
      if (result && result.valid === false) {
        setValidationData(result.data);
      } else {
        setValidationData({ ...initialValidationData });
        setState({ ...initialState });
        refreshPhrases();
        onCancel();
        navigate(`${URLS.ADMINISTRATION}/phrases/${state.url}`);
      }
    });
  }, [id, navigate, onCancel, refreshPhrases, saveData, state]);

  const handleDelete = useCallback( () => {
    openConfirmationModal({
      title: `Delete phrase`,
      message: `Please confirm that you are going to delete phrase ${ru}?`,
      action: () => {
        deleteItem(url, () => {
          refreshPhrases();
          onCancel();
          navigate(`${URLS.ADMINISTRATION}/phrases`);
        });
      },
    });
  }, [deleteItem, navigate, onCancel, openConfirmationModal, refreshPhrases, ru, url]);

  const handleChange = useCallback(({ target: { name, value } }) => {
    setState({ ...state, [name]: value });
  }, [setState, state]);

  const handleAnswersChange = useCallback(newAnswers => {
    setState({ ...state, answers: newAnswers });
  }, [state]);

  const handleSubmit = useCallback(e => {
    e.preventDefault();
  }, []);

  const disabled = mode === FormModes.NONE;

  const getTextField = useCallback((field, label) => (
    <TextField
      error={!!validationData[field]}
      disabled={disabled}
      fullWidth
      id={field}
      label={label}
      helperText={validationData[field] || ''}
      name={field}
      onChange={handleChange}
      variant="standard"
      value={(mode === FormModes.NONE ? props[field] : state[field]) || ''}
    />
  ), [disabled, handleChange, mode, props, state, validationData]);

  const enQuestionTextField = useMemo(
    () => getTextField('enQuestion', 'English words'),
    [getTextField]
  );

  const ruTextField = useMemo(
    () => getTextField('ru', 'Russian phrase'),
    [getTextField]
  );

  const enAnswers = useMemo(() => (
      <InputsStack
        disabled={disabled}
        label="Answers"
        value={mode === FormModes.NONE ? answers : state.answers || []}
        onChange={handleAnswersChange}
      />
    ), [answers, disabled, handleAnswersChange, mode, state.answers]);

  const urlTextField = useMemo(
    () => getTextField('url', 'URL'),
    [getTextField]
  );

  return (
    <TileCard sx={{ height: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flow: '0 1 auto' }}>
        <Typography variant="h6">
          {ru || 'Phrase'}
        </Typography>
        {mode === FormModes.NONE && (
          <Box>
            <Tooltip title="Edit">
              <Fab
                aria-label="Edit"
                color="primary"
                size="small"
                onClick={handleEdit}
                disabled={phraseUrl === 'new'}
                sx={{ mr: 1 }}
              >
                <EditIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Clone">
              <Fab
                aria-label="Clone"
                color="primary"
                size="small"
                onClick={handleClone}
                disabled={phraseUrl === 'new'}
              >
                <FileCopyIcon />
              </Fab>
            </Tooltip>
          </Box>
        )}
        {mode !== FormModes.NONE && (
          <Box>
            <Tooltip title="Cancel">
              <Fab
                aria-label="Cancel"
                color="default"
                size="small"
                onClick={handleCancel}
                sx={{ mr: 1 }}
              >
                <CancelIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Delete">
              <Fab
                aria-label="Delete"
                color="error"
                size="small"
                onClick={handleDelete}
                disabled={phraseUrl === 'new'}
                sx={{ mr: 1 }}
              >
                <RemoveCircleIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Save">
              <Fab
                aria-label="Save"
                color="success"
                size="small"
                onClick={handleSave}
                disabled={false}
              >
                <SaveIcon />
              </Fab>
            </Tooltip>
          </Box>
        )}
      </Grid>
      <CardContent
        sx={{
          flex: '1 1 auto',
          height: 'calc(100% - 36px)',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        <form action="" name="phrase" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {enQuestionTextField}
            </Grid>
            <Grid item xs={12}>
              {ruTextField}
            </Grid>
            <Grid item xs={12}>
              {enAnswers}
            </Grid>
            <Grid item xs={12}>
              {urlTextField}
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </TileCard>
  );
};

const mapStateToProps = state => ({
  id: phraseIdSelector(state),
  url: phraseUrlSelector(state),
  enQuestion: phraseEnQuestionSelector(state),
  ru: phraseRuSelector(state),
  answers: phraseAnswersSelector(state),
});

const mapDispatchToProps = {
  openConfirmationModal: openConfirmationModalAction,
  deleteItem: deletePhrase,
  refreshPhrases: getPhrases,
  saveData: savePhrase,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPhraseForm);
