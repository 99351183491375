import {
  START_ENDPOINT_SCOPES_LOADING,
  ERROR_ENDPOINT_SCOPES_LOADING,
  SUCCESS_ENDPOINT_SCOPES_LOADING,
} from '../action-types';
import { authTokenSelector } from '../../selectors';
import { addMessage } from '../messages';
import { createErrorMessage } from '../../../utils/messages/messages-utils';
import { logout } from '../auth';

const startEndpointScopesLoading = () => ({
  type: START_ENDPOINT_SCOPES_LOADING,
});

const errorEndpointScopesLoading = error => ({
  type: ERROR_ENDPOINT_SCOPES_LOADING,
  payload: error,
});

const successEndpointScopesLoading = payload => ({
  type: SUCCESS_ENDPOINT_SCOPES_LOADING,
  payload,
});

const getEndpointScopes = (endpointId, cbSuccess = () => {}) =>
  async (dispatch, getState, api) => {
    dispatch(startEndpointScopesLoading());
    const token = authTokenSelector(getState());
    try {
      const endpoint = await api.endpoints.getEndpoint(endpointId, token);
      dispatch(successEndpointScopesLoading(endpoint.scopes));
      cbSuccess(endpoint.scopes);
    } catch (e) {
      dispatch(errorEndpointScopesLoading(e));
      dispatch(addMessage(createErrorMessage('Can not fetch endpoint data')));
      if (e.statusCode === 401) {
        await logout()(dispatch, getState, api);
      }
    }
  };

export {
  getEndpointScopes,
};
