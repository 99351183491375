import { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import FormInputControl from '../FormInputControl';
import { isFilledArray } from '../../../utils/array';

/**
 * @param {boolean} disabled
 * @param {string} label
 * @param {string[]} value - a list of strings
 * @param {Function} onChange - changes handler
 */
const InputsStack = ({
  disabled,
  label,
  value,
  onChange,
}) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value);
  }, [value]);

  const handleBlur = useCallback(() => {
    onChange(state);
  }, [onChange, state]);

  const handleChange = useCallback(({ target: { value: tValue, id } }) => {
    const newState = isFilledArray(state) ? state.slice() : [''];
    newState[id] = tValue;
    setState(newState);
  }, [state]);

  const handleAdd = useCallback(id => () => {
    const newState = isFilledArray(state) ? state.slice() : [''];
    newState.splice(id + 1, 0, '');
    setState(newState);
  }, [state]);

  const handleRemove = useCallback(id => () => {
    if (isFilledArray(state) && state.length > 1) {
      const newState = state.slice();
      newState.splice(id, 1);
      setState(newState);
    }
  }, [state]);

  const items = useMemo(() => {
    return isFilledArray(state)
      ? state.map((s, i) => (
        <Box sx={{
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <FormInputControl
            disabled={disabled}
            id={`${i}`}
            key={i}
            label={i === 0 ? label : undefined}
            value={s || ''}
            onChange={handleChange}
            inputProps={{
              onBlur: handleBlur,
            }}
          />
          {!disabled && (
            <Box sx={{
              display: 'flex',
              flexFlow: 'row nowrap',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
              <IconButton size="small" onClick={handleAdd(i)}>
                <AddCircleIcon fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={handleRemove(i)}>
                <DeleteForeverIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
      ))
      : (
        <Box>
          <FormInputControl
            disabled={disabled}
            id="0"
            key="0"
            label={label}
            value={''}
            onChange={handleChange}
            inputProps={{
              onBlur: handleBlur,
            }}
          />
        </Box>
      )
  }, [disabled, handleAdd, handleBlur, handleChange, handleRemove, label, state]);

  return (
    <Box>
      <Stack spacing={2}>
        {items}
      </Stack>
    </Box>
  );
};

export default InputsStack;
