import { createSelector } from 'reselect';

const unitWordsSelector = state => state.unitWords;

const unitWordsDataSelector = state => unitWordsSelector(state).data;

const unitWordsLoadingSelector = state => unitWordsSelector(state).loading;

const unitWordsErrorSelector = state => unitWordsSelector(state).error;

const unitWordsUnitUrlSelector = state => unitWordsSelector(state).unitUrl;

const unitWordsDataLoadedSelector = createSelector(
  [
    unitWordsLoadingSelector,
    unitWordsErrorSelector,
    unitWordsDataSelector,
  ],
  (loading, error, data) => (
    !(loading || error) && !!data
  ),
);

export {
  unitWordsDataSelector,
  unitWordsLoadingSelector,
  unitWordsErrorSelector,
  unitWordsDataLoadedSelector,
  unitWordsUnitUrlSelector,
};
