import { createSelector } from 'reselect';
import { routerParamsSelector } from '../router';
import { entityFieldSelector } from '../common-selectors';

const wordsSelector = state => state.words;

const wordsLoadingSelector = state => wordsSelector(state).loading;

const wordsErrorSelector = state => wordsSelector(state).error;

const wordsDataSelector = state => wordsSelector(state).data;

const wordSelector = createSelector(
  [wordsDataSelector, routerParamsSelector],
  (data, params) => {
    if (Array.isArray(data) && params && params.wordUrl) {
      return  data.find(({ url }) => url === params.wordUrl);
    } else {
      return null;
    }
  },
);

const wordIdSelector = entityFieldSelector('id', wordSelector, '');

const wordUrlSelector = entityFieldSelector('url', wordSelector, '');

const wordEnSelector = entityFieldSelector('en', wordSelector, '');

const wordRuSelector = entityFieldSelector('ru', wordSelector, '');

const wordTranscriptionSelector = entityFieldSelector(
  'transcription',
  wordSelector,
  '',
);

export {
  wordsLoadingSelector,
  wordsErrorSelector,
  wordsDataSelector,
  wordSelector,
  wordIdSelector,
  wordUrlSelector,
  wordEnSelector,
  wordRuSelector,
  wordTranscriptionSelector,
};
