import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';

const InputField = ({
  disabled,
  id,
  label,
  onChange,
  value,
  ...rest
}) => {
  return (
    <FormControl variant="standard" disabled={disabled} fullWidth>
      {label && (
        <InputLabel htmlFor={id}>{label}</InputLabel>
      )}
      <Input id={id} value={value} onChange={onChange} {...rest} />
    </FormControl>
  );
};

InputField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default InputField;
