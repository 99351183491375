import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Fab from '@mui/material/Fab';

import TESTS from '../../../../constants/tests';
import sortByIndex from '../../../../utils/sort-by-index';
import Topic from '../../../Topic';

const TEST_LIST = sortByIndex(TESTS);

const TestTypes = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleTestClick = useCallback(value => () => {
    navigate(`${pathname}/${value}`);
  }, [navigate, pathname]);

  const buttons = useMemo(() => {
    return TEST_LIST.map(({ label, value, icon: Icon }) => {
      return (
        <Fab
          key={value}
          variant="extended"
          color="secondary"
          sx={{ m: 1 }}
          onClick={handleTestClick(value)}
        >
          <Icon sx={{ mr: 1 }} />
          {label}
        </Fab>
      )
    })
  }, [handleTestClick]);

  return (
    <Topic header="Tests">
      {buttons}
    </Topic>
  );
};

export default TestTypes;
