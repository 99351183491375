const statisticsSelector = state => state.statistics;

const statisticsRequestInProgressSelector = state =>
  statisticsSelector(state).requestInProgress;

const statisticsErrorSelector = state =>
  statisticsSelector(state).error;

const statisticsDataSelector = state =>
  statisticsSelector(state).data;

export {
  statisticsRequestInProgressSelector,
  statisticsErrorSelector,
  statisticsDataSelector,
};
