import { createSelector } from 'reselect';
import { routerParamsSelector } from '../router';

const endpointsSelector = state => state.endpoints;

const endpointsDataSelector = state => endpointsSelector(state).data;

const endpointsLoadingSelector = state => endpointsSelector(state).loading;

const endpointsErrorSelector = state => endpointsSelector(state).error;

const endpointSelector = createSelector(
  [endpointsDataSelector, routerParamsSelector],
  (data, params) => {
    if (Array.isArray(data) && params?.endpointUrl) {
      return  data.find(({ adminPageUrl }) => adminPageUrl === params.endpointUrl);
    } else {
      return null;
    }
  },
);

const endpointIdSelector = createSelector(
  [endpointSelector],
  endpoint => endpoint ? endpoint.id : '',
);

const endpointUrlSelector = createSelector(
  [endpointSelector],
  endpoint => endpoint ? endpoint.url : '',
);

const endpointMethodSelector = createSelector(
  [endpointSelector],
  endpoint => endpoint ? endpoint.method : '',
);

const endpointAdminPageUrlSelector = createSelector(
  [endpointSelector],
  endpoint => endpoint ? endpoint.adminPageUrl : '',
);

export {
  endpointsDataSelector,
  endpointsErrorSelector,
  endpointsLoadingSelector,
  endpointAdminPageUrlSelector,
  endpointIdSelector,
  endpointMethodSelector,
  endpointSelector,
  endpointUrlSelector,
};
