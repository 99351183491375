const endpointScopesSelector = state => state.endpointScopes;

const endpointScopesDataSelector = state => endpointScopesSelector(state).data;

const endpointScopesLoadingSelector = state => endpointScopesSelector(state).loading;

const endpointScopesErrorSelector = state => endpointScopesSelector(state).error;

export {
  endpointScopesDataSelector,
  endpointScopesErrorSelector,
  endpointScopesLoadingSelector,
};
