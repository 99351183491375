import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { authLoggedSelector} from '../../../../redux/selectors';
import { useCallback } from 'react';
import URLS from '../../../../constants/urls';
import { logout as logoutAction } from '../../../../redux/actions/auth';

const AccountMenu = ({
  anchorEl,
  id = 'account-menu',
  logged,
  logout,
  onClose,
}) => {
  const navigate = useNavigate();

  const handleLogIn = useCallback(() => {
    onClose();
    navigate(URLS.LOGIN);
  }, [navigate, onClose]);

  const handleLogout = useCallback(() => {
    onClose();
    logout(() => {
      navigate(`${URLS.HOME}`);
    });
  }, [logout, navigate, onClose]);

  const handleAccount = useCallback(() => {
    onClose();
    navigate(URLS.PERSONAL_ACCOUNT);
  }, [navigate, onClose]);

  return (
  <Menu
    id={id}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    keepMounted
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    open={Boolean(anchorEl)}
    onClose={onClose}
  >
    {logged && ([
      <MenuItem key="account" divider onClick={handleAccount}>My account</MenuItem>,
      <MenuItem key="logout" onClick={handleLogout}>Logout</MenuItem>
    ])}
    {!logged && (
      <MenuItem key="login" onClick={handleLogIn}>Log in</MenuItem>
    )}
  </Menu>
)};

AccountMenu.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  id: PropTypes.string,
  logged: PropTypes.bool,
};

const mapStateToProps = state => ({
  logged: authLoggedSelector(state),
});

const mapDispatchToProps = {
  logout: logoutAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
