import {
  ERROR_WORDS_LOADING,
  START_WORDS_LOADING,
  START_WORD_SAVING,
  SUCCESS_WORDS_LOADING,
  ERROR_WORD_SAVING,
  SUCCESS_WORD_SAVING,
  START_WORD_REMOVING,
  ERROR_WORD_REMOVING,
  SUCCESS_WORD_REMOVING,
} from '../action-types';
import { addMessage } from '../messages';
import { createErrorMessage, createInfoMessage, createSuccessMessage } from '../../../utils/messages/messages-utils';
import { authTokenSelector } from '../../selectors';
import { logout } from '../auth';

const startWordsLoading = () => ({
  type: START_WORDS_LOADING,
});

const errorWordsLoading = error => ({
  type: ERROR_WORDS_LOADING,
  payload: error,
});

const successWordsLoading = data => ({
  type: SUCCESS_WORDS_LOADING,
  payload: data,
});

const getWords = cbSuccess =>
  async (dispatch, getState, api) => {
    dispatch(startWordsLoading());

    try {
      const words = await api.words.getWords();
      dispatch(successWordsLoading(words));
      if (cbSuccess) cbSuccess();
    } catch (e) {
      dispatch(errorWordsLoading(e));
      dispatch(addMessage(createErrorMessage('Can not fetch words')));
      if (e.statusCode === 401) {
        await logout()(dispatch, getState, api);
      }
    }
  };

const startWordSaving = () => ({
  type: START_WORD_SAVING,
});

const errorWordSaving = error => ({
  type: ERROR_WORD_SAVING,
  payload: error,
});

const successWordSaving = () => ({
  type: SUCCESS_WORD_SAVING,
});

const saveWord = (word, cbSuccess) =>
  async (dispatch, getState, api) => {
    dispatch(startWordSaving());
    const token = authTokenSelector(getState());
    const action = word.id ? 'updateWord' : 'createWord';

    try {
      const result = await api.words[action](word, token);
      if (!(result && result.valid === false)) {
        dispatch(addMessage(createSuccessMessage('Word is saved successfully')));
      }
      dispatch(successWordSaving());
      if (cbSuccess) cbSuccess(result);
    } catch (e) {
      dispatch(errorWordSaving(e));
      dispatch(addMessage(createErrorMessage('Error during word saving')));
      if (e.statusCode === 401) {
        await logout()(dispatch, getState, api);
      }
    }
  };

const startWordRemoving = () => ({
  type: START_WORD_REMOVING,
});

const errorWordRemoving = error => ({
  type: ERROR_WORD_REMOVING,
  payload: error,
});

const successWordRemoving = () => ({
  type: SUCCESS_WORD_REMOVING,
});

const deleteWord = (wordUrl, cbSuccess) =>
  async (dispatch, getState, api) => {
    dispatch(startWordRemoving());
    const token = authTokenSelector(getState());
    try {
      await api.words.deleteWord(wordUrl, token);
      dispatch(successWordRemoving());
      dispatch(addMessage(createInfoMessage('Word was removed')));
      if (cbSuccess) cbSuccess();
    } catch (e) {
      dispatch(errorWordRemoving(e));
      dispatch(addMessage(createErrorMessage('Error during word removal')));
      if (e.statusCode === 401) {
        await logout()(dispatch, getState, api);
      }
    }
  };

export {
  getWords,
  saveWord,
  deleteWord,
};
