import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const TextInput = ({
  disabled,
  id,
  label,
  onChange,
  type,
  validationMessage,
  value,
  ...rest
}) => {
  const inputProps = rest || {};

  return (
    <TextField
      disabled={disabled}
      error={!!validationMessage}
      fullWidth
      helperText={validationMessage || ''}
      id={id}
      label={label}
      name={id}
      onChange={onChange}
      type={type}
      value={value || ''}
      variant="standard"
      inputProps={inputProps}
    />
  );
};

TextInput.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  validationMessage: PropTypes.string,
  value: PropTypes.string,
};

TextInput.defaultProps = {
  disabled: false,
  onChange: () => {},
  type: 'text',
  validationMessage: '',
  value: '',
}

export default TextInput;
