import { unitNameSelector }
  from '../../../../redux/selectors';
import {
  statisticsDataSelector,
  statisticsErrorSelector,
  statisticsRequestInProgressSelector
} from '../../../../redux/selectors/statistics';

const mapStateToProps = state => ({
  data: statisticsDataSelector(state),
  error: statisticsErrorSelector(state),
  requestInProgress: statisticsRequestInProgressSelector(state),
  unitName: unitNameSelector(state),
});

export default mapStateToProps;
