import { SET_ROUTE_PARAMS } from '../../actions/action-types';

const initialState = {
  params: {},
  entity: '',
};

const routerReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ROUTE_PARAMS:
      return {
        ...state,
        params: payload.params,
        entity: payload.entity || '',
      };

    default:
      return state;
  }
};

export default routerReducer;
