import {
  START_ENDPOINTS_LOADING,
  ERROR_ENDPOINTS_LOADING,
  SUCCESS_ENDPOINTS_LOADING,
  START_ENDPOINT_SAVING,
  ERROR_ENDPOINT_SAVING,
  SUCCESS_ENDPOINT_SAVING,
  START_ENDPOINT_REMOVING,
  ERROR_ENDPOINT_REMOVING,
  SUCCESS_ENDPOINT_REMOVING,
} from '../action-types';
import { addMessage } from '../messages';
import {
  createErrorMessage,
  createInfoMessage,
  createSuccessMessage,
} from '../../../utils/messages/messages-utils';
import { authTokenSelector } from '../../selectors';
import { logout } from '../auth';

const startEndpointsLoading = () => ({
  type: START_ENDPOINTS_LOADING,
});

const errorEndpointsLoading = error => ({
  type: ERROR_ENDPOINTS_LOADING,
  payload: error,
});

const successEndpointsLoading = data => ({
  type: SUCCESS_ENDPOINTS_LOADING,
  payload: data,
});

const getEndpoints = (cbSuccess = () => {}) =>
  async (dispatch, getState, api) => {
    dispatch(startEndpointsLoading());
    const token = authTokenSelector(getState());

    try {
      const endpoints = await api.endpoints.getEndpoints(token);
      dispatch(successEndpointsLoading(endpoints));
      cbSuccess();
    } catch (e) {
      dispatch(errorEndpointsLoading(e));
      dispatch(addMessage(createErrorMessage('Can not fetch endpoints')));
      if (e.statusCode === 401) {
        await logout()(dispatch, getState, api);
      }
    }
  };

const startEndpointSaving = () => ({
  type: START_ENDPOINT_SAVING,
});

const errorEndpointSaving = error => ({
  type: ERROR_ENDPOINT_SAVING,
  payload: error,
});

const successEndpointSaving = () => ({
  type: SUCCESS_ENDPOINT_SAVING,
});

const saveEndpoint = (endpoint, cbSuccess = () => {}) =>
  async (dispatch, getState, api) => {
    dispatch(startEndpointSaving());

    const action = endpoint.id ? 'updateEndpoint' : 'createEndpoint';
    const token = authTokenSelector(getState());

    try {
      const result = await api.endpoints[action](endpoint, token);
      if (!(result && result.valid === false)) {
        dispatch(addMessage(createSuccessMessage('Endpoint has been saved successfully')));
      }
      dispatch(successEndpointSaving());
      cbSuccess(result);
    } catch (e) {
      dispatch(errorEndpointSaving(e));
      dispatch(addMessage(createErrorMessage('Error saving endpoint')));
      if (e.statusCode === 401) {
        await logout()(dispatch, getState, api);
      }
    }
  };

const startEndpointRemoving = () => ({
  type: START_ENDPOINT_REMOVING,
});

const errorEndpointRemoving = error => ({
  type: ERROR_ENDPOINT_REMOVING,
  payload: error,
});

const successEndpointRemoving = () => ({
  type: SUCCESS_ENDPOINT_REMOVING,
});

const deleteEndpoint = (endpointUrl, cbSuccess) =>
  async (dispatch, getState, api) => {
    dispatch(startEndpointRemoving());
    const token = authTokenSelector(getState());
    try {
      await api.endpoints.deleteEndpoint(endpointUrl, token);
      dispatch(successEndpointRemoving());
      dispatch(addMessage(createInfoMessage('Endpoint was successfully removed')));
      if (cbSuccess) cbSuccess();
    } catch (e) {
      dispatch(errorEndpointRemoving(e));
      dispatch(addMessage(createErrorMessage('Error removing endpoint')));
      if (e.statusCode === 401) {
        await logout()(dispatch, getState, api);
      }
    }
  };

export {
  deleteEndpoint,
  getEndpoints,
  saveEndpoint,
};
