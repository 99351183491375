import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { authLoggedSelector, authLoginSelector } from '../../../../redux/selectors';

const AccountMenuButton = ({
  logged,
  login,
  onClick,
  menuId,
}) => (
  <Button
    size="large"
    aria-label="account of current user"
    aria-controls={menuId}
    aria-haspopup="true"
    onClick={onClick}
    color="inherit"
    variant="text"
  >
    <Typography sx={{ mr: 1, textTransform: 'none' }}>{login ? login : 'Not logged'}</Typography>
    {logged ? <AccountCircle /> : <PermIdentityIcon />}
  </Button>
);

AccountMenuButton.propTypes = {
  menuId: PropTypes.string,
  onClick: PropTypes.func,
};

const mapStateToProps = state => ({
  login: authLoginSelector(state),
  logged: authLoggedSelector(state),
});

export default connect(mapStateToProps)(AccountMenuButton);
