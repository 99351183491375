import { get } from '../utils/api/request';
import URLS from '../constants/urls';

const getUnitPhrases = unitUrl =>
  get(`${URLS.API_UNITS}/${unitUrl}/phrases`);

const unitPhrasesApi = {
  getUnitPhrases,
};

export default unitPhrasesApi;
