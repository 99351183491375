import { useEffect } from 'react';
import { connect } from 'react-redux';

import { checkAuth as checkAuthAction } from '../../../redux/actions/auth';

const CheckAuthService = ({ checkAuth }) => {
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return null;
};

const mapDispatchToProps = {
  checkAuth: checkAuthAction,
};

export default connect(null, mapDispatchToProps)(CheckAuthService);
