import { SET_SEARCH } from '../../actions/action-types';

const initialState = {
  value: '',
};

const searchReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SEARCH:
      return {
        ...state,
        value: payload,
      };

    default:
      return state;
  }
};

export default searchReducer;
