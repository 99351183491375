const startEntityLoading = (state, _payload) => ({
  ...state,
  loading: true,
  error: null,
});

const errorEntityLoading = (state, payload) => ({
  ...state,
  loading: false,
  error: payload,
  data: null,
});

const successEntityLoading = (state, payload) => ({
  ...state,
  loading: false,
  error: null,
  data: payload,
});

const startEntitySaving = (state, _payload) => ({
  ...state,
  saving: true,
  errorSaving: null,
});

const errorEntitySaving = (state, payload) => ({
  ...state,
  saving: false,
  errorSaving: payload,
});

const successEntitySaving = (state, _payload) => ({
  ...state,
  saving: false,
  errorSaving: null,
});

const startEntityRemoving = (state, _payload) => ({
  ...state,
  removing: true,
  errorRemoving: null,
});

const errorEntityRemoving = (state, payload) => ({
  ...state,
  removing: false,
  errorRemoving: payload,
});

const successEntityRemoving = (state, _payload) => ({
  ...state,
  removing: false,
  errorRemoving: null,
});

const basicDefaultResolver = (state, _payload) => state;

const createEntityReducer = ({
  resolvers,
  defaultResolver = basicDefaultResolver,
  initialState = {},
}) => (state = { ...initialState }, action = {}) => (
  (resolvers[action.type] || defaultResolver)(state, action.payload)
);

export {
  startEntityLoading,
  errorEntityLoading,
  successEntityLoading,
  startEntitySaving,
  errorEntitySaving,
  successEntitySaving,
  startEntityRemoving,
  errorEntityRemoving,
  successEntityRemoving,
  basicDefaultResolver,
  createEntityReducer,
};
