import { useParams, useNavigate, Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import TileCard from '../../../../TileCard';
import AdminUserStatisticsNetworkService
  from './AdminUserStatisticsNetworkService';
import { useCallback } from 'react';
import URLS from '../../../../../constants/urls';

const AdminUserStatisticsHome = () => {
  const { login } = useParams();
  const navigate = useNavigate();

  const handleTestTypeClick = useCallback(testType => () => {
    navigate(`${URLS.ADMINISTRATION}/users/${login}/statistics/${testType}`);
  }, [login, navigate]);

  const handleManageUserAccount = useCallback(() => {
    navigate(`${URLS.ADMINISTRATION}/users/${login}`);
  }, [login, navigate]);

  return (
    <>
      <AdminUserStatisticsNetworkService />
      <TileCard sx={{ height: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flow: '0 1 auto' }}>
          <Typography variant="h6">
            {login || 'User'}
          </Typography>
          <Box>
            <Tooltip title="Phrases">
              <Fab
                aria-label="Phrases"
                color="secondary"
                size="small"
                onClick={handleTestTypeClick('phrases')}
                sx={{ mr: 1 }}
              >
                <FormatListNumberedIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Words writing">
              <Fab
                aria-label="Words writing"
                color="secondary"
                size="small"
                onClick={handleTestTypeClick('words-writing')}
                sx={{ mr: 1 }}
              >
                <DriveFileRenameOutlineIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Manage user account">
              <Fab
                aria-label="Manage user account"
                color="primary"
                size="small"
                onClick={handleManageUserAccount}
              >
                <ManageAccountsIcon />
              </Fab>
            </Tooltip>
          </Box>
        </Grid>
        <CardContent
          sx={{
            flex: '1 1 auto',
            height: 'calc(100% - 36px)',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          <Outlet />
        </CardContent>
      </TileCard>
    </>
  );
};

export default AdminUserStatisticsHome;
