import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import FormModes from '../../../../constants/form-modes';

const AdminPhrasesContext = createContext({
  mode: FormModes.NONE,
  handleEdit: () => {},
});

const useAdminPhrasesContext = () => useContext(AdminPhrasesContext);

const AdminPhrasesContextProvider = ({ children }) => {
  const { phraseUrl } = useParams();
  const [mode, setMode] = useState(FormModes.NONE);

  const handleEdit = useCallback(() => {
    setMode(FormModes.EDIT);
  }, [setMode]);

  const handleCancel = useCallback(() => {
    setMode(FormModes.NONE);
  }, [setMode]);

  const value = useMemo(() => ({
    mode: phraseUrl === 'new' ? FormModes.CREATE : mode,
    handleEdit,
    handleCancel,
  }), [phraseUrl, mode, handleEdit, handleCancel]);

  return (
    <AdminPhrasesContext.Provider value={value}>
      {children}
    </AdminPhrasesContext.Provider>
  );
};

export {
  AdminPhrasesContext,
  AdminPhrasesContextProvider,
  useAdminPhrasesContext,
};
