import { isFilledArray } from '../array'

/**
 * Calculates average value from 2 (default) last results
 * @param {Object[]} results - array of results
 * @param {number} sliceFrom - start position for slice
 * @returns {number} - Returns 0..100 as average result
 */
const getStatisticsResultsAverage = (results, sliceFrom = -2) => {
  return isFilledArray(results)
    ? results
      .sort((r1, r2) => r1.createdTs - r2.createdTs)
      .slice(sliceFrom)
      .map(({ result }) => result * 100)
      .reduce((acc, cur, i) => {
        return Math.round((acc + cur) / Math.min(2, i + 1));
      }, 0)
    : 0;
};

export default getStatisticsResultsAverage;
