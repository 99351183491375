import { useParams } from 'react-router-dom';

import AdminWordsWritingStatistics
  from '../../../../tests/WordsWriting/Statistics/AdminStatistics';

import AdminPhrasesStatistics
  from '../../../../tests/Phrases/Statistics/AdminStatistics';

const AdminUserTestTypeStatistics = () => {
  const { testType } = useParams();

  if (testType === "words-writing")
    return <AdminWordsWritingStatistics />;

  if (testType === "phrases")
    return <AdminPhrasesStatistics />;

  return null;
};

export default AdminUserTestTypeStatistics;
