import MenuBookIcon from '@mui/icons-material/MenuBook';
import MediationIcon from '@mui/icons-material/Mediation';
import QuizIcon from '@mui/icons-material/Quiz';
import UnitWords from '../components/UnitWords';
import Tests from '../components/pages/Units/tests/TestTypes';
import TESTS from './tests';

const TOPICS = {
  vocabulary: {
    index: 1,
    label: 'Vocabulary',
    icon: MenuBookIcon,
    main: UnitWords
  },
  grammar: {
    index: 2,
    label: 'Grammar',
    icon: MediationIcon,
    main: () => <div>Grammar</div>
  },
  tests: {
    index: 3,
    label: 'Tests',
    icon: QuizIcon,
    main: Tests,
    partitions: TESTS,
  },
};

export default TOPICS;
