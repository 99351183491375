import { useState } from 'react';
import Box from '@mui/material/Box';

import AccountMenuButton from './AccountMenuButton';
import AccountMenu from './AccountMenu';

const ACCOUNT_MENU_ID = 'account-menu';

const CurrentUserAccount = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ pl: 1 }}>
      <AccountMenuButton
        onClick={handleMenu}
        menuId={ACCOUNT_MENU_ID}
      />
      <AccountMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        id={ACCOUNT_MENU_ID}
      />
    </Box>
  );
};

export default CurrentUserAccount;
