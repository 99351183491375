const createWordFilter = searchValue => ({ en, ru }) => {
  if (searchValue) {
    const filter = searchValue.toLowerCase();
    const lowerEn = en.toLowerCase();
    const lowerRu = ru.toLowerCase();

    return lowerEn.includes(filter) || lowerRu.includes(filter);
  }

  return true;
};

export default createWordFilter;
