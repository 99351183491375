import {
  START_USERS_LOADING,
  ERROR_USERS_LOADING,
  SUCCESS_USERS_LOADING,
  START_USER_SAVING,
  ERROR_USER_SAVING,
  SUCCESS_USER_SAVING,
} from '../action-types';
import { addMessage } from '../messages';
import {
  createErrorMessage,
  createSuccessMessage,
} from '../../../utils/messages/messages-utils';
import { authTokenSelector } from '../../selectors';
import { logout } from '../auth';

const startUsersLoading = () => ({
  type: START_USERS_LOADING,
});

const errorUsersLoading = error => ({
  type: ERROR_USERS_LOADING,
  payload: error,
});

const successUsersLoading = data => ({
  type: SUCCESS_USERS_LOADING,
  payload: data,
});

const getUsers = (cbSuccess = () => {}) =>
  async (dispatch, getState, api) => {
    dispatch(startUsersLoading());
    const token = authTokenSelector(getState());

    try {
      const users = await api.users.getUsers(token);
      dispatch(successUsersLoading(users));
      cbSuccess();
    } catch (e) {
      dispatch(errorUsersLoading(e));
      dispatch(addMessage(createErrorMessage('Can not fetch users')));
      if (e.statusCode === 401) {
        await logout()(dispatch, getState, api);
      }
    }
  };

const startUserSaving = () => ({
  type: START_USER_SAVING,
});

const errorUserSaving = error => ({
  type: ERROR_USER_SAVING,
  payload: error,
});

const successUserSaving = () => ({
  type: SUCCESS_USER_SAVING,
});

const saveUser = (user, cbSuccess = () => {}) =>
  async (dispatch, getState, api) => {
    dispatch(startUserSaving());
    const token = authTokenSelector(getState());

    try {
      const result = await api.users.updateUser(user, token);
      if (!(result && result.valid === false)) {
        dispatch(addMessage(createSuccessMessage('User has been updated successfully')));
      }
      dispatch(successUserSaving());
      cbSuccess(result);
    } catch (e) {
      dispatch(errorUserSaving(e));
      dispatch(addMessage(createErrorMessage('Error updating user')));
      if (e.statusCode === 401) {
        await logout()(dispatch, getState, api);
      }
    }
  };

const resetPassword = (login, cbSuccess) => async (dispatch, getState, api) => {
  dispatch(startUserSaving());
  const token = authTokenSelector(getState());
  try {
    await api.users.resetPassword(login, token);
    dispatch(successUserSaving());
    dispatch(addMessage(createSuccessMessage(`Password for ${login} has been reset successfully`)));
    if (cbSuccess) cbSuccess();
  } catch (e) {
    dispatch(errorUserSaving(e));
    dispatch(addMessage(createErrorMessage(`Error reset password for user ${login}`)));
    if (e.statusCode === 401) {
      await logout()(dispatch, getState, api);
    }
  }
};

export {
  getUsers,
  saveUser,
  resetPassword,
};
