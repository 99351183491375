import {
  ADD_MESSAGE,
  REMOVE_MESSAGE,
  CLEAR_MESSAGES,
} from '../../actions/action-types';

const initialState = {
  data: [],
};

const messagesReducer = (state = { ...initialState }, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_MESSAGE:
      return {
        ...state,
        data: [...state.data, payload],
      };

    case REMOVE_MESSAGE:
      return {
        ...state,
        data: state.data.filter(({ id }) => id !== payload),
      };

    case CLEAR_MESSAGES:
      return {
        ...state,
        data: [],
      };

    default:
      return state;
  }
};

export default messagesReducer;
