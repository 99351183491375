import { styled, useTheme } from '@mui/material/styles';

const Page = ({ children }) => {
  const theme = useTheme();

  const PageWrapper = styled('div')(() => ({
    flex: '1 1 auto',
    overflow: 'hidden',
    height: '100%',
    padding: '1rem',
    margin: '0 auto',
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'between',
    alignItems: 'start',
    [theme.breakpoints.up('sm')]: {
      width: '600px',
    },
    [theme.breakpoints.up('md')]: {
      width: '900px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1200px',
    },
  }));

  return (
    <PageWrapper>
      {children}
    </PageWrapper>
  )
};

export default Page;
