import {
  START_USER_SCOPES_LOADING,
  ERROR_USER_SCOPES_LOADING,
  SUCCESS_USER_SCOPES_LOADING,
} from '../action-types';
import { addMessage } from '../messages';
import { createErrorMessage } from '../../../utils/messages/messages-utils';
import { authTokenSelector } from '../../selectors';
import { logout } from '../auth';

const startUserScopesLoading = () => ({
  type: START_USER_SCOPES_LOADING,
});

const errorUserScopesLoading = error => ({
  type: ERROR_USER_SCOPES_LOADING,
  payload: error,
});

const successUserScopesLoading = data => ({
  type: SUCCESS_USER_SCOPES_LOADING,
  payload: data,
});

const getUserScopes = (login, cbSuccess = () => {}) =>
  async (dispatch, getState, api) => {
    dispatch(startUserScopesLoading());
    const token = authTokenSelector(getState());

    try {
      const user = await api.users.getUser(login, token);
      dispatch(successUserScopesLoading(user.scopes));
      cbSuccess();
    } catch (e) {
      dispatch(errorUserScopesLoading(e));
      dispatch(addMessage(createErrorMessage('Can not fetch user`s scopes')));
      if (e.statusCode === 401) {
        await logout()(dispatch, getState, api);
      }
    }
  };

export { getUserScopes };
