import { isFilledArray } from '../array';

const getPercentData = data => isFilledArray(data)
  ? data.reduce((acc, { id, answers, createdTs }, i) => {
    const correctNumber = answers.filter(({ correct }) => correct).length;
    const value = isFilledArray(answers)
      ? Math.floor(correctNumber / answers.length * 100)
      : 0;
    const color = `rgba(65, 105, 225, ${value / 100})`;

    return [
      ...acc,
      {
        id,
        index: i + 1,
        color,
        value,
        date: new Date(createdTs).toLocaleString('ru'),
      }];}, [])
  : [];

export default getPercentData;
