import {
  START_PHRASES_LOADING,
  ERROR_PHRASES_LOADING,
  SUCCESS_PHRASES_LOADING,
  START_PHRASE_SAVING,
  ERROR_PHRASE_SAVING,
  SUCCESS_PHRASE_SAVING,
  SUCCESS_PHRASE_REMOVING,
  ERROR_PHRASE_REMOVING,
  START_PHRASE_REMOVING,
} from '../../actions/action-types';
import {
  createEntityReducer,
  errorEntityLoading,
  errorEntityRemoving,
  errorEntitySaving,
  startEntityLoading,
  startEntityRemoving,
  startEntitySaving,
  successEntityLoading,
  successEntityRemoving,
  successEntitySaving
} from '../../../utils/reducer/reducer-utils';

const initialState = {
  loading: false,
  data: null,
  error: null,
  saving: false,
  errorSaving: null,
  removing: false,
  errorRemoving: null,
};

const resolvers = {
  [START_PHRASES_LOADING]: startEntityLoading,
  [ERROR_PHRASES_LOADING]: errorEntityLoading,
  [SUCCESS_PHRASES_LOADING]: successEntityLoading,
  [START_PHRASE_SAVING]: startEntitySaving,
  [ERROR_PHRASE_SAVING]: errorEntitySaving,
  [SUCCESS_PHRASE_SAVING]: successEntitySaving,
  [START_PHRASE_REMOVING]: startEntityRemoving,
  [ERROR_PHRASE_REMOVING]: errorEntityRemoving,
  [SUCCESS_PHRASE_REMOVING]: successEntityRemoving,
};

const phrasesReducer = createEntityReducer({
  resolvers,
  initialState,
})

export default phrasesReducer;
