import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import BarChartIcon from '@mui/icons-material/BarChart';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';

import WordsWritingStatistics from '../../../tests/WordsWriting/Statistics/Statistics';
import PhrasesStatistics from '../../../tests/Phrases/Statistics/Statistics';
import TileCard from '../../../TileCard';
import { unitNameSelector } from '../../../../redux/selectors';
import { useCallback } from 'react';
import URLS from '../../../../constants/urls';

const StatisticsContent = ({ unitName }) => {
  const navigate = useNavigate();
  const { testType, unitUrl } = useParams();

  const handleClickBackToStatistics = useCallback(() => {
    navigate(`${URLS.STATISTICS}`);
  }, [navigate]);

  const handleClickStartNewTest = useCallback(() => {
    navigate(`${URLS.UNITS}/${unitUrl}/tests/${testType}/in-progress`);
  }, [navigate, testType, unitUrl]);

  return (
    <TileCard sx={{ height: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ flow: '0 1 auto' }}
      >
        <Typography variant="h6">
          {unitName}
        </Typography>
        <Box>
          <Tooltip title="Start new test">
            <Fab
              aria-label="Start new test"
              color="secondary"
              size="small"
              onClick={handleClickStartNewTest}
              sx={{ mr: 1 }}
            >
              <PlayCircleFilledWhiteIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Back to statistics">
            <Fab
              aria-label="Back to statistics"
              color="primary"
              size="small"
              onClick={handleClickBackToStatistics}
            >
              <BarChartIcon />
            </Fab>
          </Tooltip>
        </Box>
      </Grid>
      <CardContent>
        {testType === 'words-writing' && (
          <WordsWritingStatistics />
        )}
        {testType === 'phrases' && (
          <PhrasesStatistics />
        )}
      </CardContent>
    </TileCard>
  );
};

const mapStateToProps = state => ({
  unitName: unitNameSelector(state),
});

export default connect(mapStateToProps)(StatisticsContent);
