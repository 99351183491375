import auth from './auth-api';
import endpoints from './endpoints-api'
import unitPhrases from './unit-phrases-api';
import phrases from './phrases-api';
import results from './results-api';
import scopes from './scopes-api';
import units from './units-api';
import unitWords from './unit-words-api';
import users from './users-api';
import words from './words-api';

const api = {
  auth,
  endpoints,
  phrases,
  results,
  scopes,
  units,
  unitPhrases,
  unitWords,
  users,
  words,
};

export default api;
