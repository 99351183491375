import {
  START_SCOPES_LOADING,
  ERROR_SCOPES_LOADING,
  SUCCESS_SCOPES_LOADING,
  START_SCOPE_SAVING,
  ERROR_SCOPE_SAVING,
  SUCCESS_SCOPE_SAVING,
  START_SCOPE_REMOVING,
  ERROR_SCOPE_REMOVING,
  SUCCESS_SCOPE_REMOVING,
} from '../action-types';
import { addMessage } from '../messages';
import {
  createErrorMessage,
  createInfoMessage,
  createSuccessMessage,
} from '../../../utils/messages/messages-utils';
import { authTokenSelector } from '../../selectors';
import { logout } from '../auth';

const startScopesLoading = () => ({
  type: START_SCOPES_LOADING,
});

const errorScopesLoading = error => ({
  type: ERROR_SCOPES_LOADING,
  payload: error,
});

const successScopesLoading = data => ({
  type: SUCCESS_SCOPES_LOADING,
  payload: data,
});

const getScopes = (cbSuccess = () => {}) =>
  async (dispatch, getState, api) => {
    dispatch(startScopesLoading());
    const token = authTokenSelector(getState());

    try {
      const scopes = await api.scopes.getScopes(token);
      dispatch(successScopesLoading(scopes));
      cbSuccess();
    } catch (e) {
      dispatch(errorScopesLoading(e));
      dispatch(addMessage(createErrorMessage('Can not fetch scopes')));
      if (e.statusCode === 401) {
        await logout()(dispatch, getState, api);
      }
    }
  };

const startScopeSaving = () => ({
  type: START_SCOPE_SAVING,
});

const errorScopeSaving = error => ({
  type: ERROR_SCOPE_SAVING,
  payload: error,
});

const successScopeSaving = () => ({
  type: SUCCESS_SCOPE_SAVING,
});

const saveScope = (scope, cbSuccess = () => {}) =>
  async (dispatch, getState, api) => {
    dispatch(startScopeSaving());

    const action = scope.id ? 'updateScope' : 'createScope';
    const token = authTokenSelector(getState());

    try {
      const result = await api.scopes[action](scope, token);
      if (!(result && result.valid === false)) {
        dispatch(addMessage(createSuccessMessage('Scope has been saved successfully')));
      }
      dispatch(successScopeSaving());
      cbSuccess(result);
    } catch (e) {
      dispatch(errorScopeSaving(e));
      dispatch(addMessage(createErrorMessage('Error saving scope')));
      if (e.statusCode === 401) {
        await logout()(dispatch, getState, api);
      }
    }
  };

const startScopeRemoving = () => ({
  type: START_SCOPE_REMOVING,
});

const errorScopeRemoving = error => ({
  type: ERROR_SCOPE_REMOVING,
  payload: error,
});

const successScopeRemoving = () => ({
  type: SUCCESS_SCOPE_REMOVING,
});

const deleteScope = (scopeUrl, cbSuccess) =>
  async (dispatch, getState, api) => {
    dispatch(startScopeRemoving());
    const token = authTokenSelector(getState());
    try {
      await api.scopes.deleteScope(scopeUrl, token);
      dispatch(successScopeRemoving());
      dispatch(addMessage(createInfoMessage('Scope was successfully removed')));
      if (cbSuccess) cbSuccess();
    } catch (e) {
      dispatch(errorScopeRemoving(e));
      dispatch(addMessage(createErrorMessage('Error removing scope')));
      if (e.statusCode === 401) {
        await logout()(dispatch, getState, api);
      }
    }
  };

export {
  deleteScope,
  getScopes,
  saveScope,
};
