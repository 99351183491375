import {
  START_UNITS_LOADING,
  ERROR_UNITS_LOADING,
  SUCCESS_UNITS_LOADING,
  START_UNIT_SAVING,
  ERROR_UNIT_SAVING,
  SUCCESS_UNIT_SAVING,
} from '../../actions/action-types';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const unitsReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case START_UNITS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ERROR_UNITS_LOADING:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };

    case SUCCESS_UNITS_LOADING:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };

    case START_UNIT_SAVING:
      return {
        ...state,
        saving: true,
        errorSaving: null,
      };

    case ERROR_UNIT_SAVING:
      return {
        ...state,
        saving: false,
        errorSaving: payload,
      };

    case SUCCESS_UNIT_SAVING:
      return {
        ...state,
        saving: false,
        errorSaving: null,
      };

    default:
      return state;
  }
};

export default unitsReducer;
