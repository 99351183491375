// auth
export const START_AUTH_REQUEST = 'START_AUTH_REQUEST';
export const ERROR_LOGIN = 'ERROR_LOGIN';
export const SUCCESS_LOGIN = 'SUCCESS_LOGIN';
export const ERROR_LOGOUT = 'ERROR_LOGOUT';
export const SUCCESS_LOGOUT = 'SUCCESS_LOGOUT';
export const COMPLETE_AUTH_CHECK = 'COMPLETE_AUTH_CHECK';
export const START_PERSONAL_ACCOUNT_REQUEST = 'START_PERSONAL_ACCOUNT_REQUEST';
export const ERROR_PERSONAL_ACCOUNT_REQUEST = 'ERROR_PERSONAL_ACCOUNT_REQUEST';
export const SUCCESS_PERSONAL_ACCOUNT_REQUEST = 'SUCCESS_PERSONAL_ACCOUNT_REQUEST';
export const START_CHANGE_PASSWORD_REQUEST = 'START_CHANGE_PASSWORD_REQUEST';
export const ERROR_CHANGE_PASSWORD_REQUEST = 'ERROR_CHANGE_PASSWORD_REQUEST';
export const SUCCESS_CHANGE_PASSWORD_REQUEST = 'SUCCESS_CHANGE_PASSWORD_REQUEST';

// endpoints
export const START_ENDPOINTS_LOADING = 'START_ENDPOINTS_LOADING';
export const ERROR_ENDPOINTS_LOADING = 'ERROR_ENDPOINTS_LOADING';
export const SUCCESS_ENDPOINTS_LOADING = 'SUCCESS_ENDPOINTS_LOADING';
export const START_ENDPOINT_SAVING = 'START_ENDPOINT_SAVING';
export const ERROR_ENDPOINT_SAVING = 'ERROR_ENDPOINT_SAVING';
export const SUCCESS_ENDPOINT_SAVING = 'SUCCESS_ENDPOINT_SAVING';
export const START_ENDPOINT_REMOVING = 'START_ENDPOINT_REMOVING';
export const ERROR_ENDPOINT_REMOVING = 'ERROR_ENDPOINT_REMOVING';
export const SUCCESS_ENDPOINT_REMOVING = 'SUCCESS_ENDPOINT_REMOVING';

// endpoint scopes
export const START_ENDPOINT_SCOPES_LOADING = 'START_ENDPOINT_SCOPES_LOADING';
export const ERROR_ENDPOINT_SCOPES_LOADING = 'ERROR_ENDPOINT_SCOPES_LOADING';
export const SUCCESS_ENDPOINT_SCOPES_LOADING = 'SUCCESS_ENDPOINT_SCOPES_LOADING';

// scopes
export const START_SCOPES_LOADING = 'START_SCOPES_LOADING';
export const ERROR_SCOPES_LOADING = 'ERROR_SCOPES_LOADING';
export const SUCCESS_SCOPES_LOADING = 'SUCCESS_SCOPES_LOADING';
export const START_SCOPE_SAVING = 'START_SCOPE_SAVING';
export const ERROR_SCOPE_SAVING = 'ERROR_SCOPE_SAVING';
export const SUCCESS_SCOPE_SAVING = 'SUCCESS_SCOPE_SAVING';
export const START_SCOPE_REMOVING = 'START_SCOPE_REMOVING';
export const ERROR_SCOPE_REMOVING = 'ERROR_SCOPE_REMOVING';
export const SUCCESS_SCOPE_REMOVING = 'SUCCESS_SCOPE_REMOVING';

// users
export const START_USERS_LOADING = 'START_USERS_LOADING';
export const ERROR_USERS_LOADING = 'ERROR_USERS_LOADING';
export const SUCCESS_USERS_LOADING = 'SUCCESS_USERS_LOADING';
export const START_USER_SAVING = 'START_USER_SAVING';
export const ERROR_USER_SAVING = 'ERROR_USER_SAVING';
export const SUCCESS_USER_SAVING = 'SUCCESS_USER_SAVING';

// user scopes
export const START_USER_SCOPES_LOADING = 'START_USER_SCOPES_LOADING';
export const ERROR_USER_SCOPES_LOADING = 'ERROR_USER_SCOPES_LOADING';
export const SUCCESS_USER_SCOPES_LOADING = 'SUCCESS_USER_SCOPES_LOADING';

// router
export const SET_ROUTE_PARAMS = 'SET_ROUTE_PARAMS';

// search
export const SET_SEARCH = 'SET_SEARCH';

// ui
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
export const CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL';

// units
export const START_UNITS_LOADING = 'START_UNITS_LOADING';
export const ERROR_UNITS_LOADING = 'ERROR_UNITS_LOADING';
export const SUCCESS_UNITS_LOADING = 'SUCCESS_UNITS_LOADING';
export const START_UNIT_SAVING = 'START_UNIT_SAVING';
export const ERROR_UNIT_SAVING = 'ERROR_UNIT_SAVING';
export const SUCCESS_UNIT_SAVING = 'SUCCESS_UNIT_SAVING';
export const START_UNIT_REMOVING = 'START_UNIT_REMOVING';
export const ERROR_UNIT_REMOVING = 'ERROR_UNIT_REMOVING';
export const SUCCESS_UNIT_REMOVING = 'SUCCESS_UNIT_REMOVING';

// unit phrases
export const START_UNIT_PHRASES_LOADING = 'START_UNIT_PHRASES_LOADING';
export const ERROR_UNIT_PHRASES_LOADING = 'ERROR_UNIT_PHRASES_LOADING';
export const SUCCESS_UNIT_PHRASES_LOADING = 'SUCCESS_UNIT_PHRASES_LOADING';
export const START_UNIT_PHRASES_SAVING = 'START_UNIT_PHRASES_SAVING';
export const ERROR_UNIT_PHRASES_SAVING = 'ERROR_UNIT_PHRASES_SAVING';
export const SUCCESS_UNIT_PHRASES_SAVING = 'SUCCESS_UNIT_PHRASES_SAVING';

// phrases
export const START_PHRASES_LOADING = 'START_PHRASES_LOADING';
export const ERROR_PHRASES_LOADING = 'ERROR_PHRASES_LOADING';
export const SUCCESS_PHRASES_LOADING = 'SUCCESS_PHRASES_LOADING';
export const START_PHRASE_SAVING = 'START_PHRASE_SAVING';
export const ERROR_PHRASE_SAVING = 'ERROR_PHRASE_SAVING';
export const SUCCESS_PHRASE_SAVING = 'SUCCESS_PHRASE_SAVING';
export const START_PHRASE_REMOVING = 'START_PHRASE_REMOVING';
export const ERROR_PHRASE_REMOVING = 'ERROR_PHRASE_REMOVING';
export const SUCCESS_PHRASE_REMOVING = 'SUCCESS_PHRASE_REMOVING';

// unit words
export const START_UNIT_WORDS_LOADING = 'START_UNIT_WORDS_LOADING';
export const ERROR_UNIT_WORDS_LOADING = 'ERROR_UNIT_WORDS_LOADING';
export const SUCCESS_UNIT_WORDS_LOADING = 'SUCCESS_UNIT_WORDS_LOADING';
export const START_WORDS_WRITING_RESULT_SAVING = 'START_WORDS_WRITING_RESULT_SAVING';
export const ERROR_WORDS_WRITING_RESULT_SAVING = 'ERROR_WORDS_WRITING_RESULT_SAVING';
export const SUCCESS_WORDS_WRITING_RESULT_SAVING = 'SUCCESS_WORDS_WRITING_RESULT_SAVING';

// words
export const START_WORDS_LOADING = 'START_WORDS_LOADING';
export const ERROR_WORDS_LOADING = 'ERROR_WORDS_LOADING';
export const SUCCESS_WORDS_LOADING = 'SUCCESS_WORDS_LOADING';
export const START_WORD_SAVING = 'START_WORDS_SAVING';
export const ERROR_WORD_SAVING = 'ERROR_WORDS_SAVING';
export const SUCCESS_WORD_SAVING = 'SUCCESS_WORDS_SAVING';
export const START_WORD_REMOVING = 'START_WORDS_REMOVING';
export const ERROR_WORD_REMOVING = 'ERROR_WORDS_REMOVING';
export const SUCCESS_WORD_REMOVING = 'SUCCESS_WORDS_REMOVING';

// statistics
export const START_STATISTICS_REQUEST = 'START_STATISTICS_REQUEST';
export const ERROR_STATISTICS_REQUEST = 'ERROR_STATISTICS_REQUEST';
export const SUCCESS_STATISTICS_REQUEST = 'SUCCESS_STATISTICS_REQUEST';

// messages
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
