import Card from '@mui/material/Card';

const ELEVATION = 1;

const TileCard = ({ children, sx = {} }) => {
  return (
    <Card elevation={ELEVATION} outlined="true" sx={{ p: 2, ...sx }}>
      {children}
    </Card>
  );
};

export default TileCard;
