import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { dateTimeToString } from '../../../utils/date';

const TestResultsModal = ({ data, open, onClose, testResult: TestResult }) => {
  const content = useMemo(() => data ? (
      <TestResult data={data.answers} />
    ) : (
      <div>No data to display</div>
    ),
    [data]
  );

  const date = data ? dateTimeToString(data.createdTs) : '';

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      scroll="paper"
    >
      <DialogTitle>
        Test result ({date})
      </DialogTitle>
      <DialogContent dividers>
        {content}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

TestResultsModal.propTypes = {
  data: PropTypes.shape({}),
  date: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default TestResultsModal;
