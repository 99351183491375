import { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Tooltip from '@mui/material/Tooltip';

import {
  searchValueSelector,
  phrasesDataSelector,
  phrasesErrorSelector,
  phrasesLoadingSelector,
} from '../../../../redux/selectors';
import TileCard from '../../../TileCard';
import { useAdminPhrasesContext } from './admin-phrases-context';
import { getPhrases } from '../../../../redux/actions';
import URLS from '../../../../constants/urls';
import FormModes from '../../../../constants/form-modes';
import createPhrasesFilter from '../../../../utils/filters/create-phrases-filter';
import { scrollIntoViewIfNecessary } from '../../../../utils/ui/scroll-into-view';

const phrasesContainerId = 'phrases-container';

const AdminPhrasesList = ({
  data,
  getData,
  loading,
  error,
  searchValue,
}) => {
  const navigate = useNavigate();
  const { phraseUrl } = useParams();
  const { mode } = useAdminPhrasesContext();

  const disabled = (mode !== FormModes.NONE) || (phraseUrl === 'new');

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    scrollIntoViewIfNecessary(phraseUrl, phrasesContainerId)
  }, [phraseUrl, data]);

  const handleSelect = useCallback(
    url => () => { navigate(`${URLS.ADMINISTRATION}/phrases/${url}`) },
    [navigate],
  );

  const items = useMemo(() => {
    return Array.isArray(data)
      ? data
        .filter(createPhrasesFilter(searchValue))
        .sort((w1, w2) => {
          return w1.ru.localeCompare(w2.ru);
        })
        .map(({ id, ru, url }) => {
          return (
            <ListItemButton
              key={id}
              id={url}
              selected={phraseUrl === url}
              onClick={handleSelect(url)}
              disabled={disabled}
            >
              <ListItemText primary={ru} />
            </ListItemButton>
          );
        })
      : null;
  }, [data, disabled, handleSelect, searchValue, phraseUrl]);

  const dataExists = !(loading || error) && !!data;
  const dataExistsAndReadyToDisplay = dataExists && data.length > 0;
  const dataExistsAndEmpty = dataExists && data.length === 0;

  return (
    <TileCard sx={{ height: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '0 1 auto' }}>
        <Typography variant="h6">
          List of phrases
        </Typography>
        <Tooltip title="Add new">
          <Fab
            aria-label="Add phrase"
            color="primary"
            size="small"
            onClick={handleSelect('new')}
            disabled={disabled}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </Grid>
      <CardContent sx={{ flex: '1 1 auto', height: 'calc(100% - 32px)' }}>
        {loading && <div>Loading...</div>}
        {!loading && error ? <div>Error: {error.message}</div> : null}
        {dataExistsAndReadyToDisplay ? (
            <List
              id={phrasesContainerId}
              component="nav"
              aria-label="phrases list"
              sx={{
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
            >
              {items}
            </List>
          ) : null
        }
        {dataExistsAndEmpty && (
          <div>No data to display</div>
        )}
      </CardContent>
    </TileCard>
  );
};

const mapStateToProps = state => ({
  data: phrasesDataSelector(state),
  loading: phrasesLoadingSelector(state),
  error: phrasesErrorSelector(state),
  searchValue: searchValueSelector(state),
});

const mapDispatchToProps = {
  getData: getPhrases,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPhrasesList);
