import { entityLoadedSelector } from '../common-selectors';

const unitPhrasesSelector = state => state.unitPhrases;

const unitPhrasesDataSelector = state => unitPhrasesSelector(state).data;

const unitPhrasesLoadingSelector = state => unitPhrasesSelector(state).loading;

const unitPhrasesErrorSelector = state => unitPhrasesSelector(state).error;

const unitPhrasesLoadedSelector = entityLoadedSelector(unitPhrasesSelector);

export {
  unitPhrasesDataSelector,
  unitPhrasesLoadingSelector,
  unitPhrasesErrorSelector,
  unitPhrasesLoadedSelector,
};
