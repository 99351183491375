const createUnitFilter = searchValue => ({ name, url }) => {
  if (searchValue) {
    const filter = searchValue.toLowerCase();
    const lowerName = name.toLowerCase();
    const lowerUrl = url.toLowerCase();

    return lowerName.includes(filter) || lowerUrl.includes(filter);
  }

  return true;
};

export default createUnitFilter;
