import {
  START_STATISTICS_REQUEST,
  ERROR_STATISTICS_REQUEST,
  SUCCESS_STATISTICS_REQUEST,
} from '../action-types';
import { authTokenSelector } from '../../selectors';
import { createErrorMessage }
  from '../../../utils/messages/messages-utils';
import { addMessage } from '../messages';
import { logout } from '../auth';

const startStatisticsRequest = () => ({
  type: START_STATISTICS_REQUEST,
});

const errorStatisticsRequest = error => ({
  type: ERROR_STATISTICS_REQUEST,
  payload: error,
});

const successStatisticsRequest = data => ({
  type: SUCCESS_STATISTICS_REQUEST,
  payload: data,
});

const getWordWritingStatistics = unitUrl => async (dispatch, getState, api) => {
  dispatch(startStatisticsRequest());
  try {
    const state = getState();
    const token = authTokenSelector(state);
    const data = await api.results.getWordWritingResults(unitUrl, token);

    dispatch(successStatisticsRequest(data));
  } catch (e) {
    dispatch(errorStatisticsRequest(e));
    dispatch(addMessage(createErrorMessage('Can not fetch statistics for test')));
    if (e.statusCode === 401) {
      await logout()(dispatch, getState, api);
    }
  }
};

const getWordWritingStatisticsByLogin = (login, unitUrl) => async (dispatch, getState, api) => {
  dispatch(startStatisticsRequest());
  try {
    const state = getState();
    const token = authTokenSelector(state);
    const data = await api.results.getWordWritingResultsByLogin(unitUrl, login, token);

    dispatch(successStatisticsRequest(data));
  } catch (e) {
    dispatch(errorStatisticsRequest(e));
    dispatch(addMessage(createErrorMessage('Can not fetch statistics for test')));
    if (e.statusCode === 401) {
      await logout()(dispatch, getState, api);
    }
  }
};

const getPhrasesStatistics = unitUrl => async (dispatch, getState, api) => {
  dispatch(startStatisticsRequest());
  try {
    const state = getState();
    const token = authTokenSelector(state);
    const data = await api.results.getPhrasesResults(unitUrl, token);

    dispatch(successStatisticsRequest(data));
  } catch (error) {
    dispatch(errorStatisticsRequest(error));
    dispatch(addMessage(createErrorMessage('Could not fetch statistics')));
    if (error.statusCode === 401) {
      await logout()(dispatch, getState, api);
    }
  }
};

const getPhrasesStatisticsByLogin = (login, unitUrl) => async (dispatch, getState, api) => {
  dispatch(startStatisticsRequest());

  try {
    const state = getState();
    const token = authTokenSelector(state);
    const data = await api.results.getPhrasesResultsByLogin(unitUrl, token, login);

    dispatch(successStatisticsRequest(data));
  } catch (error) {
    dispatch(errorStatisticsRequest(error));
    dispatch(addMessage(createErrorMessage('Could not fetch statistics')))
    if (error.statusCode === 401) {
      await logout()(dispatch, getState, api);
    }
  }
};

const getStatistics = (testType, unitUrl) =>
  (dispatch, getState, api) => {
  const action = {
    'words-writing': getWordWritingStatistics,
    'phrases': getPhrasesStatistics,
  }[testType];

  return action
    ? action(unitUrl)(dispatch, getState, api)
    : Promise.resolve({});
  };

const getStatisticsByLogin = (login, testType, unitUrl) =>
  (dispatch, getState, api) => {
    const action = {
      'words-writing': getWordWritingStatisticsByLogin,
      'phrases': getPhrasesStatisticsByLogin,
    }[testType];

    return action
      ? action(login, unitUrl)(dispatch, getState, api)
      : Promise.resolve({});
  };

export {
  getWordWritingStatistics,
  getWordWritingStatisticsByLogin,
  getPhrasesStatistics,
  getPhrasesStatisticsByLogin,
  getStatistics,
  getStatisticsByLogin,
};
