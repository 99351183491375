import { useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ResponsiveBar } from '@nivo/bar';

/**
 * @typedef DataItem
 * @property {string} id
 * @property {number} index
 * @property {string} color
 * @property {string | number} value
 * @property {string} date
 */

/**
 * @param {DataItem[]} data
 * @param {string[]} keys
 * @param {string} indexBy
 * @param {Function} onClick
 */
const StatisticBar = ({
  data,
  keys,
  indexBy,
  onClick,
}) => {
  const handleClick = useCallback(({ data: item }) => {
    onClick(item);
  }, [onClick]);

  const getBarColor = useCallback(({ data: { color } }) => color, [] );

  const getBarTooltip = useCallback(({ data: { date }, formattedValue }) => {
    return (
      <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="h5">{formattedValue}</Typography>
        <Typography variant="caption">{date}</Typography>
      </Paper>
    );
  }, []);

  const formatValue = useCallback(value => `${Number(value).toLocaleString('ru-RU',
    { minimumFractionDigits: 0 })}%`, []);

  return (
    <ResponsiveBar
      onClick={handleClick}
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={{ top: 50, right: 16, bottom: 50, left: 60 }}
      padding={0.4}
      valueScale={{ type: "linear" }}
      indexScale={{ type: 'band', round: true }}
      colors={getBarColor}
      animate={true}
      enableLabel={false}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Index',
        legendPosition: 'middle',
        legendOffset: 32
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Result, %",
        legendPosition: "middle",
        legendOffset: -40
      }}
      tooltip={getBarTooltip}
      valueFormat={formatValue}
    />
  );
};

export default StatisticBar;
