import { Outlet } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import AdminScopesList from './AdminScopesList';
import { AdminScopeContextProvider } from './admin-scopes-context';

const AdminScopes = () => {
  return (
    <AdminScopeContextProvider>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={5} sx={{ height: '100%' }}>
          <AdminScopesList />
        </Grid>
        <Grid item xs={7} sx={{ height: '100%' }}>
          <Outlet />
        </Grid>
      </Grid>
    </AdminScopeContextProvider>
  );
};

export default AdminScopes;
