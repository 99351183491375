const uiSelector = state => state.ui;

const sidebarOpenSelector = state => uiSelector(state).sidebarOpen;

const confirmationModalSelector = state => uiSelector(state).confirmationModal;

const confirmationModalOpenSelector = state => confirmationModalSelector(state).open;

const confirmationModalTitleSelector = state => confirmationModalSelector(state).title;

const confirmationModalMessageSelector = state => confirmationModalSelector(state).message;

const confirmationModalActionSelector = state => confirmationModalSelector(state).action;


export {
  sidebarOpenSelector,
  confirmationModalActionSelector,
  confirmationModalTitleSelector,
  confirmationModalMessageSelector,
  confirmationModalOpenSelector,
};
