import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Topic = ({ header, children, sx = {} }) => {
  return (
    <Box sx={sx}>
      <Typography variant="h4" p={1} sx={{ flex: '0 1 auto' }}>
        {header}
      </Typography>
      <Box sx={{ p: 1, flex: '1 1 auto', overflow: 'hidden', height: '100%' }}>
        {children}
      </Box>
    </Box>
  );
};

export default Topic;
