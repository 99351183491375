class NetworkError {
  constructor(statusCode, message, date) {
    this.statusCode = statusCode;
    this.message = message;
    this.date = date;
  }
}

const getNetworkError = payload => new NetworkError(
  payload.statusCode || 0,
  payload.message || 'Something went wrong',
  payload.date || new Date().getTime(),
);

const getRequest = (options = { method: 'GET' }) =>
  async (url, data = null, token = '') => {
    try {
      if (options.method !== 'GET' && data) {
        options.body = JSON.stringify(data);
      }
      options.credentials = 'include';
      options.cors = 'cors';
      if (token) {
        options.headers = options.headers
          ? options.headers
          : {};
        options.headers['X-Session-Token'] = token;
      }
      const response = await fetch(url, options);
      const contentType = response.headers.get('Content-Type');
      let payload;
      if (contentType && contentType.includes('application/json')) {
        payload = await response.json();
      } else if (contentType && contentType.includes('text')) {
        payload = await response.text();
      }

      if (!response.ok) {
        if (!payload || typeof payload === 'string') {
          payload = { statusCode: response.status, message: response.statusText };
        }

        return Promise.reject(getNetworkError(payload));
      }

      return payload;
    } catch (error) {
      const formattedError = getNetworkError(error);

      return Promise.reject(formattedError);
    }
};

const get = getRequest();

const post = getRequest({
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
});

const put = getRequest({
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
});

const deleteRequest = getRequest({
  method: 'DELETE',
});

export {
  deleteRequest,
  get,
  post,
  put,
};
