import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';

import { openSidebar } from '../../../../redux/actions';

const SideBarMenuButton = ({
  onClick,
}) => {
  return (
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="open drawer"
      sx={{ mr: 2 }}
      onClick={onClick}
    >
      <MenuIcon />
    </IconButton>
  );
};

SideBarMenuButton.propTypes = {
  onClick: PropTypes.func,
};

const mapDispatchToProps = {
  onClick: openSidebar,
};

export default connect(null, mapDispatchToProps)(SideBarMenuButton);
