import {
  CLOSE_SIDEBAR,
  OPEN_SIDEBAR,
  CLOSE_CONFIRMATION_MODAL,
  OPEN_CONFIRMATION_MODAL,
} from '../../actions/action-types';

const initialState = {
  sidebarOpen: false,
  confirmationModal: {
    action: null,
    open: false,
    title: '',
    message: '',
  },
};

const uiReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case OPEN_SIDEBAR:
      return {
        ...state,
        sidebarOpen: true,
      };

    case CLOSE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: false,
      };

    case OPEN_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModal: {
          action: payload.action,
          message: payload.message,
          open: true,
          title: payload.title,
        },
      };

    case CLOSE_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModal: {
          action: null,
          message: '',
          open: false,
          title: '',
        },
      };

    default:
      return state;
  }
};

export default uiReducer;
