const createScopeFilter = searchValue => ({ name, value }) => {
  if (searchValue) {
    const filter = searchValue.toLowerCase();
    const lowerName = name.toLowerCase();
    const lowerValue = value.toLowerCase();

    return lowerName.includes(filter)
      || lowerValue.includes(filter);
  }

  return true;
};

export default createScopeFilter;
