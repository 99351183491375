import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import CurrentUserAccount from './shared/CurrentUserAccount';
import SearchInput from './shared/SearchInput';
import SideBarMenuButton from './shared/SideBarMenuButton';

const Header = () => {
  return (
    <Box sx={{ display: 'flex', /*flexGrow: 1*/ flex: '0 1 auto' }}>
      <AppBar position="static">
        <Toolbar>
          <Grid container direction="row" wrap="nowrap" justifyContent="space-between" alignItems="center">
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <SideBarMenuButton />
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
              >
                FUNNY ENGLISH
              </Typography>
            </Grid>
            <Grid container direction="row" wrap="nowrap" justifyContent="flex-end" alignItems="center">
              <SearchInput />
              <CurrentUserAccount />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
