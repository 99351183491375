import PropTypes from 'prop-types';

import WordsWritingContentTestResult from './WordsWritingContentTestResult';
import TestResultsModal from '../shared/TestResultsModal';

const WordsWritingTestResultModal = ({ data, open, onClose }) => (
  <TestResultsModal
    data={data}
    testResult={WordsWritingContentTestResult}
    open={open}
    onClose={onClose}
  />
);

WordsWritingTestResultModal.propTypes = {
  data: PropTypes.shape({}),
  date: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default WordsWritingTestResultModal;
