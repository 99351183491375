import {
  START_AUTH_REQUEST,
  ERROR_LOGIN,
  ERROR_LOGOUT,
  SUCCESS_LOGIN,
  SUCCESS_LOGOUT,
  COMPLETE_AUTH_CHECK,
  START_PERSONAL_ACCOUNT_REQUEST,
  ERROR_PERSONAL_ACCOUNT_REQUEST,
  SUCCESS_PERSONAL_ACCOUNT_REQUEST,
  START_CHANGE_PASSWORD_REQUEST,
  ERROR_CHANGE_PASSWORD_REQUEST,
  SUCCESS_CHANGE_PASSWORD_REQUEST,
} from '../../actions/action-types';
import setEitherRemove from '../../../utils/local-storage/set-either-remove';

const getInitialAuth = () => ({
  login: localStorage.getItem('login') || '',
  token: localStorage.getItem('token') || '',
});

const setAuthParams = (login, token) => {
  setEitherRemove('login', login);
  setEitherRemove('token', token);
};

const clearAuthParams = () => { setAuthParams(); };

const getInitialState = () => ({
  error: null,
  errorLogout: null,
  errorPersonalAccount: null,
  errorChangePassword: null,
  requestInProgress: false,
  email: '',
  ...getInitialAuth(),
});

const authReducer = (state = getInitialState(), action = {}) => {
  const { type, payload } = action;

  switch(type) {
    case START_AUTH_REQUEST:
      return {
        ...state,
        error: null,
        errorLogout: null,
        requestInProgress: true,
      };

    case ERROR_LOGIN:
      clearAuthParams();
      return {
        ...state,
        error: payload,
        requestInProgress: false,
        login: '',
        token: '',
      };

    case SUCCESS_LOGIN: {
      const { login, token } = payload;
      setAuthParams(login, token)
      return {
        ...state,
        error: null,
        errorLogout: null,
        login: login,
        token: token,
        requestInProgress: false,
      };
    }


    case ERROR_LOGOUT:
      clearAuthParams();
      return {
        ...state,
        requestInProgress: false,
        errorLogout: payload,
      };

    case SUCCESS_LOGOUT:
      clearAuthParams();
      return {
        ...getInitialState(),
      };

    case COMPLETE_AUTH_CHECK:
      return {
        ...state,
        requestInProgress: false,
      };

    case START_PERSONAL_ACCOUNT_REQUEST:
      return {
        ...state,
        requestInProgress: true,
      };

    case ERROR_PERSONAL_ACCOUNT_REQUEST:
      return {
        ...state,
        requestInProgress: false,
        errorPersonalAccount: payload,
        email: '',
      };

    case SUCCESS_PERSONAL_ACCOUNT_REQUEST:
      setAuthParams(payload.login, state.token);
      return {
        ...state,
        requestInProgress: false,
        errorPersonalAccount: null,
        ...payload,
      };

    case START_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        requestInProgress: true,
      };

    case ERROR_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        requestInProgress: false,
        errorChangePassword: payload,
      };

    case SUCCESS_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        requestInProgress: false,
        errorChangePassword: null,
      };

    default:
      return state;
  }
};

export default authReducer;
