import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { isFilledArray } from '../../../../utils/array';
import StatisticBar from '../../../charts/StatisticBar';
import WordsWritingTestResultModal from '../WordsWritingTestResultModal';
import getPercentData from '../../../../utils/statistics/get-percent-data';
import URLS from '../../../../constants/urls';
import Fab from '@mui/material/Fab';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';

const Statistics = ({
  data,
  error,
  requestInProgress,
  unitName,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const { unitUrl, login } = useParams();
  const navigate = useNavigate();

  const adminMode = !!login;

  const handleCloseTestResult = useCallback(() => {
    setSelectedItem(null);
  }, []);

  const handleClickTestResult = useCallback(item => {
    const dataItem = data.find(({ id }) => id === item.id);
    setSelectedItem(dataItem);
  }, [data]);

  const handleStartNewTest = useCallback(() => {
    navigate(`${URLS.UNITS}/${unitUrl}/tests/words-writing/in-progress`);
  }, [navigate, unitUrl]);

  const percentData = useMemo(() => getPercentData(data), [data]);

  return (
    <>
      <Typography variant="h6">Words writing</Typography>
      <div>{unitName}</div>
      {requestInProgress && (
        <Box sx={{ mt: 2 }}>Loading...</Box>
      )}
      {!(error || requestInProgress) && isFilledArray(data) && (
        <div id={"percent-data"} style={{ height: 400 }}>
          <WordsWritingTestResultModal
            open={!!selectedItem}
            onClose={handleCloseTestResult}
            data={selectedItem}
          />
          <StatisticBar
            data={percentData}
            keys={['value']}
            indexBy="index"
            onClick={handleClickTestResult}
          />
        </div>
      )}
      {!(error || requestInProgress) && !isFilledArray(data) && (
        <Box sx={{ mt: 2 }}>No data to display</Box>
      )}
      {!requestInProgress && !!error && (
        <Box>{(error && error.message) || 'Error: something went wrong'}</Box>
      )}
      {!adminMode && (
        <Box
          sx={{
            mt: 3,
            width: '100%',
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <Fab
            variant="extended"
            color="secondary"
            onClick={handleStartNewTest}
          >
            <PlayCircleFilledWhiteIcon sx={{ mr: 1 }} />
            Start new test
          </Fab>
        </Box>
      )}
    </>
  );
};

export default Statistics;
