import { ADD_MESSAGE, REMOVE_MESSAGE, CLEAR_MESSAGES } from '../action-types';

const addMessage = message => ({
  type: ADD_MESSAGE,
  payload: message,
});

const removeMessage = messageId => ({
  type: REMOVE_MESSAGE,
  payload: messageId,
});

const clearMessages = () => ({
  type: CLEAR_MESSAGES,
});

export {
  addMessage,
  removeMessage,
  clearMessages,
};
