import { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import TileCard from '../../../TileCard';
import {
  wordIdSelector,
  wordUrlSelector,
  wordEnSelector,
  wordRuSelector,
  wordTranscriptionSelector,
} from '../../../../redux/selectors';
import {
  deleteWord,
  getWords,
  openConfirmationModal as openConfirmationModalAction,
  saveWord,
} from '../../../../redux/actions';
import { useAdminWordsContext } from './admin-words-context';
import FormModes from '../../../../constants/form-modes';
import URLS from '../../../../constants/urls';

const initialState = {
  url: '',
  en: '',
  ru: '',
  transcription: '',
};

const initialValidationData = {
  url: '',
  en: '',
  ru: '',
  transcription: '',
};

const AdminWordForm = props => {
  const {
    deleteItem,
    en,
    id,
    openConfirmationModal,
    refreshWords,
    ru,
    saveData,
    transcription,
    url,
  } = props;
  const navigate = useNavigate();
  const [state, setState] = useState({ ...initialState });
  const [validationData, setValidationData] = useState(initialValidationData);
  const { wordUrl } = useParams();
  const {
    mode,
    handleEdit: onEdit,
    handleCancel: onCancel,
  } = useAdminWordsContext();

  const handleEdit = useCallback(() => {
    onEdit();
    setState({ url, en, ru, transcription });
  }, [url, en, ru, transcription, onEdit]);

  const handleCancel = useCallback(() => {
    onCancel();
    setState({ ...initialState });
    if (wordUrl === 'new') {
      navigate(`${URLS.ADMINISTRATION}/words`);
    }
  }, [navigate, onCancel, wordUrl]);

  const handleSave = useCallback(() => {
    const word = {
      url: state.url,
      en: state.en,
      ru: state.ru,
      transcription: state.transcription,
      ...(id && { id }),
    };
    saveData(word, result => {
      if (result && result.valid === false) {
        setValidationData(result.data);
      } else {
        setValidationData(initialValidationData);
        setState({ ...initialState });
        refreshWords();
        onCancel();
        navigate(`${URLS.ADMINISTRATION}/words/${state.url}`);
      }
    });
  }, [
    id,
    navigate,
    onCancel,
    refreshWords,
    saveData,
    state.en,
    state.ru,
    state.transcription,
    state.url,
  ]);

  const handleDelete = useCallback( () => {
    openConfirmationModal({
      title: `Delete word ${en}`,
      message: `Please confirm that you are going to delete word ${en}?`,
      action: () => {
        deleteItem(url, () => {
          refreshWords();
          onCancel();
          navigate(`${URLS.ADMINISTRATION}/words`);
        });
      },
    });
  }, [deleteItem, en, navigate, onCancel, openConfirmationModal, refreshWords, url]);

  const handleChange = useCallback(({ target: { name, value } }) => {
    setState({ ...state, [name]: value });
  }, [setState, state]);

  const handleSubmit = useCallback(e => {
    e.preventDefault();
  }, []);

  const handleKeyPress = useCallback(({ key }) => {
    if (key === 'Enter') {
      handleSave();
    }
  }, [handleSave]);

  const getTextField = useCallback((field, label) => (
    <TextField
      error={!!validationData[field]}
      disabled={mode === FormModes.NONE}
      fullWidth
      id={field}
      label={label}
      helperText={validationData[field] || ''}
      name={field}
      onChange={handleChange}
      variant="standard"
      value={(mode === FormModes.NONE ? props[field] : state[field]) || ''}
      inputProps={{
        onKeyPress: handleKeyPress,
      }}
    />
  ), [handleChange, handleKeyPress, mode, props, state, validationData]);

  const enTextField = useMemo(
    () => getTextField('en', 'English'),
    [getTextField]
  );

  const ruTextField = useMemo(
    () => getTextField('ru', 'Russian'),
    [getTextField]
  );

  const transcriptionTextField = useMemo(
    () => getTextField('transcription', 'Transcription'),
    [getTextField]
  );

  const urlTextField = useMemo(
    () => getTextField('url', 'URL'),
    [getTextField]
  );

  return (
    <TileCard sx={{ height: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flow: '0 1 auto' }}>
        <Typography variant="h6">
          {en || 'Word'}
        </Typography>
        {mode === FormModes.NONE && (
          <Box>
            <Fab
              aria-label="Edit"
              color="primary"
              size="small"
              onClick={handleEdit}
              disabled={wordUrl === 'new'}
            >
              <EditIcon />
            </Fab>
          </Box>
        )}
        {mode !== FormModes.NONE && (
          <Box>
            <Fab
              aria-label="Cancel"
              color="default"
              size="small"
              onClick={handleCancel}
              sx={{ mr: 1 }}
            >
              <CancelIcon />
            </Fab>
            <Fab
              aria-label="Delete"
              color="error"
              size="small"
              onClick={handleDelete}
              disabled={wordUrl === 'new'}
              sx={{ mr: 1 }}
            >
              <RemoveCircleIcon />
            </Fab>
            <Fab
              aria-label="Save"
              color="success"
              size="small"
              onClick={handleSave}
              disabled={false}
            >
              <SaveIcon />
            </Fab>
          </Box>
        )}
      </Grid>
      <CardContent
        sx={{
          flex: '1 1 auto',
          height: 'calc(100% - 36px)',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        <form action="" name="word" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {enTextField}
            </Grid>
            <Grid item xs={12}>
              {ruTextField}
            </Grid>
            <Grid item xs={12}>
              {transcriptionTextField}
            </Grid>
            <Grid item xs={12}>
              {urlTextField}
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </TileCard>
  );
};

const mapStateToProps = state => ({
  id: wordIdSelector(state),
  url: wordUrlSelector(state),
  en: wordEnSelector(state),
  ru: wordRuSelector(state),
  transcription: wordTranscriptionSelector(state),
});

const mapDispatchToProps = {
  openConfirmationModal: openConfirmationModalAction,
  deleteItem: deleteWord,
  refreshWords: getWords,
  saveData: saveWord,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminWordForm);
