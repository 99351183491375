import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';

import ApplicationContent from '../../routing/ApplicationContent';

import configureStore from '../../redux';

const store = configureStore();
const theme = responsiveFontSizes(createTheme());

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <ApplicationContent />
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </SnackbarProvider>
  );
}

export default App;
