import { createSelector } from 'reselect';
import { routerParamsSelector } from '../router';

const usersSelector = state => state.users;

const usersDataSelector = state => usersSelector(state).data;

const usersLoadingSelector = state => usersSelector(state).loading;

const usersErrorSelector = state => usersSelector(state).error;

const userSelector = createSelector(
  [usersDataSelector, routerParamsSelector],
  (data, params) => {
    if (Array.isArray(data) && params && params.login) {
      return  data.find(({ login }) => login === params.login);
    } else {
      return null;
    }
  },
);

const userLoginSelector = createSelector(
  [userSelector],
  user => user ? user.login : '',
);

const userEmailSelector = createSelector(
  [userSelector],
  user => user ? user.email : '',
);

export {
  usersDataSelector,
  usersLoadingSelector,
  usersErrorSelector,
  userSelector,
  userLoginSelector,
  userEmailSelector,
};
