const scrollIntoView = id => {
  const elm = document.getElementById(id);
  if (elm) {
    elm.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }
};

const scrollIntoViewIfNecessary = (id, containerId) => {
  const elm = document.getElementById(id);
  const box = document.getElementById(containerId);
  if (elm && box) {
    const rect = elm.getBoundingClientRect();
    const boxRect = box.getBoundingClientRect();
    if ((rect.top < boxRect.top) || (rect.bottom > boxRect.bottom)) {
      elm.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }
};

export { scrollIntoView, scrollIntoViewIfNecessary };
