import { get, post } from '../utils/api/request';
import URLS from '../constants/urls';

const getWordWritingResults = (unitUrl, token) =>
  get(`${URLS.API_RESULTS_WORD_WRITING}?unit=${unitUrl}`, null, token);

const getWordWritingResultsByLogin = (unitUrl, login, token) =>
  get(`${URLS.API_RESULTS_WORD_WRITING}?unit=${unitUrl}&login=${login}`, null, token);

const saveWordsWritingResults = (data, token) =>
  post(`${URLS.API_RESULTS_WORD_WRITING}`, data, token);

const getPhrasesResults = (unitUrl, token) =>
  get(`${URLS.API_RESULTS_PHRASES}?unit=${unitUrl}`, null, token);

const getPhrasesResultsByLogin = (unitUrl, token, login) =>
  get(`${URLS.API_RESULTS_PHRASES}?unit=${unitUrl}&login=${login}`, null, token);

const savePhrasesResults = (data, token) =>
  post(`${URLS.API_RESULTS_PHRASES}`, data, token);

const resultsApi = {
  getWordWritingResults,
  getWordWritingResultsByLogin,
  saveWordsWritingResults,
  getPhrasesResults,
  getPhrasesResultsByLogin,
  savePhrasesResults,
};

export default resultsApi;
