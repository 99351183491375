import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

import { setSearch } from '../../../../redux/actions';
import { searchValueSelector } from '../../../../redux/selectors';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const SearchInput = ({
  value,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleInputChange = ({ target: { value: newValue } }) => {
    setInputValue(newValue);
  };

  const handleSearch = () => {
    onChange(inputValue);
  };

  const handleKeyUp = ({ code }) => {
    if (code === 13 || code === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search…"
        inputProps={{
          'aria-label': 'search',
          onChange: handleInputChange,
          onBlur: handleSearch,
          onKeyUp: handleKeyUp,
          value: inputValue,
        }}
      />
    </Search>
  );
};

SearchInput.propTypes = {
  value: PropTypes.string,
};

const mapStateToProps = state => ({
  value: searchValueSelector(state),
});

const mapDispatchToProps = {
  onChange: setSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);
