import { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import {
  searchValueSelector,
  unitsDataSelector,
  unitsErrorSelector,
  unitsLoadingSelector,
} from '../../../../redux/selectors';
import TileCard from '../../../TileCard';
import {useAdminUnitContext} from './admin-units-context';
import { getUnits } from '../../../../redux/actions';
import URLS from '../../../../constants/urls';
import FormModes from '../../../../constants/form-modes';
import createUnitFilter from '../../../../utils/filters/create-unit-filter';
import { scrollIntoViewIfNecessary } from '../../../../utils/ui/scroll-into-view';

const unitsBoxId = 'units-container';

const AdminUnitsList = ({
  data,
  getData,
  loading,
  error,
  searchValue,
}) => {
  const navigate = useNavigate();
  const { unitUrl } = useParams();
  const { mode } = useAdminUnitContext();

  const disabled = (mode !== FormModes.NONE) || (unitUrl === 'new');

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    scrollIntoViewIfNecessary(unitUrl, unitsBoxId);
  }, [data, unitUrl]);

  const handleSelect = useCallback(
    url => () => { navigate(`${URLS.ADMINISTRATION}/units/${url}`) },
    [navigate],
  );

  const items = useMemo(() => {
    return Array.isArray(data)
      ? data
        .filter(createUnitFilter(searchValue))
        .map(({ id, name, url }) => {
          return (
            <ListItemButton
              id={url}
              key={id}
              selected={unitUrl === url}
              onClick={handleSelect(url)}
              disabled={disabled}
            >
              <ListItemText primary={name} />
            </ListItemButton>
          );
        })
      : null;
  }, [data, disabled, handleSelect, searchValue, unitUrl]);

  return (
    <TileCard sx={{ height: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '0 1 auto' }}>
        <Typography variant="h6">
          List of units
        </Typography>
        <Fab
          aria-label="Add unit"
          color="primary"
          size="small"
          onClick={handleSelect('new')}
          disabled={disabled}
        >
          <AddIcon />
        </Fab>
      </Grid>
      <CardContent sx={{ flex: '1 1 auto', height: 'calc(100% - 32px)' }}>
        {loading && <div>Loading...</div>}
        {!loading && error ? <div>Error: {error.message}</div> : null}
        {!(loading || error) && data ? (
            <List
              id={unitsBoxId}
              component="nav"
              aria-label="main mailbox folders"
              sx={{
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
            >
              {items}
            </List>
          ) : null
        }
      </CardContent>
    </TileCard>
  );
};

const mapStateToProps = state => ({
  data: unitsDataSelector(state),
  loading: unitsLoadingSelector(state),
  error: unitsErrorSelector(state),
  searchValue: searchValueSelector(state),
});

const mapDispatchToProps = {
  getData: getUnits,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUnitsList);
