import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';

import { getUnits as getUnitsAction } from '../../../../../redux/actions';
import {
  unitsDataSelector,
  unitsErrorSelector,
  unitsLoadingSelector,
} from '../../../../../redux/selectors';
import { useCallback, useEffect, useMemo } from 'react';
import { isFilledArray } from '../../../../../utils/array';
import URLS from '../../../../../constants/urls';
import { ONE_WEEK_MS } from '../../../../../constants/time-constants';
import getStatisticsResultsAverage
  from '../../../../../utils/statistics/get-statistics-results-average';
import TESTS from '../../../../../constants/tests';

const UnitsList = styled('ul')(() => ({
  listStyle: 'none',
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: 0,
}));

const Unit = styled('li')(() => ({
  margin: '0.8rem',
}));

const AdminUserUnitsStatistics = ({
  getUnits,
  units,
  loading,
  error,
}) => {
  const navigate = useNavigate();
  const { login, testType } = useParams();

  const { label } = TESTS[testType];

  useEffect(() => {
    getUnits(null, login, testType);
  }, [getUnits, login, testType]);

  const handleItemClick = useCallback(url => () => {
    if (login && testType) {
      navigate(`${URLS.ADMINISTRATION}/users/${login}/statistics/${testType}/${url}`);
    }
  }, [login, navigate, testType]);

  const unitsList = useMemo(() => (
    isFilledArray(units) ? (
      <UnitsList>
        {units.map(({ id, name, url, results }) => {
          const average = getStatisticsResultsAverage(results);
          const count = results.length;
          const content = average ? `${average}%` : 0;
          const lastTs = count > 0 ? results[count - 1].createdTs : 0;
          const now = new Date().getTime();
          const duringTheWeek = (now - lastTs) <= ONE_WEEK_MS;
          let color;
          switch (true) {
            case duringTheWeek && average > 90:
              color = "success";
              break;
            case duringTheWeek && average > 80:
              color = "secondary";
              break;
            case !duringTheWeek && average > 75:
              color = "warning";
              break;
            default:
              color = "error";
          }

          return (
            <Unit key={id}>
              <Badge
                badgeContent={content}
                color={color}
              >
                <Fab
                  variant="extended"
                  color="primary"
                  onClick={handleItemClick(url)}
                >
                  {name}
                </Fab>
              </Badge>
            </Unit>
          );
        })}
      </UnitsList>
    ) : <div>No data to display</div>
  ), [handleItemClick, units]);

  return (
    <>
      <Typography variant="h6">
        {label}
      </Typography>
      <Box>
        {!units && loading && (
          <div>Loading...</div>
        )}
        {!!error && !loading && (
          <div>Error: {error.message}</div>
        )}
        {!!units && unitsList}
      </Box>
    </>
  );
};

const mapStateToProps = state => ({
  units: unitsDataSelector(state),
  loading: unitsLoadingSelector(state),
  error: unitsErrorSelector(state),
});

const mapDispatchToProps = {
  getUnits: getUnitsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserUnitsStatistics);
