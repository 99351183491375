const routerSelector = state => state.router;

const routerParamsSelector = state => routerSelector(state).params;

const routerEntitySelector = state => routerSelector(state).entity;

export {
  routerEntitySelector,
  routerParamsSelector,
};
