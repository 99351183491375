import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import BarChartIcon from '@mui/icons-material/BarChart';
import Box from '@mui/material/Box';
import URLS from '../../../constants/urls';

const WordsWritingContentHome = () => {
  const navigate = useNavigate();
  const { unitUrl } = useParams();

  const handleStart = useCallback(() => {
    navigate(`${URLS.UNITS}/${unitUrl}/tests/words-writing/in-progress`);
  }, [navigate, unitUrl]);

  const handleStatistics = useCallback(() => {
    navigate(`${URLS.UNITS}/${unitUrl}/tests/words-writing/statistics`);
  }, [navigate, unitUrl]);

  return (
    <Box sx={{ flex: '0 1 auto' }}>
      <Fab
        variant="extended"
        color="secondary"
        sx={{ mt: 3 }}
        onClick={handleStart}
      >
        <PlayCircleFilledWhiteIcon sx={{ mr: 1 }} />
        Start new test
      </Fab>
      <Fab
        variant="extended"
        color="secondary"
        sx={{ mt: 3, ml: 3 }}
        onClick={handleStatistics}
      >
        <BarChartIcon sx={{ mr: 1 }} />
        Statistics
      </Fab>
    </Box>
  );
};

export default WordsWritingContentHome;
