import { Outlet } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import AdminWordsList from './AdminWordsList';
import { AdminWordsContextProvider } from './admin-words-context';

const AdminWords = () => {
  return (
    <AdminWordsContextProvider>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={5} sx={{ height: '100%' }}>
          <AdminWordsList />
        </Grid>
        <Grid item xs={7} sx={{ height: '100%' }}>
          <Outlet />
        </Grid>
      </Grid>
    </AdminWordsContextProvider>
  );
};

export default AdminWords;
