import { Outlet } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import AdminEndpointsList from './AdminEndpointsList';
import { AdminEndpointContextProvider } from './admin-endpoints-context';

const AdminEndpoints = () => {
  return (
    <AdminEndpointContextProvider>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={5} sx={{ height: '100%' }}>
          <AdminEndpointsList />
        </Grid>
        <Grid item xs={7} sx={{ height: '100%' }}>
          <Outlet />
        </Grid>
      </Grid>
    </AdminEndpointContextProvider>
  );
};

export default AdminEndpoints;
