import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

import { isFilledArray } from '../../../../../utils/array';
import { useCallback, useMemo } from 'react';

const AdminScopesListControl = ({
  disabled,
  onChange,
  value,
  data,
}) => {
  const { palette } = useTheme();

  const handleChange = useCallback(value => () => {
    onChange(value);
  }, [onChange]);

  const processedData = useMemo(() => (
    isFilledArray(data) ? data.reduce((acc, cur) => {
      const checked = value.includes(cur.value);

      if (!disabled || checked) acc.push({ ...cur, checked });

      return acc;
    }, []) : []
    ),[data, disabled, value]);

  const textColor = disabled ? palette.grey['500'] : 'inherit';

  return (
    <>
      <Typography
        variant="caption"
        sx={{
          color: textColor,
          userSelect: 'none'
        }}
      >
        Scopes list ({`${value.length ? value.length : 'empty'}`})
      </Typography>
      {isFilledArray(processedData) ? (
        <List
          dense
          sx={{
            // maxHeight: 500,
            // overflowY: 'auto',
            // overflowX: 'hidden',
          }}
        >
          {processedData.map(({ id, value: scopeValue, checked }) => (
            <ListItem key={id} disabled={disabled}>
              {!disabled && (
                <ListItemIcon>
                  <Checkbox
                    checked={checked}
                    disabled={disabled}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': scopeValue,
                    }}
                    onChange={handleChange(scopeValue)}
                  />
                </ListItemIcon>
              )}
              <ListItemText>
                {scopeValue}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography sx={{ color: textColor, p: 2 }}>
          No data to display
        </Typography>
      )}
    </>
  );
};

export default AdminScopesListControl;
