import {
  START_ENDPOINTS_LOADING,
  ERROR_ENDPOINTS_LOADING,
  SUCCESS_ENDPOINTS_LOADING,
  START_ENDPOINT_SAVING,
  ERROR_ENDPOINT_SAVING,
  SUCCESS_ENDPOINT_SAVING,
} from '../../actions/action-types';
import {
  createEntityReducer,
  errorEntityLoading, errorEntitySaving,
  startEntityLoading,
  startEntitySaving,
  successEntityLoading, successEntitySaving
} from '../../../utils/reducer/reducer-utils';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const resolvers = {
  [START_ENDPOINTS_LOADING]: startEntityLoading,
  [ERROR_ENDPOINTS_LOADING]: errorEntityLoading,
  [SUCCESS_ENDPOINTS_LOADING]: successEntityLoading,
  [START_ENDPOINT_SAVING]: startEntitySaving,
  [ERROR_ENDPOINT_SAVING]: errorEntitySaving,
  [SUCCESS_ENDPOINT_SAVING]: successEntitySaving,
};

const endpointsReducer = createEntityReducer({
  resolvers,
  initialState,
})

export default endpointsReducer;
