import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import HttpIcon from '@mui/icons-material/Http';
import KeyIcon from '@mui/icons-material/Key';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';

const PARTITIONS = {
  units: {
    index: 1,
    label: 'Units',
    icon: SchoolIcon,
  },
  words: {
    index: 2,
    label: 'Words',
    icon: SchoolIcon,
  },
  phrases: {
    index: 3,
    label: 'Phrases',
    icon: SchoolIcon,
  },
  scopes: {
    index: 4,
    label: 'Scopes',
    icon: KeyIcon,
  },
  endpoints: {
    index: 5,
    label: 'Endpoints',
    icon: HttpIcon,
  },
  users: {
    index: 6,
    label: 'Users',
    icon: PeopleIcon,
  },
};

const PARTITIONS_LIST = Object.keys(PARTITIONS)
  .map(key => ({ value: key, ...PARTITIONS[key] }))
  .sort((p1, p2) => p1.index - p2.index);

const AdminHome = () => {
  const navigate = useNavigate();

  const handlePartitionClick = useCallback(value => () => {
    navigate(`/administration/${value}`);
  }, [navigate]);

  const items = useMemo(() => {
    return PARTITIONS_LIST.map(({ value, label, icon: Icon }) => (
      <Fab
        key={value}
        variant="extended"
        color="secondary"
        sx={{ m: 1 }}
        onClick={handlePartitionClick(value)}
      >
        <Icon sx={{ mr: 1 }} />
        {label}
      </Fab>
    ));
  }, [handlePartitionClick]);

  return (
    <div>{items}</div>
  );
};

export default AdminHome;
