import { Outlet, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';

import Topic from '../../Topic';
import TESTS from '../../../constants/tests';
import StatisticsNetworkService from './StatisticsNetworkService';

const StatisticsIndex = () => {
  const { testType } = useParams();
  const label = TESTS[testType]?.label || 'Unknown type of test';
  const header = `Statistics${testType ? `: ${label}` : ''}`;

  return (
    <>
      <StatisticsNetworkService />
      <Box sx={{ width: '100%', height: '100%', display: 'flex', flexFlow: 'column nowrap'}}>
        <Topic header={header} sx={{ height: 'calc(100% - 32px)' }}>
          <Outlet />
        </Topic>
      </Box>
    </>
  );
};

export default StatisticsIndex;
