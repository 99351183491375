import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

const TESTS = {
  'words-writing': {
    index: 1,
    label: 'Words writing',
    icon: DriveFileRenameOutlineIcon,
  },
  'phrases': {
    index: 2,
    label: 'Phrases',
    icon: FormatListNumberedIcon,
  },
};

export default TESTS;
