import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getStatisticsByLogin }
  from '../../../../../redux/actions';
import { useEffect } from 'react';

const AdminUserStatisticsNetworkService = ({
  getData,
}) => {
  const { testType, login, unitUrl } = useParams();

  useEffect(() => {
    if (login && testType && unitUrl) {
      getData(login, testType, unitUrl);
    }
  }, [testType, login, unitUrl, getData]);

  return null;
};

const mapDispatchToProps = {
  getData: getStatisticsByLogin,
};

export default connect(null,
  mapDispatchToProps)(AdminUserStatisticsNetworkService);
