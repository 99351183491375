import { get, post, put, deleteRequest } from '../utils/api/request';
import URLS from '../constants/urls';

const getScopes = token => get(URLS.API_SCOPES, null, token);

// const getScope = (scopeUrl, token) => get(`${URLS.API_SCOPES}/${scopeUrl}`, null, token);

const createScope = (scope, token) => post(URLS.API_SCOPES, scope, token);

const updateScope = (scope, token) => put(URLS.API_SCOPES, scope, token);

const deleteScope = (scopeUrl, token) => deleteRequest(`${URLS.API_SCOPES}/${scopeUrl}`, null, token);

const scopesApi = {
  createScope,
  deleteScope,
  // getScope,
  getScopes,
  updateScope,
};

export default scopesApi;
