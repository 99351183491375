import { get } from '../utils/api/request';
import URLS from '../constants/urls';

const getUnitWords = unitUrl =>
  get(`${URLS.API_UNITS}/${unitUrl}/words`);

const unitsWordsApi = {
  getUnitWords,
};

export default unitsWordsApi;
