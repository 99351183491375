import { createSelector } from 'reselect';
import { routerParamsSelector } from '../router';

const unitsSelector = state => state.units;

const unitsDataSelector = state => unitsSelector(state).data;

const unitsLoadingSelector = state => unitsSelector(state).loading;

const unitsErrorSelector = state => unitsSelector(state).error;

const unitSelector = createSelector(
  [unitsDataSelector, routerParamsSelector],
  (data, params) => {
    if (Array.isArray(data) && params && params.unitUrl) {
      return  data.find(({ url }) => url === params.unitUrl);
    } else {
      return null;
    }
  },
);

const unitNameSelector = createSelector(
  [unitSelector],
  unit => unit ? unit.name : '',
);

const unitUrlSelector = createSelector(
  [unitSelector],
  unit => unit ? unit.url : '',
);

const unitIdSelector = createSelector(
  [unitSelector],
  unit => unit ? unit.id : '',
);

export {
  unitsDataSelector,
  unitsLoadingSelector,
  unitsErrorSelector,
  unitSelector,
  unitIdSelector,
  unitNameSelector,
  unitUrlSelector,
};
