import { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import {
  searchValueSelector,
  usersDataSelector,
  usersLoadingSelector,
  usersErrorSelector,
} from '../../../../redux/selectors';
import TileCard from '../../../TileCard';
import {useAdminUserContext} from './admin-users-context';
import { getUsers } from '../../../../redux/actions';
import URLS from '../../../../constants/urls';
import FormModes from '../../../../constants/form-modes';
import createUserFilter from '../../../../utils/filters/create-user-filter';
import { scrollIntoViewIfNecessary } from '../../../../utils/ui/scroll-into-view';

const listBoxId = 'users-container';

const AdminUsersList = ({
  data,
  getData,
  loading,
  error,
  searchValue,
}) => {
  const navigate = useNavigate();
  const { login: loginParam } = useParams();
  const { mode } = useAdminUserContext();

  const disabled = mode !== FormModes.NONE;

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    scrollIntoViewIfNecessary(loginParam, listBoxId);
  }, [data, loginParam]);

  const handleSelect = useCallback(
    login => () => { navigate(`${URLS.ADMINISTRATION}/users/${login}`) },
    [navigate],
  );

  const items = useMemo(() => {
    return Array.isArray(data)
      ? data
        .filter(createUserFilter(searchValue))
        .map(({ login }) => {
          return (
            <ListItemButton
              id={login}
              key={login}
              selected={login === loginParam}
              onClick={handleSelect(login)}
              disabled={disabled}
            >
              <ListItemText primary={login} />
            </ListItemButton>
          );
        })
      : null;
  }, [data, disabled, handleSelect, loginParam, searchValue]);

  return (
    <TileCard sx={{ height: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '0 1 auto' }}>
        <Typography variant="h6">
          List of users
        </Typography>
      </Grid>
      <CardContent sx={{ flex: '1 1 auto', height: 'calc(100% - 32px)' }}>
        {loading && <div>Loading...</div>}
        {!loading && error ? <div>Error: {error.message}</div> : null}
        {!(loading || error) && data ? (
            <List
              id={listBoxId}
              component="nav"
              aria-label="list of users"
              sx={{
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
            >
              {items}
            </List>
          ) : null
        }
      </CardContent>
    </TileCard>
  );
};

const mapStateToProps = state => ({
  data: usersDataSelector(state),
  loading: usersLoadingSelector(state),
  error: usersErrorSelector(state),
  searchValue: searchValueSelector(state),
});

const mapDispatchToProps = {
  getData: getUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersList);
