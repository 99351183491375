import TestResultsModal from '../shared/TestResultsModal';
import PhrasesContentTestResult from './PhrasesContentTestResult';

const PhrasesTestResultsModal = ({
  open, data, onClose
}) => (
  <TestResultsModal
    open={open}
    data={data}
    onClose={onClose}
    testResult={PhrasesContentTestResult}
  />
);

export default PhrasesTestResultsModal;
