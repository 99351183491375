const createPhrasesFilter = searchValue => ({ enQuestion, ru }) => {
  if (searchValue) {
    const filter = searchValue.toLowerCase();
    const lowerEnQuestion = enQuestion.toLowerCase();
    const lowerRu = ru.toLowerCase();

    return lowerEnQuestion.includes(filter) || lowerRu.includes(filter);
  }

  return true;
};

export default createPhrasesFilter;
