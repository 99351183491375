import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';

import api from '../api';
import reducer from './reducers';

const middleware = [thunk.withExtraArgument(api)];

const configureStore = (initialState = {}) => createStore(
  reducer,
  initialState,
  composeWithDevTools(
    applyMiddleware(...middleware),
  ),
);

export default configureStore;
