import {
  START_UNIT_WORDS_LOADING,
  ERROR_UNIT_WORDS_LOADING,
  SUCCESS_UNIT_WORDS_LOADING,
} from '../../actions/action-types';

const initialState = {
  unitUrl: '',
  loading: false,
  error: null,
  data: null,
};

const unitWordsReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case START_UNIT_WORDS_LOADING:
      return {
        ...state,
        unitUrl: payload,
        loading: true,
        error: null,
      };

    case ERROR_UNIT_WORDS_LOADING:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };

    case SUCCESS_UNIT_WORDS_LOADING:
      return {
        ...state,
        loading: false,
        data: payload,
      };

    default:
      return state;
  }
};

export default unitWordsReducer;
