import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import TileCard from '../../../TileCard';
import {
  endpointAdminPageUrlSelector,
  endpointIdSelector,
  endpointMethodSelector,
  endpointScopesDataSelector,
  endpointUrlSelector,
  scopesDataSelector,
} from '../../../../redux/selectors';
import {
  getScopes as getScopesAction,
  getEndpointScopes as getEndpointScopesAction,
  getEndpoints,
  saveEndpoint,
  openConfirmationModal as openConfirmationModalAction,
  deleteEndpoint,
} from '../../../../redux/actions';
import { useAdminEndpointContext } from './admin-endpoints-context';
import FormModes from '../../../../constants/form-modes';
import URLS from '../../../../constants/urls';
import { isFilledArray } from '../../../../utils/array';
import AdminScopesListControl from '../shared/AdminScopesListControl';

const initialState = {
  adminPageUrl: '',
  method: '',
  url: '',
  endpointScopes: [],
};

const initialValidationData = {
  adminPageUrl: '',
  method: '',
  url: '',
};

const AdminEndpointForm = props => {
  const {
    adminPageUrl,
    deleteItem,
    endpointScopes,
    getEndpointScopes,
    getScopes,
    id,
    method,
    openConfirmationModal,
    refreshEndpoints,
    saveData,
    scopes,
    url,
  } = props;
  const navigate = useNavigate();
  const [state, setState] = useState({ ...initialState });
  const [validationData, setValidationData] = useState({ ...initialValidationData });
  const { endpointUrl } = useParams();
  const {
    mode,
    handleEdit: onEdit,
    handleCancel: onCancel,
  } = useAdminEndpointContext();

  useEffect(() => {
    getScopes();
  }, [getScopes]);

  useEffect(() => {
    if (endpointUrl !== 'new') {
      getEndpointScopes(endpointUrl);
    } else {
      setState({ ...initialState });
    }
  }, [getEndpointScopes, endpointUrl]);

  const disabled = mode === FormModes.NONE;

  const endpointScopesSrc = disabled
    ? endpointScopes
    : state.endpointScopes;

  const selected = useMemo(() => isFilledArray(endpointScopesSrc)
      ? endpointScopesSrc.map(({ value }) => value)
      : [],
    [endpointScopesSrc]);

  const handleEdit = useCallback(() => {
    onEdit();
    setState({ adminPageUrl, method, url, endpointScopes });
  }, [adminPageUrl, method, url, endpointScopes, onEdit]);

  const handleCancel = useCallback(() => {
    onCancel();
    setState({ ...initialState });
    if (endpointUrl === 'new') {
      navigate(`${URLS.ADMINISTRATION}/endpoints`);
    }
  }, [navigate, onCancel, endpointUrl]);

  const handleSave = useCallback(() => {
    const endpoint = {
      adminPageUrl: state.adminPageUrl,
      method: state.method,
      url: state.url,
      scopes: isFilledArray(state.endpointScopes)
        ? state.endpointScopes.map(({ value }) => value)
        : [],
      ...(id && { id }),
    };
    saveData(endpoint, result => {
      if (result && result.valid === false) {
        setValidationData(result.data);
      } else {
        setValidationData({ ...initialValidationData });
        refreshEndpoints();
        getEndpointScopes(state.adminPageUrl);
        onCancel();
        setState({ ...initialState });
        navigate(`${URLS.ADMINISTRATION}/endpoints/${state.adminPageUrl}`);
      }
    });
  }, [
    getEndpointScopes,
    id,
    navigate,
    onCancel,
    refreshEndpoints,
    saveData,
    state,
  ]);

  const handleDelete = useCallback( () => {
    openConfirmationModal({
      title: `Delete endpoint ${adminPageUrl}`,
      message: `Please confirm that you are going to delete endpoint ${adminPageUrl}?`,
      action: () => {
        deleteItem(id, () => {
          refreshEndpoints();
          onCancel();
          navigate(`${URLS.ADMINISTRATION}/endpoints`);
        });
      },
    });
  }, [adminPageUrl, deleteItem, id, navigate, onCancel, openConfirmationModal, refreshEndpoints]);

  const handleChange = useCallback(({ target: { name, value } }) => {
    setState({ ...state, [name]: value });
  }, [setState, state]);

  const handleSubmit = useCallback(e => {
    e.preventDefault();
  }, []);

  const getTextField = useCallback((field, label) => (
    <TextField
      error={!!validationData[field]}
      disabled={disabled}
      fullWidth
      id={field}
      label={label}
      helperText={validationData[field] ? validationData[field] : ''}
      name={field}
      onChange={handleChange}
      variant="standard"
      value={(mode === FormModes.NONE ? props[field] : state[field]) || ''}
    />
  ), [disabled, handleChange, mode, props, state, validationData]);

  const adminPageUrlTextField = useMemo(() => (
    getTextField('adminPageUrl', 'Admin page url')
  ), [getTextField]);

  const methodTextField = useMemo(() => (
    getTextField('method', 'Method')
  ), [getTextField]);

  const urlTextField = useMemo(() => (
    getTextField('url', 'URL')
  ), [getTextField]);

  const handleScopesChange = useCallback(scopeValue => {
    if (state.endpointScopes.findIndex(({ value }) => scopeValue === value) > -1) {
      setState({
        ...state,
        endpointScopes: state.endpointScopes
          .filter(({ value }) => value !== scopeValue),
      });
    } else {
      const scope = scopes.find(({ value }) => value === scopeValue);
      setState({
        ...state,
        endpointScopes: [...state.endpointScopes, scope],
      });
    }
  }, [scopes, state]);

  return (
    <TileCard sx={{ height: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flow: '0 1 auto' }}>
        <Typography variant="h6">
          {adminPageUrl || 'Endpoint'}
        </Typography>
        {mode === FormModes.NONE && (
          <Box>
            <Fab
              aria-label="Edit"
              color="primary"
              size="small"
              onClick={handleEdit}
              disabled={endpointUrl === 'new'}
            >
              <EditIcon />
            </Fab>
          </Box>
        )}
        {mode !== FormModes.NONE && (
          <Box>
            <Fab
              aria-label="Cancel"
              color="default"
              size="small"
              onClick={handleCancel}
              sx={{ mr: 1 }}
            >
              <CancelIcon />
            </Fab>
            <Fab
              aria-label="Delete"
              color="error"
              size="small"
              onClick={handleDelete}
              disabled={endpointUrl === 'new'}
              sx={{ mr: 1 }}
            >
              <RemoveCircleIcon />
            </Fab>
            <Fab
              aria-label="Save"
              color="success"
              size="small"
              onClick={handleSave}
              disabled={false}
            >
              <SaveIcon />
            </Fab>
          </Box>
        )}
      </Grid>
      <CardContent
        sx={{
          flex: '1 1 auto',
          height: 'calc(100% - 36px)',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        <form action="" name="unit" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {adminPageUrlTextField}
            </Grid>
            <Grid item xs={12}>
              {methodTextField}
            </Grid>
            <Grid item xs={12}>
              {urlTextField}
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <AdminScopesListControl
                data={scopes}
                disabled={disabled}
                onChange={handleScopesChange}
                value={selected}
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </TileCard>
  );
};

const mapStateToProps = state => ({
  adminPageUrl: endpointAdminPageUrlSelector(state),
  endpointScopes: endpointScopesDataSelector(state),
  id: endpointIdSelector(state),
  method: endpointMethodSelector(state),
  scopes: scopesDataSelector(state),
  url: endpointUrlSelector(state),
});

const mapDispatchToProps = {
  openConfirmationModal: openConfirmationModalAction,
  deleteItem: deleteEndpoint,
  getEndpointScopes: getEndpointScopesAction,
  getScopes: getScopesAction,
  refreshEndpoints: getEndpoints,
  saveData: saveEndpoint,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEndpointForm);
