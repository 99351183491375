import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getUnitWords } from '../../redux/actions';
import Topic from '../Topic';
import { isFilledArray } from '../../utils/array';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import {
  searchValueSelector,
  unitWordsDataSelector,
  unitWordsErrorSelector,
  unitWordsLoadingSelector
} from '../../redux/selectors';
import createWordFilter from '../../utils/filters/create-word-filter';

const UnitWords = ({
  loading,
  error,
  data,
  getData,
  searchValue,
}) => {
  const { unitUrl } = useParams();

  useEffect(() => {
    getData(unitUrl);
  }, [getData, unitUrl]);

  const sortedData = useMemo(() => isFilledArray(data)
    ? data
        .filter(createWordFilter(searchValue))
        .sort((i1, i2) =>
          i1.en.toLowerCase().localeCompare(i2.en.toLowerCase()))
    : null,
    [data, searchValue]);

  const words = useMemo(() => {
    return isFilledArray(sortedData) ? (
      <TableContainer component={Paper} sx={{ overflowY: 'auto', height: '100%' }}>
        <Table sx={{ width: '100%' }} aria-label="unit words">
          <TableHead>
            <TableRow sx={{ borderBottom: 'solid 2px rgba(0, 0, 0, 0.5)' }}>
              <TableCell>Word</TableCell>
              <TableCell align="right">Transcription</TableCell>
              <TableCell align="right">Translation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map(({ id, en, ru, transcription }) => (
              <TableRow
                key={id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '&:hover': {
                    background: '#eceff1',
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="body1">
                    {en}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body1">
                    {transcription}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body1">
                    {ru}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : null;
  }, [sortedData]);

  return (
    <Topic
      header="Vocabulary"
      sx={{
        height: '100%',
        display: 'flex',
        flexFlow: 'column nowrap',
      }}
    >
      {error && !loading && <div>Error: {error.message}</div>}
      {loading && <div>Loading...</div>}
      {words && !loading && words}
    </Topic>
  );
};

const mapStateToProps = state => ({
  loading: unitWordsLoadingSelector(state),
  error: unitWordsErrorSelector(state),
  data: unitWordsDataSelector(state),
  searchValue: searchValueSelector(state),
});

const mapDispatchToProps = {
  getData: getUnitWords,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitWords);
