const ADMINISTRATION = '/administration';
const HOME = '/';
const LOGIN = '/login';
const PERSONAL_ACCOUNT = '/personal-account';
const REGISTER = '/register';
const STATISTICS = '/statistics';
const UNITS = '/units';

const API = '/english-api/api/v1';
const API_RESULTS = `${API}/results`

const URLS = {
  API_USERS: `${API}/users`,
  API_UNITS: `${API}/units`,
  API_WORDS: `${API}/words`,
  API_AUTH: `${API}/auth`,
  API_PHRASES: `${API}/phrases`,
  API_RESULTS_WORD_WRITING: `${API_RESULTS}/words-writing`,
  API_RESULTS_PHRASES: `${API_RESULTS}/phrases`,
  API_ENDPOINTS: `${API}/endpoints`,
  API_SCOPES: `${API}/scopes`,
  ADMINISTRATION,
  HOME,
  LOGIN,
  PERSONAL_ACCOUNT,
  REGISTER,
  STATISTICS,
  UNITS,
};

export default URLS;
