import { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import {
  searchValueSelector,
  wordsDataSelector,
  wordsErrorSelector,
  wordsLoadingSelector,
} from '../../../../redux/selectors';
import TileCard from '../../../TileCard';
import { useAdminWordsContext } from './admin-words-context';
import { getWords } from '../../../../redux/actions';
import URLS from '../../../../constants/urls';
import FormModes from '../../../../constants/form-modes';
import createWordFilter from '../../../../utils/filters/create-word-filter';
import { scrollIntoViewIfNecessary } from '../../../../utils/ui/scroll-into-view';

const wordsContainerId = 'words-container';

const AdminWordsList = ({
  data,
  getData,
  loading,
  error,
  searchValue,
}) => {
  const navigate = useNavigate();
  const { wordUrl } = useParams();
  const { mode } = useAdminWordsContext();

  const disabled = (mode !== FormModes.NONE) || (wordUrl === 'new');

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    scrollIntoViewIfNecessary(wordUrl, wordsContainerId)
  }, [wordUrl, data]);

  const handleSelect = useCallback(
    url => () => { navigate(`${URLS.ADMINISTRATION}/words/${url}`) },
    [navigate],
  );

  const items = useMemo(() => {
    return Array.isArray(data)
      ? data
        .filter(createWordFilter(searchValue))
        .sort((w1, w2) => {
          return w1.en.localeCompare(w2.en);
        })
        .map(({ id, en, url }) => {
          return (
            <ListItemButton
              key={id}
              id={url}
              selected={wordUrl === url}
              onClick={handleSelect(url)}
              disabled={disabled}
            >
              <ListItemText primary={en} />
            </ListItemButton>
          );
        })
      : null;
  }, [data, disabled, handleSelect, searchValue, wordUrl]);

  const dataExists = !(loading || error) && !!data;
  const dataExistsAndReadyToDisplay = dataExists && data.length > 0;
  const dataExistsAndEmpty = dataExists && data.length === 0;

  return (
    <TileCard sx={{ height: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '0 1 auto' }}>
        <Typography variant="h6">
          List of words
        </Typography>
        <Fab
          aria-label="Add word"
          color="primary"
          size="small"
          onClick={handleSelect('new')}
          disabled={disabled}
        >
          <AddIcon />
        </Fab>
      </Grid>
      <CardContent sx={{ flex: '1 1 auto', height: 'calc(100% - 32px)' }}>
        {loading && <div>Loading...</div>}
        {!loading && error ? <div>Error: {error.message}</div> : null}
        {dataExistsAndReadyToDisplay ? (
            <List
              id={wordsContainerId}
              component="nav"
              aria-label="words list"
              sx={{
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
            >
              {items}
            </List>
          ) : null
        }
        {dataExistsAndEmpty && (
          <div>No data to display</div>
        )}
      </CardContent>
    </TileCard>
  );
};

const mapStateToProps = state => ({
  data: wordsDataSelector(state),
  loading: wordsLoadingSelector(state),
  error: wordsErrorSelector(state),
  searchValue: searchValueSelector(state),
});

const mapDispatchToProps = {
  getData: getWords,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminWordsList);
