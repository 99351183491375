import { deleteRequest, get, post, put } from '../utils/api/request';
import URLS from '../constants/urls';

const getWords = () => get(URLS.API_WORDS);

const createWord = (word, token) => post(URLS.API_WORDS, word, token);

const updateWord = (word, token) => put(URLS.API_WORDS, word, token);

const deleteWord = (wordUrl, token) => deleteRequest(`${URLS.API_WORDS}/${wordUrl}`, null, token);

const wordsApi = {
  getWords,
  createWord,
  updateWord,
  deleteWord,
};

export default wordsApi;
