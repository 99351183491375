import {
  START_AUTH_REQUEST,
  ERROR_LOGIN,
  SUCCESS_LOGIN,
  SUCCESS_LOGOUT,
  ERROR_LOGOUT,
  COMPLETE_AUTH_CHECK,
  SUCCESS_PERSONAL_ACCOUNT_REQUEST,
  START_PERSONAL_ACCOUNT_REQUEST,
  ERROR_PERSONAL_ACCOUNT_REQUEST,
  START_CHANGE_PASSWORD_REQUEST,
  ERROR_CHANGE_PASSWORD_REQUEST,
  SUCCESS_CHANGE_PASSWORD_REQUEST,
} from '../action-types';
import { authTokenSelector } from '../../selectors';
import { addMessage } from '../messages';
import { createErrorMessage, createInfoMessage, createSuccessMessage } from '../../../utils/messages/messages-utils';

const startAuthRequest = () => ({
  type: START_AUTH_REQUEST
});

const errorLogin = error => ({
  type: ERROR_LOGIN,
  payload: error,
});

const successLogin = data => ({
  type: SUCCESS_LOGIN,
  payload: data,
});

const errorLogout = error => ({
  type: ERROR_LOGOUT,
  payload: error,
});

const successLogout = () => ({
  type: SUCCESS_LOGOUT,
});

const completeAuthCheck = () => ({
  type: COMPLETE_AUTH_CHECK,
});

const startPersonalAccountRequest = () => ({
  type: START_PERSONAL_ACCOUNT_REQUEST,
});

const errorPersonalAccountRequest = error => ({
  type: ERROR_PERSONAL_ACCOUNT_REQUEST,
  payload: error,
});

const successPersonalAccountRequest = data => ({
  type: SUCCESS_PERSONAL_ACCOUNT_REQUEST,
  payload: data
});

const startChangePasswordRequest = () => ({
  type: START_CHANGE_PASSWORD_REQUEST,
});

const errorChangePasswordRequest = error => ({
  type: ERROR_CHANGE_PASSWORD_REQUEST,
  payload: error,
});

const successChangePasswordRequest = () => ({
  type: SUCCESS_CHANGE_PASSWORD_REQUEST,
});

const login = (data, cbSuccess = () => {}) => async (dispatch, getState, api) => {
  dispatch(startAuthRequest());

  try {
    const responseData = await api.auth.login(data);
    dispatch(successLogin(responseData));
    dispatch(addMessage(createSuccessMessage('You are logged in successfully')));
    cbSuccess(responseData);
  } catch (e) {
    dispatch(errorLogin(e));
    dispatch(addMessage(createErrorMessage('Login failed')));
  }
};

const register = (data, cbSuccess = () => {}) => async (dispatch, getState, api) => {
  dispatch(startAuthRequest());

  try {
    const responseData = await api.auth.register(data);
    dispatch(successLogin(responseData));
    dispatch(addMessage(createSuccessMessage('You are registered successfully')));
    cbSuccess(responseData);
  } catch (e) {
    dispatch(errorLogin(e));
    dispatch(addMessage(createErrorMessage('Registration failed')));
  }
};

const logout = (cbSuccess = () => {}) => async (dispatch, getState, api) => {
  dispatch(startAuthRequest());
  try {
    const token = authTokenSelector(getState());
    await api.auth.logout(token);
    dispatch(successLogout());
    dispatch(addMessage(createInfoMessage('You are logged out')));
    cbSuccess();
  } catch (e) {
    console.error(e);
    dispatch(errorLogout(e));
    dispatch(addMessage(createErrorMessage('Error during logout')));
  }
};

const checkAuth = () => async (dispatch, getState, api) => {
  const token = authTokenSelector(getState());
  try {
    if (token) {
      dispatch(startAuthRequest());
      await api.auth.check(token);
      dispatch(completeAuthCheck());
      dispatch(addMessage(createSuccessMessage('You are still logged in')));
    } else {
      dispatch(addMessage(createInfoMessage('You are not logged in')));
    }
  } catch (e) {
    dispatch(logout());
    dispatch(addMessage(createInfoMessage('You are not logged in')));
  }
};

const getPersonalAccount = (cbSuccess = () => {}) => async (dispatch, getState, api) => {
  const token = authTokenSelector(getState());
  dispatch(startPersonalAccountRequest());
  try {
    const data = await api.auth.getPersonalAccount(token);
    dispatch(successPersonalAccountRequest(data));
    cbSuccess(data);
  } catch(e) {
    errorPersonalAccountRequest(e);
    dispatch(addMessage(createErrorMessage('Error getting personal account')));
  }
};

const savePersonalAccount = (data, cbSuccess = () => {}) => async (dispatch, getState, api) => {
  const state = getState();
  const token = authTokenSelector(state);
  dispatch(startPersonalAccountRequest());
  try {
    const result = await api.auth.updatePersonalAccount(data, token);
    if (!(result && (result.valid === false))) {
      dispatch(successPersonalAccountRequest(data));
      dispatch(addMessage(createSuccessMessage('Personal account updated successfully')));
    } else {
      dispatch(successPersonalAccountRequest({}));
    }
    cbSuccess(result);
  } catch (e) {
    dispatch(errorPersonalAccountRequest(e));
    dispatch(addMessage(createInfoMessage('Error updating personal account')));
  }
};

const updatePassword = (data, cbSuccess = () => {}) => async (dispatch, getState, api) => {
  const state = getState();
  const token = authTokenSelector(state);
  dispatch(startChangePasswordRequest());
  try {
    const result = await api.auth.updatePassword(data, token);
    if (!(result && result.valid === false)) {
      dispatch(addMessage(createSuccessMessage('Password updated successfully')));
    }
    dispatch(successChangePasswordRequest());

    cbSuccess(result);
  } catch (e) {
    dispatch(errorChangePasswordRequest(e));
    dispatch(addMessage(createErrorMessage('Error updating password')));
  }
};

export {
  checkAuth,
  getPersonalAccount,
  login,
  logout,
  register,
  savePersonalAccount,
  updatePassword,
};
