import {
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  OPEN_CONFIRMATION_MODAL,
  CLOSE_CONFIRMATION_MODAL,
} from '../action-types';

const openSidebar = () => ({
  type: OPEN_SIDEBAR
});

const closeSidebar = () => ({
  type: CLOSE_SIDEBAR
});

const openConfirmationModal = ({ title, message, action }) => ({
  type: OPEN_CONFIRMATION_MODAL,
  payload: {
    action,
    title,
    message,
  },
});

const closeConfirmationModal = () => ({
  type: CLOSE_CONFIRMATION_MODAL
});

export {
  closeSidebar,
  openSidebar,
  openConfirmationModal,
  closeConfirmationModal,
};
