import { useCallback, useEffect, useMemo} from 'react';
import { connect } from 'react-redux';
import { Outlet, useNavigate, useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { getUnits } from '../../../redux/actions';
import URLS from '../../../constants/urls'
import {
  unitsDataSelector,
  unitsErrorSelector,
  unitsLoadingSelector,
} from '../../../redux/selectors';
import ConfirmationModal from '../Administration/shared/ConfirmationModal';

const sideNavMaxWidth = 200;

const Units = ({
  data,
  error,
  getData,
  loading,
}) => {
  const navigate = useNavigate();
  const { unitUrl } = useParams();

  useEffect(() => {
    if (!data) {
      getData();
    }
  }, [getData, data]);

  const handleListItemClick = useCallback(
    (event, itemUrl) => {
        navigate(`${URLS.UNITS}/${itemUrl}`);
      },
    [navigate]);

  const items = useMemo(() => {
    return Array.isArray(data) ? data.map(({ id, name, url }) => {
      return (
        <ListItemButton
          key={id}
          selected={unitUrl === url}
          onClick={event => handleListItemClick(event, url)}
        >
          <ListItemText primary={name} />
        </ListItemButton>
      );
    }) : null;
  }, [data, handleListItemClick, unitUrl]);

  return (
    <>
      <ConfirmationModal />
      <Box
        sx={{
          width: '100%',
          maxWidth: sideNavMaxWidth,
          bgcolor: 'background.paper',
          height: '100%'
        }}
      >
        {loading && <div>Loading...</div>}
        {!(loading || error) && (
          <>
            <List
              component="nav"
              aria-label="main mailbox folders"
              sx={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}
            >
              {items}
            </List>
          </>
        )}
      </Box>
      <Box sx={{ height: '100%', ml: '1rem', width: `calc(100% - ${sideNavMaxWidth}px - 1rem)` }}>
        <Outlet />
      </Box>
    </>
  );
};

const mapStateToProps = state => ({
  loading: unitsLoadingSelector(state),
  error: unitsErrorSelector(state),
  data: unitsDataSelector(state),
});

const mapDispatchToProps = {
  getData: getUnits,
};

export default connect(mapStateToProps, mapDispatchToProps)(Units);
