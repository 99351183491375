import {
  START_UNIT_PHRASES_LOADING,
  ERROR_UNIT_PHRASES_LOADING,
  SUCCESS_UNIT_PHRASES_LOADING,
  START_UNIT_PHRASES_SAVING,
  ERROR_UNIT_PHRASES_SAVING,
  SUCCESS_UNIT_PHRASES_SAVING,
} from '../action-types';
import { addMessage } from '../messages';
import { createErrorMessage, createInfoMessage, createSuccessMessage } from '../../../utils/messages/messages-utils';
import { authTokenSelector, unitIdSelector } from '../../selectors';
import { logout } from '../auth';

const startUnitPhrasesLoading = unitUrl => ({
  type: START_UNIT_PHRASES_LOADING,
  payload: unitUrl,
});

const errorUnitPhrasesLoading = error => ({
  type: ERROR_UNIT_PHRASES_LOADING,
  payload: error,
});

const successUnitPhrasesLoading = data => ({
  type: SUCCESS_UNIT_PHRASES_LOADING,
  payload: data,
});

const getUnitPhrases = (unitUrl, cbSuccess) => async (dispatch, getState, api) => {
  dispatch(startUnitPhrasesLoading(unitUrl));
  try {
    const data = await api.unitPhrases.getUnitPhrases(unitUrl);
    dispatch(successUnitPhrasesLoading(data));
    if (cbSuccess) cbSuccess(data);
  } catch (error) {
    dispatch(errorUnitPhrasesLoading(error));
    dispatch(addMessage(createErrorMessage(`Can not fetch phrases for unit: ${unitUrl}`)));
    if (error.statusCode === 401) {
      await logout()(dispatch, getState, api);
    }
  }
};

const startUnitPhrasesSaving = () => ({
  type: START_UNIT_PHRASES_SAVING
});

const errorUnitPhrasesSaving = error => ({
  type: ERROR_UNIT_PHRASES_SAVING,
  payload: error,
});

const successUnitPhrasesSaving = () => ({
  type: SUCCESS_UNIT_PHRASES_SAVING,
});

const saveUnitPhrasesResults = (data, cbSuccess) => async (dispatch, getState, api) => {
  const state = getState();
  const token = authTokenSelector(state);
  const unitId = unitIdSelector(state);
  dispatch(startUnitPhrasesSaving());
  try {
    const response = await api.results.savePhrasesResults({ ...data, unitId }, token);
    dispatch(successUnitPhrasesSaving());
    dispatch(addMessage(createSuccessMessage('Answers are saved successfully')));
    if (cbSuccess) cbSuccess(response);
  } catch (error) {
    dispatch(addMessage(createErrorMessage('Could not save phrases test result')));
    dispatch(errorUnitPhrasesSaving(error));
    if (error.statusCode === 401) {
      await logout()(dispatch, getState, api);
    }
  }
};

const showNotLoggedMessage = () =>
  addMessage(createInfoMessage('You are not logged in account. Result was not saved'));

export {
  getUnitPhrases,
  saveUnitPhrasesResults,
  showNotLoggedMessage,
};
