import { Route, Routes } from 'react-router-dom';

import Administration from '../components/pages/Administration';
import AdminEndpointForm from '../components/pages/Administration/AdminEndpoints/AdminEndpointForm';
import AdminEndpoints from '../components/pages/Administration/AdminEndpoints';
import AdminHome from '../components/pages/Administration/AdminHome';
import AdminPhrases from '../components/pages/Administration/AdminPhrases';
import AdminPhraseForm from '../components/pages/Administration/AdminPhrases/AdminPhraseForm';
import AdminScopes from '../components/pages/Administration/AdminScopes';
import AdminScopesForm from '../components/pages/Administration/AdminScopes/AdminScopeForm';
import AdminUserTestTypeStatistics
  from '../components/pages/Administration/AdminUsers/AdminUserStatistics/AdminUserTestTypeStatistics';
import AdminUnitForm from '../components/pages/Administration/AdminUnits/AdminUnitForm';
import AdminUnits from '../components/pages/Administration/AdminUnits';
import AdminUserForm from '../components/pages/Administration/AdminUsers/AdminUserForm';
import AdminUsers from '../components/pages/Administration/AdminUsers';
import AdminUserStatisticsHome
  from '../components/pages/Administration/AdminUsers/AdminUserStatistics/AdminUserStatisticsHome';
import AdminUserUnitsStatistics
  from '../components/pages/Administration/AdminUsers/AdminUserStatistics/AdminUserUnitsStatistics';
import AdminWordForm from '../components/pages/Administration/AdminWords/AdminWordForm';
import AdminWords from '../components/pages/Administration/AdminWords';
import AuthRequired from '../components/AuthRequired';
import CheckAuthService from '../components/services/CheckAuthService';
import Grammar from '../components/Grammar';
import Header from '../components/Header';
import HomePage from '../components/pages/HomePage';
import LoginRegistrationPage from '../components/pages/LoginRegistration';
import MessagesService from '../components/services/MessagesService';
import Page from '../components/Page';
import PersonalAccount from '../components/pages/PersonalAccount';
import Phrases from '../components/tests/Phrases';
import PhrasesContentHome from '../components/tests/Phrases/PhrasesContentHome';
import PhrasesContentTest from '../components/tests/Phrases/PhrasesContentTest';
import PhrasesContentStatistics from '../components/tests/Phrases/Statistics/Statistics';
import RouterService from '../components/services/RouterService';
import Sidebar from '../components/Sidebar';
import Tests from '../components/pages/Units/tests/Tests';
import TestTypes from '../components/pages/Units/tests/TestTypes';
import StatisticsIndex from '../components/pages/Statistics';
import StatisticsHome from '../components/pages/Statistics/StatisticsHome';
import StatisticsType from '../components/pages/Statistics/StatisticsType';
import StatisticsContent from '../components/pages/Statistics/StatisticsContent';
import Unit from '../components/Unit';
import Units from '../components/pages/Units';
import UnitWords from '../components/UnitWords';
import WordsWriting from '../components/tests/WordsWriting';
import WordsWritingContentHome
  from '../components/tests/WordsWriting/WordsWritingContentHome';
import WordsWritingContentTest
  from '../components/tests/WordsWriting/WordsWritingContentTest';
import WordsWritingContentStatistics
  from '../components/tests/WordsWriting/Statistics/Statistics';
import URLS from '../constants/urls';

const ApplicationContent = () => {
  return (
    <>
      <CheckAuthService />
      <MessagesService />
      <Header />
      <Sidebar />
      <Page id="page">
        <Routes>
          <Route path="/" element={<RouterService />}>
            <Route path="/" exact={true} element={<HomePage />} />
            <Route path={URLS.ADMINISTRATION} element={<AuthRequired><Administration /></AuthRequired>}>
              <Route index element={<AdminHome />} />
              <Route path="units" element={<AdminUnits />}>
                <Route path=":unitUrl" element={<AdminUnitForm />} />
              </Route>
              <Route path="words" element={<AdminWords />}>
                <Route path=":wordUrl" element={<AdminWordForm />} />
              </Route>
              <Route path="phrases" element={<AdminPhrases />}>
                <Route path=":phraseUrl" element={<AdminPhraseForm />} />
              </Route>
              <Route path="scopes" element={<AdminScopes />}>
                <Route path=":scopeUrl" element={<AdminScopesForm />} />
              </Route>
              <Route path="endpoints" element={<AdminEndpoints />}>
                <Route path=":endpointUrl" element={<AdminEndpointForm />} />
              </Route>
              <Route path="users" element={<AdminUsers />}>
                <Route path=":login">
                  <Route index element={<AdminUserForm />} />
                  <Route path="statistics" element={<AdminUserStatisticsHome />}>
                    <Route path=":testType">
                      <Route index element={<AdminUserUnitsStatistics />} />
                      <Route path=":unitUrl" element={<AdminUserTestTypeStatistics />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path={URLS.LOGIN} exact element={<LoginRegistrationPage />} />
            <Route path={URLS.PERSONAL_ACCOUNT} exact element={<PersonalAccount />} />
            <Route path={URLS.REGISTER} exact element={<LoginRegistrationPage />} />
            <Route path={URLS.STATISTICS} element={<StatisticsIndex />}>
              <Route index element={<StatisticsHome />} />
              <Route path=":testType" element={<StatisticsType />}>
                <Route path=":unitUrl" element={<StatisticsContent />} />
              </Route>
            </Route>
            <Route path={URLS.UNITS} element={<Units />}>
              <Route path=":unitUrl">
                <Route index element={<Unit />} />
                <Route path="vocabulary" element={<UnitWords />} />
                <Route path="grammar" element={<Grammar />} />
                <Route path="tests" element={<Tests />}>
                  <Route index element={<TestTypes />} />
                  <Route path="words-writing" element={<WordsWriting />}>
                    <Route index element={<WordsWritingContentHome />}/>
                    <Route path="in-progress" element={<WordsWritingContentTest />}/>
                    <Route path="statistics" element={<WordsWritingContentStatistics />}/>
                  </Route>
                  <Route path="phrases" element={<Phrases />}>
                    <Route index element={<PhrasesContentHome />} />
                    <Route path="in-progress" element={<PhrasesContentTest />} />
                    <Route path="statistics" element={<PhrasesContentStatistics />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<div>Error: 404 Resource was not found</div>} />
          </Route>
        </Routes>
      </Page>
    </>
  );
};

export default ApplicationContent;
