const createUserFilter = searchValue => ({ email, login }) => {
  if (searchValue) {
    const filter = searchValue.toLowerCase();
    const lowerLogin = login.toLowerCase();
    const lowerEmail = email.toLowerCase();

    return lowerLogin.includes(filter)
      || lowerEmail.includes(filter);
  }

  return true;
};

export default createUserFilter;
