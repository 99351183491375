import { deleteRequest, get, post, put } from '../utils/api/request';
import URLS from '../constants/urls';

const getPhrases = () => get(URLS.API_PHRASES);

const createPhrase = (phrase, token) => post(URLS.API_PHRASES, phrase, token);

const updatePhrase = (phrase, token) => put(URLS.API_PHRASES, phrase, token);

const deletePhrase = (phraseUrl, token) => deleteRequest(`${URLS.API_PHRASES}/${phraseUrl}`, null, token);

const phrasesApi = {
  getPhrases,
  createPhrase,
  updatePhrase,
  deletePhrase,
};

export default phrasesApi;