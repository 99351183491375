import { createSelector } from 'reselect';
import { routerParamsSelector } from '../router';

const scopesSelector = state => state.scopes;

const scopesDataSelector = state => scopesSelector(state).data;

const scopesLoadingSelector = state => scopesSelector(state).loading;

const scopesErrorSelector = state => scopesSelector(state).error;

const scopeSelector = createSelector(
  [scopesDataSelector, routerParamsSelector],
  (data, params) => {
    if (Array.isArray(data) && params && params.scopeUrl) {
      return  data.find(({ value }) => value === params.scopeUrl);
    } else {
      return null;
    }
  },
);

const scopeNameSelector = createSelector(
  [scopeSelector],
  scope => scope ? scope.name : '',
);

const scopeValueSelector = createSelector(
  [scopeSelector],
  scope => scope ? scope.value : '',
);

const scopeIdSelector = createSelector(
  [scopeSelector],
  scope => scope ? scope.id : '',
);

const scopeDescriptionSelector = createSelector(
  [scopeSelector],
  scope => scope ? scope.description : '',
);

export {
  scopesDataSelector,
  scopesLoadingSelector,
  scopesErrorSelector,
  scopeSelector,
  scopeIdSelector,
  scopeNameSelector,
  scopeValueSelector,
  scopeDescriptionSelector,
};
