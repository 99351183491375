import { createSelector } from 'reselect';

const authSelector = state => state.auth;

const authEmailSelector = state => authSelector(state).email;

const authLoginSelector = state => authSelector(state).login;

const authTokenSelector = state => authSelector(state).token;

const authErrorSelector = state => authSelector(state).error;

const authErrorPersonalAccountSelector = state => authSelector(state).errorPersonalAccount;

const authRequestInProgressSelector = state => authSelector(state).requestInProgress;

const authLoggedSelector = createSelector(
  [
    authLoginSelector,
    authTokenSelector,
    authErrorSelector,
  ],
  (login, token, error) => (
    !error && !!(login && token)
  ),
);

export {
  authEmailSelector,
  authErrorPersonalAccountSelector,
  authErrorSelector,
  authLoggedSelector,
  authRequestInProgressSelector,
  authLoginSelector,
  authTokenSelector,
};
