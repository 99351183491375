import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getStatistics } from '../../../redux/actions';
import { useEffect } from 'react';

const StatisticsNetworkService = ({ getData }) => {
  const { unitUrl, testType } = useParams();

  useEffect(() => {
    if (testType && unitUrl) {
      getData(testType, unitUrl);
    }
  }, [getData, testType, unitUrl]);

  return null;
};

const mapDispatchToProps = {
  getData: getStatistics,
};

export default connect(null, mapDispatchToProps)(StatisticsNetworkService);
