import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import './styles.css';

const HomePage = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Tooltip title="Практика приводит к совершенству">
        <Typography variant="h3" align="center" color="#696969" sx={{ mt: 2 }}>
          "Practice makes perfect"
        </Typography>
      </Tooltip>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 3,
        }}
      >
        <img
          src="assets/images/london-background.jpg"
          alt="London"
        />
      </Box>
    </Box>
  );
};

export default HomePage;
