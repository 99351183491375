import { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';

import TileCard from '../../../TileCard';
import {
  authLoginSelector,
  searchValueSelector,
  unitsDataSelector, unitsErrorSelector,
  unitsLoadingSelector,
} from '../../../../redux/selectors';
import { getUnits } from '../../../../redux/actions';
import URLS from '../../../../constants/urls';
import createUnitFilter from '../../../../utils/filters/create-unit-filter';
import getStatisticsResultsAverage from '../../../../utils/statistics/get-statistics-results-average';
import { ONE_WEEK_MS } from '../../../../constants/time-constants';

const listItemsContainerId = 'units-container';

const SpanBadge = styled('span')(({ color = 'green' }) => ({
  fontSize: '0.75rem',
  display: 'inline-block',
  borderRadius: '8px / 50%',
  border: `1px solid ${color}`,
  padding: '0.2rem 0.5rem',
  backgroundColor: color,
  color: 'white',
}));

const StatisticsUnitsList = ({
  data,
  loading,
  error,
  login,
  searchValue,
  getData,
}) => {
  const navigate = useNavigate();
  const { testType, unitUrl } = useParams();

  useEffect(() => {
    if (testType && login) {
      getData(null, login, testType);
    } else {
      getData();
    }
  }, [testType, login, getData]);

  const handleSelect = useCallback(
    url => () => { navigate(`${URLS.STATISTICS}/${testType}/${url}`) },
    [navigate, testType],
  );

  const items = useMemo(() => {
    return Array.isArray(data)
      ? data
        .filter(createUnitFilter(searchValue))
        .sort((u1, u2) => {
          return u1.name.localeCompare(u2.name);
        })
        .map(({ id, name, url, results = [] }) => {
          const average = getStatisticsResultsAverage(results);
          const count = results.length;
          const lastTs = count > 0 ? results[count - 1].createdTs : 0;
          const now = new Date().getTime();
          const duringTheWeek = (now - lastTs) <= ONE_WEEK_MS;
          let color;
          switch (true) {
            case duringTheWeek && average > 90:
              color = "green";
              break;
            case duringTheWeek && average > 80:
              color = "violet";
              break;
            case !duringTheWeek && average > 75:
              color = "orange";
              break;
            default:
              color = "red";
          }

          return (
            <ListItemButton
              key={id}
              id={url}
              selected={unitUrl === url}
              onClick={handleSelect(url)}
            >
              <ListItemText primary={name} />
              <ListItemIcon>
                {count > 0 && (
                  <SpanBadge color={color}>{average}%</SpanBadge>
                )}
              </ListItemIcon>
            </ListItemButton>
          );
        })
      : null;
  }, [data, handleSelect, searchValue, unitUrl]);

  const dataExists = !(loading || error) && !!data;
  const dataExistsAndReadyToDisplay = dataExists && data.length > 0;
  const dataExistsAndEmpty = dataExists && data.length === 0;

  return (
    <TileCard sx={{ height: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '0 1 auto' }}>
        <Typography variant="h6">
          Units
        </Typography>
      </Grid>
      <CardContent sx={{ flex: '1 1 auto', height: 'calc(100% - 32px)' }}>
        {loading && <div>Loading...</div>}
        {!loading && error ? <div>Error: {error.message}</div> : null}
        {dataExistsAndReadyToDisplay ? (
          <List
            id={listItemsContainerId}
            component="nav"
            aria-label="units list"
            sx={{
              height: '100%',
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            {items}
          </List>
        ) : null
        }
        {dataExistsAndEmpty && (
          <div>No data to display</div>
        )}
      </CardContent>
    </TileCard>
  );
};

const mapStateToProps = state => ({
  data: unitsDataSelector(state),
  loading: unitsLoadingSelector(state),
  error: unitsErrorSelector(state),
  login: authLoginSelector(state),
  searchValue: searchValueSelector(state),
});

const mapDispatchToProps = {
  getData: getUnits,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsUnitsList);
