import { connect } from 'react-redux';
import Statistics from '.';
import {
  statisticsDataSelector,
  statisticsErrorSelector,
  statisticsRequestInProgressSelector
} from '../../../../redux/selectors/statistics';
import { unitNameSelector } from '../../../../redux/selectors';

const mapStateToProps = state => ({
  data: statisticsDataSelector(state),
  error: statisticsErrorSelector(state),
  requestInProgress: statisticsRequestInProgressSelector(state),
  unitName: unitNameSelector(state),
});

export default connect(mapStateToProps)(Statistics);
