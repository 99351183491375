import {
  START_UNIT_WORDS_LOADING,
  ERROR_UNIT_WORDS_LOADING,
  SUCCESS_UNIT_WORDS_LOADING,
  START_WORDS_WRITING_RESULT_SAVING,
  ERROR_WORDS_WRITING_RESULT_SAVING,
  SUCCESS_WORDS_WRITING_RESULT_SAVING,
} from '../action-types';
import { addMessage } from '../messages';
import { createErrorMessage, createSuccessMessage } from '../../../utils/messages/messages-utils';
import { authTokenSelector, unitIdSelector } from '../../selectors';
import { logout } from '../auth';

const startUnitWordsLoading = unitUrl => ({
  type: START_UNIT_WORDS_LOADING,
  payload: unitUrl,
});

const errorUnitWordsLoading = error => ({
  type: ERROR_UNIT_WORDS_LOADING,
  payload: error,
});

const successUnitWordsLoading = data => ({
  type: SUCCESS_UNIT_WORDS_LOADING,
  payload: data,
});

const getUnitWords = (unitUrl, cbSuccess) =>
  async (dispatch, getState, api) => {
    dispatch(startUnitWordsLoading(unitUrl));

    try {
      const data = await api.unitWords.getUnitWords(unitUrl);
      dispatch(successUnitWordsLoading(data));
      if (cbSuccess) cbSuccess(data);
    } catch (error) {
      dispatch(errorUnitWordsLoading(error));
      dispatch(addMessage(createErrorMessage(`Can not fetch words for unit: ${unitUrl}`)));
      if (error.statusCode === 401) {
        await logout()(dispatch, getState, api);
      }
    }
  };

const startWordsWritingResultSaving = () => ({
  type: START_WORDS_WRITING_RESULT_SAVING,
});

const errorWordsWritingResultSaving = error => ({
  type: ERROR_WORDS_WRITING_RESULT_SAVING,
  payload: error,
});

const successWordsWritingResultSaving = data => ({
  type: SUCCESS_WORDS_WRITING_RESULT_SAVING,
  payload: data,
});

const saveUnitWordsWritingResults = (data, cbSuccess) => async (dispatch, getState, api) => {
  const state = getState();
  const token = authTokenSelector(state);
  const unitId = unitIdSelector(state);
  dispatch(startWordsWritingResultSaving());
  try {
    const response = await api.results.saveWordsWritingResults({ ...data, unitId }, token);
    dispatch(successWordsWritingResultSaving());
    dispatch(addMessage(createSuccessMessage('Answers are saved successfully')));
    if (cbSuccess) cbSuccess(response);
  } catch (error) {
    dispatch(addMessage(
      createErrorMessage('Could not save words writing test result')));
    dispatch(errorWordsWritingResultSaving(error));
    if (error.statusCode === 401) {
      await logout()(dispatch, getState, api);
    }
  }
};

export {
  getUnitWords,
  saveUnitWordsWritingResults,
};
