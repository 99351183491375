import { Outlet } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import AdminUnitsList from './AdminUnitsList';
import { AdminUnitContextProvider } from './admin-units-context';

const AdminUnits = () => {
  return (
    <AdminUnitContextProvider>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={5} sx={{ height: '100%' }}>
          <AdminUnitsList />
        </Grid>
        <Grid item xs={7} sx={{ height: '100%' }}>
          <Outlet />
        </Grid>
      </Grid>
    </AdminUnitContextProvider>
  );
};

export default AdminUnits;
