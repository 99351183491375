import { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate} from 'react-router-dom';
import Fab from '@mui/material/Fab';

import Topic from '../Topic';
import TOPICS from '../../constants/topics';
import sortByIndex from '../../utils/sort-by-index';
import URLS from '../../constants/urls';
import { unitSelector } from '../../redux/selectors';

const TOPICS_LIST = sortByIndex(TOPICS);

const Unit = ({ unit }) => {
  const navigate = useNavigate();
  const { name, url: unitUrl } = unit;

  const handleTopicClick = useCallback(value => () => {
    navigate(`${URLS.UNITS}/${unitUrl}/${value}`);
  }, [navigate, unitUrl]);

  const buttons = useMemo(() => {
    return TOPICS_LIST.map(({ label, value, icon: Icon }) => {
      return (
        <Fab
          key={value}
          variant="extended"
          color="secondary"
          sx={{ m: 1 }}
          onClick={handleTopicClick(value)}
        >
          <Icon sx={{ mr: 1 }} />
          {label}
        </Fab>
      );
    });
  }, [handleTopicClick]);

  return (
    <Topic header={name}>
      {buttons}
    </Topic>
  );
};

const mapStateToProps = state => {
  return {
    unit: unitSelector(state) || { name: 'Noname', url: '' },
  };
};

export default connect(mapStateToProps)(Unit);
