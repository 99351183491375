import { createSelector } from 'reselect';

const entityFieldSelector = (field, entitySelector, defaultValue = null) =>
  createSelector(
    [entitySelector],
    entity => entity ? entity[field] : defaultValue,
  );

const entityLoadedSelector = entityRootSelector => createSelector(
  [entityRootSelector],
  ({ data, loading, error }) => !(loading || error) && !!data,
);

export {
  entityFieldSelector,
  entityLoadedSelector,
};
