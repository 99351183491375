import {
  START_USER_SCOPES_LOADING,
  ERROR_USER_SCOPES_LOADING,
  SUCCESS_USER_SCOPES_LOADING,
} from '../../actions/action-types';

import {
  startEntityLoading,
  errorEntityLoading,
  successEntityLoading,
  createEntityReducer,
} from '../../../utils/reducer/reducer-utils';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const resolvers = {
  [START_USER_SCOPES_LOADING]: startEntityLoading,
  [ERROR_USER_SCOPES_LOADING]: errorEntityLoading,
  [SUCCESS_USER_SCOPES_LOADING]: successEntityLoading,
};

const userScopesReducer = createEntityReducer({
  resolvers,
  initialState,
});

export default userScopesReducer;
