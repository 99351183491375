import MESSAGE_TYPE from '../../constants/message-type';

const createMessage = (message, variant) => ({
  id: new Date().getTime(),
  message,
  variant,
});

const createSuccessMessage = message =>
  createMessage(message, MESSAGE_TYPE.SUCCESS);

const createErrorMessage = message =>
  createMessage(message, MESSAGE_TYPE.ERROR);

const createInfoMessage = message =>
  createMessage(message, MESSAGE_TYPE.INFO);

export {
  createMessage,
  createSuccessMessage,
  createErrorMessage,
  createInfoMessage,
};
