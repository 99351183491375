import { combineReducers } from 'redux';

import auth from './auth';
import endpoints from './endpoints';
import endpointScopes from './endpoint-scopes';
import messages from './messages';
import phrases from './phrases';
import router from './router';
import scopes from './scopes';
import search from './search';
import statistics from './statistics';
import ui from './ui';
import units from './units';
import unitPhrases from './unit-phrases';
import unitWords from './unit-words';
import users from './users';
import userScopes from './user-scopes';
import words from './words';

export default combineReducers({
  auth,
  endpoints,
  endpointScopes,
  messages,
  phrases,
  router,
  scopes,
  search,
  statistics,
  ui,
  units,
  unitPhrases,
  unitWords,
  users,
  userScopes,
  words,
});
