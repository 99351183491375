import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, Outlet, useLocation } from 'react-router-dom';

import { setRouteParams as setRouteParamsAction } from '../../../redux/actions';

const RouterService = ({ setRouteParams }) => {
  const params = useParams();
  const { pathname } = useLocation();
  const re = /^\/administration\/([\w-]+)\/?/i;
  let entity = '';
  try {
    entity = pathname.match(re)[1];
  } catch(e) {}

  useEffect(() => {
    setRouteParams(params, entity);
  }, [entity, params, setRouteParams]);

  return <Outlet />;
};

const mapDispatchToProps = {
  setRouteParams: setRouteParamsAction,
};

export default connect(null, mapDispatchToProps)(RouterService);
