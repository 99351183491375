import { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import {
  searchValueSelector,
  endpointsDataSelector,
  endpointsErrorSelector,
  endpointsLoadingSelector,
} from '../../../../redux/selectors';
import TileCard from '../../../TileCard';
import {useAdminEndpointContext} from './admin-endpoints-context';
import { getEndpoints } from '../../../../redux/actions';
import URLS from '../../../../constants/urls';
import FormModes from '../../../../constants/form-modes';
import createEndpointFilter from '../../../../utils/filters/create-endpoint-filter';
import { scrollIntoViewIfNecessary } from '../../../../utils/ui/scroll-into-view';

const listBoxId = 'endpoints-container';

const AdminEndpointsList = ({
  data,
  getData,
  loading,
  error,
  searchValue,
}) => {
  const navigate = useNavigate();
  const { endpointUrl } = useParams();
  const { mode } = useAdminEndpointContext();

  const disabled = (mode !== FormModes.NONE) || (endpointUrl === 'new');

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    scrollIntoViewIfNecessary(endpointUrl, listBoxId);
  }, [data, endpointUrl]);

  const handleSelect = useCallback(
    url => () => { navigate(`${URLS.ADMINISTRATION}/endpoints/${url}`) },
    [navigate],
  );

  const items = useMemo(() => {
    return Array.isArray(data)
      ? data
        .filter(createEndpointFilter(searchValue))
        .map(({ id, adminPageUrl }) => {
          return (
            <ListItemButton
              id={adminPageUrl}
              key={id}
              selected={endpointUrl === adminPageUrl}
              onClick={handleSelect(adminPageUrl)}
              disabled={disabled}
            >
              <ListItemText primary={adminPageUrl} />
            </ListItemButton>
          );
        })
      : null;
  }, [data, disabled, endpointUrl, handleSelect, searchValue]);

  return (
    <TileCard sx={{ height: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flex: '0 1 auto' }}>
        <Typography variant="h6">
          List of endpoints
        </Typography>
        <Fab
          aria-label="Add endpoint"
          color="primary"
          size="small"
          onClick={handleSelect('new')}
          disabled={disabled}
        >
          <AddIcon />
        </Fab>
      </Grid>
      <CardContent sx={{ flex: '1 1 auto', height: 'calc(100% - 32px)' }}>
        {loading && <div>Loading...</div>}
        {!loading && error ? <div>Error: {error.message}</div> : null}
        {!(loading || error) && data ? (
            <List
              id={listBoxId}
              component="nav"
              aria-label="list of endpoints"
              sx={{
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
            >
              {items}
            </List>
          ) : null
        }
      </CardContent>
    </TileCard>
  );
};

const mapStateToProps = state => ({
  data: endpointsDataSelector(state),
  loading: endpointsLoadingSelector(state),
  error: endpointsErrorSelector(state),
  searchValue: searchValueSelector(state),
});

const mapDispatchToProps = {
  getData: getEndpoints,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEndpointsList);
