import { deleteRequest, get, post, put } from '../utils/api/request';
import URLS from '../constants/urls';

const getUnits = () => get(URLS.API_UNITS);

const getUnitsWithResults = (login, testType) =>
  get(`${URLS.API_USERS}/${login}/units/${testType}`);

const getUnit = unitUrl => get(`${URLS.API_UNITS}/${unitUrl}`);

const createUnit = (unit, token) => post(URLS.API_UNITS, unit, token);

const updateUnit = (unit, token) => put(URLS.API_UNITS, unit, token);

const deleteUnit = (unitUrl, token) => deleteRequest(`${URLS.API_UNITS}/${unitUrl}`, null, token);

const unitsApi = {
  createUnit,
  deleteUnit,
  getUnit,
  getUnits,
  getUnitsWithResults,
  updateUnit,
};

export default unitsApi;