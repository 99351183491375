import { connect } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import { closeConfirmationModal } from '../../../../../redux/actions';
import {
  confirmationModalActionSelector,
  confirmationModalMessageSelector,
  confirmationModalOpenSelector,
  confirmationModalTitleSelector
} from '../../../../../redux/selectors';

const ConfirmationModal = ({
  open = false,
  title,
  message,
  onSubmit,
  onClose,
}) => {
  const handleSubmit = () => {
    onSubmit();
    handleCancel();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      scroll="paper"
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>
          No
        </Button>
        <Button onClick={handleSubmit}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  open: confirmationModalOpenSelector(state),
  title: confirmationModalTitleSelector(state),
  message: confirmationModalMessageSelector(state),
  onSubmit: confirmationModalActionSelector(state),
});

const mapDispatchToProps = {
  onClose: closeConfirmationModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
