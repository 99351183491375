import {
  START_USERS_LOADING,
  ERROR_USERS_LOADING,
  SUCCESS_USERS_LOADING,
  START_USER_SAVING,
  ERROR_USER_SAVING,
  SUCCESS_USER_SAVING,
} from '../../actions/action-types';

import {
  startEntityLoading,
  errorEntityLoading,
  successEntityLoading,
  startEntitySaving,
  errorEntitySaving,
  successEntitySaving,
  createEntityReducer,
} from '../../../utils/reducer/reducer-utils';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const resolvers = {
  [START_USERS_LOADING]: startEntityLoading,
  [ERROR_USERS_LOADING]: errorEntityLoading,
  [SUCCESS_USERS_LOADING]: successEntityLoading,
  [START_USER_SAVING]: startEntitySaving,
  [ERROR_USER_SAVING]: errorEntitySaving,
  [SUCCESS_USER_SAVING]: successEntitySaving,
};

const usersReducer = createEntityReducer({
  resolvers,
  initialState,
});

export default usersReducer;
