import { get, post, put } from '../utils/api/request';
import URLS from '../constants/urls';

const check = token => get(`${URLS.API_AUTH}/check`, null, token);

const getPersonalAccount = token => get(`${URLS.API_AUTH}/personal-account`, null, token);

const updatePassword = (data, token) => put(`${URLS.API_AUTH}/change-password`, data, token);

const updatePersonalAccount = (data, token) => put(`${URLS.API_AUTH}/personal-account`, data, token);

const login = data => post(`${URLS.API_AUTH}/login`, data);

const logout = token => get(`${URLS.API_AUTH}/logout`, null, token);

const register = data => post(`${URLS.API_AUTH}/register`, data);

const authApi = {
  check,
  getPersonalAccount,
  login,
  logout,
  register,
  updatePassword,
  updatePersonalAccount,
};

export default authApi;
