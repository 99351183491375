import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import BarChartIcon from '@mui/icons-material/BarChart';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
import SaveIcon from '@mui/icons-material/Save';

import TileCard from '../../../TileCard';
import {
  scopesDataSelector,
  userEmailSelector,
  userLoginSelector,
  userScopesDataSelector,
} from '../../../../redux/selectors';
import {
  getScopes as getScopesAction,
  getUserScopes as getUserScopesAction,
  saveUser,
  getUsers,
  resetPassword as resetPasswordAction,
  openConfirmationModal as openConfirmationModalAction,
} from '../../../../redux/actions';
import { useAdminUserContext } from './admin-users-context';
import FormModes from '../../../../constants/form-modes';
import { isFilledArray } from '../../../../utils/array';
import AdminScopesListControl from '../shared/AdminScopesListControl';
import URLS from '../../../../constants/urls';

const initialState = {
  userScopes: [],
};

const initialValidationData = {};

const AdminUserForm = props => {
  const {
    email,
    login,
    getScopes,
    getUserScopes,
    openConfirmationModal,
    refreshUsers,
    saveData,
    scopes,
    userScopes,
    resetPassword,
  } = props;
  const [state, setState] = useState({ ...initialState });
  const [validationData, setValidationData] = useState({ ...initialValidationData });
  const { login: loginParam } = useParams();
  const navigate = useNavigate();
  const {
    mode,
    handleEdit: onEdit,
    handleCancel: onCancel,
  } = useAdminUserContext();

  useEffect(() => {
    getScopes();
  }, [getScopes]);

  useEffect(() => {
    if (loginParam) {
      getUserScopes(loginParam);
    }
  }, [getUserScopes, loginParam]);

  const disabled = mode === FormModes.NONE;

  const userScopesSrc = disabled
    ? userScopes
    : state.userScopes;

  const selected = useMemo(() => isFilledArray(userScopesSrc)
      ? userScopesSrc.map(({ value }) => value)
      : [],
    [userScopesSrc]);

  const handleEdit = useCallback(() => {
    onEdit();
    setState({ userScopes });
  }, [userScopes, onEdit]);

  const handleCancel = useCallback(() => {
    onCancel();
    setState({ ...initialState });
  }, [onCancel]);

  const handleSave = useCallback(() => {
    const user = {
      login: login,
      email: email,
      scopes: isFilledArray(state.userScopes)
        ? state.userScopes.map(({ value }) => value)
        : [],
    };
    saveData(user, result => {
      if (result && result.valid === false) {
        setValidationData(result.data);
      } else {
        setValidationData({ ...initialValidationData });
        refreshUsers();
        getUserScopes(login);
        onCancel();
        setState({ ...initialState });
        // navigate(`${URLS.ADMINISTRATION}/users/${login}`);
      }
    });
  }, [email, getUserScopes, login, onCancel, refreshUsers, saveData, state.userScopes]);

  const handleResetPassword = useCallback(() => {
    openConfirmationModal({
      title: `Reset password for ${login}`,
      message: `Please confirm that you are going to reset password for user ${login}`,
      action: () => {
        resetPassword(login);
      },
    });
  }, [login, openConfirmationModal, resetPassword]);

  const handleSubmit = useCallback(e => {
    e.preventDefault();
  }, []);

  const getTextField = useCallback((field, label, disabledElm = true, onChange = () => {}) => (
    <TextField
      error={!!validationData[field]}
      disabled={disabledElm}
      fullWidth
      id={field}
      label={label}
      helperText={validationData[field] ? validationData[field] : ''}
      name={field}
      onChange={onChange}
      variant="standard"
      value={(disabledElm ? props[field] : state[field]) || ''}
    />
  ), [props, state, validationData]);

  const loginTextField = useMemo(() => (
    getTextField('login', 'Login')
  ), [getTextField]);

  const emailTextField = useMemo(() => (
    getTextField('email', 'Email')
  ), [getTextField]);

  const handleScopesChange = useCallback(scopeValue => {
    if (state.userScopes.findIndex(({ value }) => scopeValue === value) > -1) {
      setState({
        ...state,
        userScopes: state.userScopes
          .filter(({ value }) => value !== scopeValue),
      });
    } else {
      const scope = scopes.find(({ value }) => value === scopeValue);
      setState({
        ...state,
        userScopes: [...state.userScopes, scope],
      });
    }
  }, [scopes, state]);

  const handleStatistics = useCallback(() => {
    navigate(`${URLS.ADMINISTRATION}/users/${login}/statistics`);
  }, [login, navigate]);

  return (
    <TileCard sx={{ height: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flow: '0 1 auto' }}>
        <Typography variant="h6">
          {login || 'User'}
        </Typography>
        {mode === FormModes.NONE && (
          <Box>
            <Tooltip title="Reset password">
              <Fab
                aria-label="Reset password"
                color="secondary"
                size="small"
                onClick={handleResetPassword}
                sx={{ mr: 1 }}
              >
                <LockResetIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Edit">
              <Fab
                aria-label="Edit"
                color="secondary"
                size="small"
                onClick={handleEdit}
                sx={{ mr: 1 }}
              >
                <EditIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Statistics">
              <Fab
                aria-label="Statistics"
                color="primary"
                size="small"
                onClick={handleStatistics}
              >
                <BarChartIcon />
              </Fab>
            </Tooltip>
          </Box>
        )}
        {mode !== FormModes.NONE && (
          <Box>
            <Tooltip title="Cancel">
              <Fab
                aria-label="Cancel"
                color="default"
                size="small"
                onClick={handleCancel}
                sx={{ mr: 1 }}
              >
                <CancelIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Save">
              <Fab
                aria-label="Save"
                color="success"
                size="small"
                onClick={handleSave}
                disabled={false}
              >
                <SaveIcon />
              </Fab>
            </Tooltip>
          </Box>
        )}
      </Grid>
      <CardContent
        sx={{
          flex: '1 1 auto',
          height: 'calc(100% - 36px)',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        <form action="" name="unit" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {loginTextField}
            </Grid>
            <Grid item xs={12}>
              {emailTextField}
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <AdminScopesListControl
                data={scopes}
                disabled={disabled}
                onChange={handleScopesChange}
                value={selected}
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </TileCard>
  );
};

const mapStateToProps = state => ({
  email: userEmailSelector(state),
  login: userLoginSelector(state),
  scopes: scopesDataSelector(state),
  userScopes: userScopesDataSelector(state),
});

const mapDispatchToProps = {
  openConfirmationModal: openConfirmationModalAction,
  getUserScopes: getUserScopesAction,
  getScopes: getScopesAction,
  refreshUsers: getUsers,
  saveData: saveUser,
  resetPassword: resetPasswordAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserForm);
