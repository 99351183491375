import {
  START_STATISTICS_REQUEST,
  ERROR_STATISTICS_REQUEST,
  SUCCESS_STATISTICS_REQUEST,
} from '../../actions/action-types';

const initialState = {
  requestInProgress: false,
  error: null,
  data: null,
};

const statisticsReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case START_STATISTICS_REQUEST:
      return {
        ...state,
        requestInProgress: true,
      };

    case ERROR_STATISTICS_REQUEST:
      return {
        ...state,
        data: null,
        error: payload,
        requestInProgress: false,
      };

    case SUCCESS_STATISTICS_REQUEST:
      return {
        ...state,
        data: payload,
        error: null,
        requestInProgress: false,
      };

    default:
      return state;
  }
};

export default statisticsReducer;
