import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom'

import Topic from '../../Topic';
import { getUnitWords } from '../../../redux/actions';
import {
  unitWordsErrorSelector,
  unitWordsDataLoadedSelector,
  unitWordsLoadingSelector,
} from '../../../redux/selectors';

const WordsWriting = ({ dataLoaded, loading, error, getData }) => {
  const { unitUrl } = useParams();

  useEffect(() => {
    getData(unitUrl);
  }, [getData, unitUrl]);

  return (
    <Topic
      header="Test: Words writing"
      sx={{ height: '100%', display: 'flex', flexFlow: 'column nowrap' }}
    >
      {loading && <div>Loading...</div>}
      {(!loading && error) && <div>Error: {error.message}</div>}
      {dataLoaded && <Outlet />}
    </Topic>
  );
};

const mapStateToProps = state => ({
  dataLoaded: unitWordsDataLoadedSelector(state),
  loading: unitWordsLoadingSelector(state),
  error: unitWordsErrorSelector(state),
});

const mapDispatchToProps = {
  getData: getUnitWords,
};

export default connect(mapStateToProps, mapDispatchToProps)(WordsWriting);
