import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import TileCard from '../../../TileCard';
import {
  phrasesDataSelector,
  unitIdSelector,
  unitNameSelector, unitPhrasesDataSelector,
  unitUrlSelector,
  unitWordsDataSelector, wordsDataSelector,
} from '../../../../redux/selectors';
import {
  getUnits,
  getWords as getWordsAction,
  getUnitWords as getUnitWordsAction,
  saveUnit,
  openConfirmationModal as openConfirmationModalAction,
  deleteUnit,
  getUnitPhrases as getUnitPhrasesAction,
  getPhrases as getPhrasesAction,
} from '../../../../redux/actions';
import { useAdminUnitContext } from './admin-units-context';
import FormModes from '../../../../constants/form-modes';
import URLS from '../../../../constants/urls';
import { isFilledArray } from '../../../../utils/array';
import AdminWordsList from './AdminWordsList';
import AdminPhrasesList from './AdminPhrasesList';

const initialState = {
  url: '',
  name: '',
  words: [],
  phrases: [],
};

const initialValidationData = {
  url: '',
  name: '',
};

const AdminUnitsForm = props => {
  const {
    deleteItem,
    getUnitWords,
    getWords,
    getUnitPhrases,
    getPhrases,
    id,
    name,
    openConfirmationModal,
    refreshUnits,
    saveData,
    url,
    words,
    wordsData,
    phrases,
    phrasesData,
  } = props;
  const navigate = useNavigate();
  const [state, setState] = useState({ ...initialState });
  const [validationData, setValidationData] = useState({ ...initialValidationData });
  const { unitUrl } = useParams();
  const {
    mode,
    handleEdit: onEdit,
    handleCancel: onCancel,
  } = useAdminUnitContext();

  useEffect(() => {
    getWords();
  }, [getWords]);

  useEffect(() => {
    getPhrases();
  }, [getPhrases]);

  useEffect(() => {
    if (unitUrl !== 'new') {
      getUnitWords(unitUrl);
    }
  }, [getUnitWords, unitUrl]);

  useEffect(() => {
    if (unitUrl !== 'new') {
      getUnitPhrases(unitUrl);
    }
  }, [getUnitPhrases, unitUrl]);

  useEffect(() => {
    if (unitUrl === 'new') {
      setState({ ...initialState });
    }
  }, [unitUrl]);

  const disabled = mode === FormModes.NONE;

  const wordsSrc = disabled ? words : state.words;
  const phrasesSrc = disabled ? phrases : state.phrases;

  const selectedWord = useMemo(() => isFilledArray(wordsSrc)
      ? wordsSrc.map(({ id }) => id)
      : [],
    [wordsSrc]);

  const selectedPhrase = useMemo(() => isFilledArray(phrasesSrc)
      ? phrasesSrc.map(({ id }) => id)
      : [],
    [phrasesSrc]);

  const handleEdit = useCallback(() => {
    onEdit();
    setState({ name, url, words, phrases });
  }, [onEdit, name, url, words, phrases]);

  const handleCancel = useCallback(() => {
    onCancel();
    setState({ ...initialState });
    if (unitUrl === 'new') {
      navigate(`${URLS.ADMINISTRATION}/units`);
    }
  }, [navigate, onCancel, unitUrl]);

  const handleSave = useCallback(() => {
    const unit = {
      name: state.name,
      url: state.url,
      words: isFilledArray(state.words)
        ? state.words.map(({ id }) => id)
        : [],
      ...(id && { id }),
      phrases: isFilledArray(state.phrases)
        ? state.phrases.map(({ id }) => id)
        : [],
      ...(id && { id }),
    };
    saveData(unit, result => {
      if (result && result.valid === false) {
        setValidationData(result.data);
      } else {
        setValidationData({ ...initialValidationData });
        refreshUnits();
        getUnitWords(state.url);
        getUnitPhrases(state.url);
        onCancel();
        setState({ ...initialState });
        navigate(`${URLS.ADMINISTRATION}/units/${state.url}`);
      }
    });
  }, [getUnitPhrases, getUnitWords, id, navigate, onCancel, refreshUnits, saveData, state.name, state.phrases, state.url, state.words]);

  const handleDelete = useCallback( () => {
    openConfirmationModal({
      title: `Delete unit`,
      message: `Please confirm that you are going to delete unit ${name}?`,
      action: () => {
        deleteItem(url, () => {
          refreshUnits();
          onCancel();
          navigate(`${URLS.ADMINISTRATION}/units`);
        });
      },
    });
  }, [
    deleteItem,
    name,
    navigate,
    onCancel,
    openConfirmationModal,
    refreshUnits,
    url,
  ]);

  const handleChange = useCallback(({ target: { name, value } }) => {
    setState({ ...state, [name]: value });
  }, [setState, state]);

  const handleSubmit = useCallback(e => {
    e.preventDefault();
  }, []);

  const getTextField = useCallback((field, label) => (
    <TextField
      error={!!validationData[field]}
      disabled={mode === FormModes.NONE}
      fullWidth
      id={field}
      label={label}
      helperText={validationData[field] ? validationData[field] : ''}
      name={field}
      onChange={handleChange}
      variant="standard"
      value={(mode === FormModes.NONE ? props[field] : state[field]) || ''}
    />
  ), [handleChange, mode, props, state, validationData]);

  const nameTextField = useMemo(() => (
    getTextField('name', 'Name')
  ), [getTextField]);

  const urlTextField = useMemo(() => (
    getTextField('url', 'URL')
  ), [getTextField]);

  const handleWordsChange = useCallback(wordId => {
    if (state.words.findIndex(({ id }) => wordId === id) > -1) {
      setState({
        ...state,
        words: state.words.filter(({ id }) => id !== wordId),
      });
    } else {
      const word = wordsData.find(({ id }) => id === wordId);
      setState({
        ...state,
        words: [...state.words, word],
      });
    }
  }, [state, wordsData]);

  const handlePhrasesChange = useCallback(phraseId => {
    if (state.phrases.findIndex(({ id }) => phraseId === id) > -1) {
      setState({
        ...state,
        phrases: state.phrases.filter(({ id }) => id !== phraseId),
      });
    } else {
      const phrase = phrasesData.find(({ id }) => id === phraseId);
      setState({
        ...state,
        phrases: [...state.phrases, phrase],
      });
    }
  }, [phrasesData, state]);

  return (
    <TileCard sx={{ height: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ flow: '0 1 auto' }}>
        <Typography variant="h6">
          {name || 'Unit'}
        </Typography>
        {mode === FormModes.NONE && (
          <Box>
            <Fab
              aria-label="Edit"
              color="primary"
              size="small"
              onClick={handleEdit}
              disabled={unitUrl === 'new'}
            >
              <EditIcon />
            </Fab>
          </Box>
        )}
        {mode !== FormModes.NONE && (
          <Box>
            <Fab
              aria-label="Cancel"
              color="default"
              size="small"
              onClick={handleCancel}
              sx={{ mr: 1 }}
            >
              <CancelIcon />
            </Fab>
            <Fab
              aria-label="Delete"
              color="error"
              size="small"
              onClick={handleDelete}
              disabled={unitUrl === 'new'}
              sx={{ mr: 1 }}
            >
              <RemoveCircleIcon />
            </Fab>
            <Fab
              aria-label="Save"
              color="success"
              size="small"
              onClick={handleSave}
              disabled={false}
            >
              <SaveIcon />
            </Fab>
          </Box>
        )}
      </Grid>
      <CardContent
        sx={{
          flex: '1 1 auto',
          height: 'calc(100% - 36px)',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        <form action="" name="unit" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {nameTextField}
            </Grid>
            <Grid item xs={12}>
              {urlTextField}
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <AdminWordsList
                data={wordsData}
                disabled={disabled}
                onChange={handleWordsChange}
                value={selectedWord}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <AdminPhrasesList
                data={phrasesData}
                disabled={disabled}
                onChange={handlePhrasesChange}
                value={selectedPhrase}
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </TileCard>
  );
};

const mapStateToProps = state => ({
  id: unitIdSelector(state),
  name: unitNameSelector(state),
  url: unitUrlSelector(state),
  words: unitWordsDataSelector(state),
  wordsData: wordsDataSelector(state),
  phrases: unitPhrasesDataSelector(state),
  phrasesData: phrasesDataSelector(state),
});

const mapDispatchToProps = {
  openConfirmationModal: openConfirmationModalAction,
  deleteItem: deleteUnit,
  refreshUnits: getUnits,
  getUnitWords: getUnitWordsAction,
  getWords: getWordsAction,
  getUnitPhrases: getUnitPhrasesAction,
  getPhrases: getPhrasesAction,
  saveData: saveUnit,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUnitsForm);
