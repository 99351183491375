import { connect } from 'react-redux';
import { useEffect} from 'react';
import { useSnackbar } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';

import { messagesDataSelector } from '../../../redux/selectors/messages';
import { removeMessage as removeMessageAction } from '../../../redux/actions';
import { isFilledArray } from '../../../utils/array';

const MessagesService = ({ data, removeMessage }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (isFilledArray(data)) {
      const { id, message, variant } = data[0];

      enqueueSnackbar(
        message,
        {
          variant,
          action: key => (
            <IconButton onClick={() => { closeSnackbar(key) }} size="small">
              <CancelIcon fontSize="small" sx={{ color: 'white' }} />
            </IconButton>
          ),
        },
      );
      removeMessage(id);
    }
  }, [data, enqueueSnackbar, removeMessage, closeSnackbar]);

  return null;
};

const mapStateToProps = state => ({
  data: messagesDataSelector(state),
});

const mapDispatchToProps = {
  removeMessage: removeMessageAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesService);
