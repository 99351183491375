import {
  START_PHRASES_LOADING,
  ERROR_PHRASES_LOADING,
  SUCCESS_PHRASES_LOADING,
  START_PHRASE_SAVING,
  ERROR_PHRASE_SAVING,
  SUCCESS_PHRASE_SAVING,
  START_PHRASE_REMOVING,
  ERROR_PHRASE_REMOVING,
  SUCCESS_PHRASE_REMOVING,
} from '../action-types';
import { addMessage } from '../messages';
import { createErrorMessage, createInfoMessage, createSuccessMessage } from '../../../utils/messages/messages-utils';
import { authTokenSelector } from '../../selectors';
import { logout } from '../auth';

const startPhrasesLoading = () => ({
  type: START_PHRASES_LOADING,
});

const errorPhrasesLoading = error => ({
  type: ERROR_PHRASES_LOADING,
  payload: error,
});

const successPhrasesLoading = data => ({
  type: SUCCESS_PHRASES_LOADING,
  payload: data,
});

const getPhrases = cbSuccess => async (dispatch, getState, api) => {
  dispatch(startPhrasesLoading());
  const token = authTokenSelector(getState());
  try {
    const data = await api.phrases.getPhrases(token);
    dispatch(successPhrasesLoading(data));
    if (cbSuccess) cbSuccess(data);
  } catch (error) {
    dispatch(addMessage(createErrorMessage('Could not fetch phrases')));
    dispatch(errorPhrasesLoading(error));
    if (error.statusCode === 401) {
      await logout()(dispatch, getState, api);
    }
  }
};

const startPhraseSaving = () => ({
  type: START_PHRASE_SAVING,
});

const errorPhraseSaving = error => ({
  type: ERROR_PHRASE_SAVING,
  payload: error,
});

const successPhraseSaving = () => ({
  type: SUCCESS_PHRASE_SAVING,
});

const savePhrase = (phrase, cbSuccess) => async (dispatch, getState, api) => {
  dispatch(startPhraseSaving());
  const token = authTokenSelector(getState());
  const action = phrase.id ? 'updatePhrase' : 'createPhrase';
  try {
    const response = await api.phrases[action](phrase, token);
    dispatch(addMessage(createSuccessMessage('Phrase was saved successfully')));
    dispatch(successPhraseSaving());
    if (cbSuccess) cbSuccess(response);
  } catch (error) {
    dispatch(addMessage(createErrorMessage('Phrase was not saved')));
    dispatch(errorPhraseSaving(error));
    if (error.statusCode === 401) {
      await logout()(dispatch, getState, api);
    }
  }
};

const startPhraseRemoving = () => ({
  type: START_PHRASE_REMOVING,
});

const errorPhraseRemoving = error => ({
  type: ERROR_PHRASE_REMOVING,
  payload: error,
});

const successPhraseRemoving = () => ({
  type: SUCCESS_PHRASE_REMOVING,
});

const deletePhrase = (phraseUrl, cbSuccess) => async (dispatch, getState, api) => {
  dispatch(startPhraseRemoving());
  const token = authTokenSelector(getState());
  try {
    await api.phrases.deletePhrase(phraseUrl, token);
    dispatch(addMessage(createInfoMessage('Phrase was removed')));
    dispatch(successPhraseRemoving());
    if (cbSuccess) cbSuccess();
  } catch (error) {
    dispatch(addMessage(createErrorMessage('Error during phrase removal')));
    dispatch(errorPhraseRemoving(error));
    if (error.statusCode === 401) {
      await logout()(dispatch, getState, api);
    }
  }
};

export {
  getPhrases,
  savePhrase,
  deletePhrase,
};
