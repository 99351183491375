import { SET_SEARCH } from '../action-types';

const setSearch = value => ({
  type: SET_SEARCH,
  payload: value,
});

export {
  setSearch,
};
