import {
  START_UNITS_LOADING,
  ERROR_UNITS_LOADING,
  SUCCESS_UNITS_LOADING,
  START_UNIT_SAVING,
  ERROR_UNIT_SAVING,
  SUCCESS_UNIT_SAVING, START_UNIT_REMOVING, ERROR_UNIT_REMOVING, SUCCESS_UNIT_REMOVING,
} from '../action-types';
import { addMessage } from '../messages';
import { createErrorMessage, createInfoMessage, createSuccessMessage } from '../../../utils/messages/messages-utils';
import { authTokenSelector } from '../../selectors';
import { logout } from '../auth';

const startUnitsLoading = () => ({
  type: START_UNITS_LOADING,
});

const errorUnitsLoading = error => ({
  type: ERROR_UNITS_LOADING,
  payload: error,
});

const successUnitsLoading = data => ({
  type: SUCCESS_UNITS_LOADING,
  payload: data,
});

const getUnits = (cbSuccess = () => {}, login = '', testType = '') =>
  async (dispatch, getState, api) => {
    dispatch(startUnitsLoading());

    try {
      const apiAction = login && testType
        ? 'getUnitsWithResults' : 'getUnits';
      const units = await api.units[apiAction](login, testType);
      dispatch(successUnitsLoading(units));
      if (cbSuccess) cbSuccess();
    } catch (e) {
      dispatch(errorUnitsLoading(e));
      dispatch(addMessage(createErrorMessage('Can not fetch units')));
      if (e.statusCode === 401) {
        await logout()(dispatch, getState, api);
      }
    }
  };

const startUnitSaving = () => ({
  type: START_UNIT_SAVING,
});

const errorUnitSaving = error => ({
  type: ERROR_UNIT_SAVING,
  payload: error,
});

const successUnitSaving = () => ({
  type: SUCCESS_UNIT_SAVING,
});

const saveUnit = (unit, cbSuccess = () => {}) =>
  async (dispatch, getState, api) => {
    dispatch(startUnitSaving());
    const token = authTokenSelector(getState());
    const action = unit.id ? 'updateUnit' : 'createUnit';

    try {
      const result = await api.units[action](unit, token);
      if (!(result && result.valid === false)) {
        dispatch(addMessage(createSuccessMessage('Unit has been saved successfully')));
      }
      dispatch(successUnitSaving());
      cbSuccess(result);
    } catch (e) {
      dispatch(errorUnitSaving(e));
      dispatch(addMessage(createErrorMessage('Error saving unit')));
      if (e.statusCode === 401) {
        await logout()(dispatch, getState, api);
      }
    }
  };

const startUnitRemoving = () => ({
  type: START_UNIT_REMOVING,
});

const errorUnitRemoving = error => ({
  type: ERROR_UNIT_REMOVING,
  payload: error,
});

const successUnitRemoving = () => ({
  type: SUCCESS_UNIT_REMOVING,
});

const deleteUnit = (unitUrl, cbSuccess) =>
  async (dispatch, getState, api) => {
    dispatch(startUnitRemoving());
    const token = authTokenSelector(getState());
    try {
      await api.units.deleteUnit(unitUrl, token);
      dispatch(successUnitRemoving());
      dispatch(addMessage(createInfoMessage('Unit was successfully removed')));
      if (cbSuccess) cbSuccess();
    } catch (e) {
      dispatch(errorUnitRemoving(e));
      dispatch(addMessage(createErrorMessage('Error removing unit')));
      if (e.statusCode === 401) {
        await logout()(dispatch, getState, api);
      }
    }
  };

export {
  deleteUnit,
  getUnits,
  saveUnit,
};
