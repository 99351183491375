import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

import DeleteConfirmModal from './shared/ConfirmationModal';
import Topic from '../../Topic';

const Administration = () => {
  return (
    <>
      <DeleteConfirmModal />
      <Box sx={{ width: '100%', height: '100%', display: 'flex', flexFlow: 'column nowrap'}}>
        <Topic header="Administration" sx={{ height: 'calc(100% - 32px)' }}>
          <Outlet />
        </Topic>
      </Box>
    </>
  );
};

export default Administration;
