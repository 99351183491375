import { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BarChartIcon from '@mui/icons-material/BarChart';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';

import { DRAWER_WIDTH } from '../../constants/ui-properties';
import { closeSidebar } from '../../redux/actions';
import { sidebarOpenSelector } from '../../redux/selectors';

const NAV_ITEMS = {
  HOME: {
    label: 'Home',
    url: '/',
    icon: HomeIcon,
  },
  UNITS: {
    label: 'Units',
    url: '/units',
    icon: SchoolIcon,
  },
  STATISTICS: {
    label: 'Statistics',
    url: '/statistics',
    icon: BarChartIcon,
  },
  ADMINISTRATION: {
    label: 'Administration',
    url: '/administration',
    icon: AdminPanelSettingsIcon,
  },
};

const Index = ({
  onClose,
  open,
  window,
}) => {
  const container = window !== undefined ? () => window().document.body : undefined;
  const navigate = useNavigate();

  const getHandleMenuItemClick = useCallback(url => () => {
    navigate(url);
    onClose();
  }, [navigate, onClose]);

  const drawer = useMemo(() => (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {[
          NAV_ITEMS.HOME,
          NAV_ITEMS.UNITS,
          NAV_ITEMS.STATISTICS,
          NAV_ITEMS.ADMINISTRATION,
        ].map(({ label, url, icon: Icon }) => (
          <ListItem button key={label} onClick={getHandleMenuItemClick(url)}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
      {/*<Divider />
    <List>
      {['All mail', 'Trash', 'Spam'].map((text, index) => (
        <ListItem button key={text}>
          <ListItemIcon>
            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      ))}
    </List>*/}
    </div>
  ), [getHandleMenuItemClick]);

  return (
    <Box
      component="nav"
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={open}
        onClose={onClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{'& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH }}}
      >
        {drawer}
      </Drawer>
    </Box>
  )
};

const mapStateToProps = state => ({
  open: sidebarOpenSelector(state),
});

const mapDispatchToProps = {
  onClose: closeSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
