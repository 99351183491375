import { createSelector } from 'reselect';
import { routerParamsSelector } from '../router';
import { entityFieldSelector } from '../common-selectors';

const phrasesSelector = state => state.phrases;

const phrasesLoadingSelector = state => phrasesSelector(state).loading;

const phrasesErrorSelector = state => phrasesSelector(state).error;

const phrasesDataSelector = state => phrasesSelector(state).data;

const phraseSelector = createSelector(
  [phrasesDataSelector, routerParamsSelector],
  (data, params) => {
    if (Array.isArray(data) && params && params.phraseUrl) {
      return  data.find(({ url }) => url === params.phraseUrl);
    } else {
      return null;
    }
  },
);

const phraseIdSelector = entityFieldSelector('id', phraseSelector, '');
const phraseUrlSelector = entityFieldSelector('url', phraseSelector, '');
const phraseEnQuestionSelector = entityFieldSelector('enQuestion', phraseSelector, '');
const phraseAnswersSelector = entityFieldSelector('answers', phraseSelector, []);
const phraseRuSelector = entityFieldSelector('ru', phraseSelector, '');

export {
  phrasesDataSelector,
  phrasesErrorSelector,
  phrasesLoadingSelector,
  phraseSelector,
  phraseIdSelector,
  phraseAnswersSelector,
  phraseEnQuestionSelector,
  phraseRuSelector,
  phraseUrlSelector,
};
