import { get, put } from '../utils/api/request';
import URLS from '../constants/urls';

const { API_USERS } = URLS;

const getUsers = token => get(API_USERS, null, token);

const getUser = (login, token) => get(`${API_USERS}/${login}`, null, token);

const updateUser = (user, token) => put(API_USERS, user, token);

const resetPassword = (login, token) => put(`${API_USERS}/reset-password`, { login }, token);

const usersApi = {
  getUser,
  getUsers,
  resetPassword,
  updateUser,
};

export default usersApi;
