import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom'

import Topic from '../../Topic';
import { getUnitPhrases } from '../../../redux/actions';
import {
  unitPhrasesErrorSelector,
  unitPhrasesLoadedSelector,
  unitPhrasesLoadingSelector
} from '../../../redux/selectors';
import { useParams } from 'react-router-dom';

const Phrases = ({ dataLoaded, loading, error, getData }) => {
  const { unitUrl } = useParams();

  useEffect(() => {
    getData(unitUrl);
  }, [getData, unitUrl]);

  return (
    <Topic
      header="Test: Phrases"
      sx={{ height: '100%', display: 'flex', flexFlow: 'column nowrap' }}
    >
      {loading && <div>Loading...</div>}
      {(!loading && error) && <div>Error: {error.message}</div>}
      {dataLoaded && <Outlet />}
    </Topic>
  );
};

const mapStateToProps = state => ({
  dataLoaded: unitPhrasesLoadedSelector(state),
  loading: unitPhrasesLoadingSelector(state),
  error: unitPhrasesErrorSelector(state),
});

const mapDispatchToProps = {
  getData: getUnitPhrases,
};

export default connect(mapStateToProps, mapDispatchToProps)(Phrases);
