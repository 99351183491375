import { connect } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import URLS from '../../constants/urls';
import { authLoggedSelector } from '../../redux/selectors';

const AuthRequired = ({ children, logged }) => {
  const location = useLocation();

  if (!logged) {
    return <Navigate to={URLS.LOGIN} state={{ from: location }} replace />
  }

  return children;
};

const mapStateToProps = state => ({
  logged: authLoggedSelector(state),
});

export default connect(mapStateToProps)(AuthRequired);
