import TESTS from '../constants/tests';

/**
 * Parses given url and returns test's config
 * @param {string} url
 * @returns {Object} - test's config
 */
const getTestConfig = url => {
  const pathname = url.split('?')[0];
  const pathSegments = pathname.split('/');
  let result = null;
  let testNames = Object.keys(TESTS);
  for (let i = 0; i < testNames.length; i++) {
    const testName = testNames[i];
    result = pathSegments.includes(testName)
      ? { ...TESTS[testName], id: testName }
      : result;
    if (result) break;
  }

  return result;
};

export default getTestConfig;
