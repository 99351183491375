import { Outlet } from 'react-router-dom';

import AuthRequired from '../../../AuthRequired';
import TestsNetworkService from './TestsNetworkService';

const Tests = () => {
  return (
    <>
      <TestsNetworkService />
      <AuthRequired>
        <Outlet />
      </AuthRequired>
    </>
  );
};

export default Tests;
