import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Chip from '@mui/material/Chip';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ReplyIcon from '@mui/icons-material/Reply';

import { authLoggedSelector, unitPhrasesDataSelector } from '../../../redux/selectors';
import { isEmptyArray, isFilledArray } from '../../../utils/array';
import {
  openConfirmationModal,
  saveUnitPhrasesResults,
  showNotLoggedMessage,
} from '../../../redux/actions';
import PhrasesTestResultsModal from './PhrasesTestResultsModal';
import URLS from '../../../constants/urls';

const StyledToolbarBox = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledQuestionBox = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const createAnswers = data => isFilledArray(data)
  ? data.map(item => ({ ...item, validationMessage: '', answer: '' }))
  : [];

const getCurrentAnswer = (answers, testIndex) => isFilledArray(answers)
  ? answers[testIndex - 1]
  : null;

const PhrasesContentTest = ({
  data,
  logged,
  saveResult,
  showConfirmationModal,
  showNotLogged,
}) => {
  const navigate = useNavigate();
  const { unitUrl } = useParams();
  const [testNumber, setTestNumber] = useState(0);
  const [testIndex, setTestIndex] = useState(1);
  const [answers, setAnswers] = useState([]);
  const [testResult, setTestResult] = useState();

  const progress = Math.floor(testIndex / Math.max(testNumber, 1) * 100);

  useEffect(() => {
    const testNumber = Array.isArray(data) ? data.length : 0
    setTestNumber(testNumber);
    setTestIndex(1);
    setAnswers(createAnswers(data));
  }, [data]);

  const focuseOnAnswerInput = useCallback(() => {
    const input = document.getElementById('Answer');
    if (input) input.focus();
  }, []);

  useEffect(() => {
    focuseOnAnswerInput();
  }, [focuseOnAnswerInput, testIndex]);

  const currentAnswer = useMemo(
    () => getCurrentAnswer(answers, testIndex) || { enQuestion: '' },
    [answers, testIndex]
  );

  const handleNext = useCallback(() => {
    const newTestIndex = testIndex < testNumber ? testIndex + 1 : 1;
    setTestIndex(newTestIndex);
  }, [testIndex, testNumber]);

  const handlePrev = useCallback(() => {
    const newTestIndex = testIndex > 1 ?  testIndex - 1 : testNumber;
    setTestIndex(newTestIndex);
  }, [testIndex, testNumber]);

  const changeCurrentAnswer = useCallback(newValue => {
    setAnswers(answers.map(({ id, ...rest }) => {
      if (id === currentAnswer.id) {
        rest.answer = newValue;
        rest.validationMessage = newValue.trim() ? '' : 'Answer is required';
      }

      return { id, ...rest };
    }));
  }, [answers, currentAnswer.id]);

  const handleAnswer = useCallback(() => {
    const { answer, validationMessage } = currentAnswer;
    if (!(answer || validationMessage)) {
      changeCurrentAnswer(answer);
    } else {
      const newAnswers = answers.slice(testIndex - 1).concat(answers.slice(0, testIndex - 1));
      const nextTest = newAnswers.find(({ answer: testAnswer }) => !testAnswer);
      if (nextTest) {
        const nextTestIndex = answers.findIndex(({ id }) => id === nextTest.id) + 1;
        setTestIndex(nextTestIndex);
      } else if (logged) {
        showConfirmationModal({
          title: 'Confirmation message',
          message: 'Are you sure that you are going to submit test results?',
          action: () => {
            const mappedAnswers = answers.map(({ id, answer }) => ({
              questionId: id,
              answer,
            }));
            const result = {
              createdTs: new Date().getTime(),
              answers: mappedAnswers,
            };
            saveResult(result, response => {
              setTestResult(response)
            });
          },
        });
      } else {
        showNotLogged();
      }
    }
  }, [answers, changeCurrentAnswer, currentAnswer, logged, saveResult, showConfirmationModal, showNotLogged, testIndex]);

  const handleChangeAnswer = useCallback(({ target: { value: newValue } }) => {
    changeCurrentAnswer(newValue);
  }, [changeCurrentAnswer]);

  const handleKeyDown = useCallback(({ key }) => {
    if (key === 'Enter') {
      handleAnswer();
    }
  }, [handleAnswer]);

  const handleWordClick = useCallback(word => () => {
    const newValue = `${currentAnswer.answer} ${word}`.trim();
    changeCurrentAnswer(newValue);
    focuseOnAnswerInput();
  }, [changeCurrentAnswer, currentAnswer.answer, focuseOnAnswerInput]);

  const question = useMemo(() => {
    return currentAnswer && currentAnswer.enQuestion
      .split(' ')
      .filter(item => item && item.trim())
      .map((word, i) => (
        <Chip
          key={`${word}-${i}`}
          label={word}
          onClick={handleWordClick(word)}
          sx={{ m: 1 }}
        />
      ));
  }, [currentAnswer, handleWordClick]);

  return (
    <>
      <PhrasesTestResultsModal
        open={!!testResult}
        data={testResult}
        onClose={() => {
          navigate(`${URLS.UNITS}/${unitUrl}/tests/phrases`);
        }}
      />
      {isFilledArray(data) && (
        <Card sx={{ maxWidth: 600 }}>
          <Box>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
          <Box sx={{ textAlign: 'right', pr: 1 }}>
            <Typography variant="caption" color="primary">
              {testIndex} / {testNumber}
            </Typography>
          </Box>
          <CardContent sx={{ p: 3 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              {currentAnswer.ru}
            </Typography>
            <Box sx={{ mb: 2 }}>
              <TextField
                error={!!currentAnswer.validationMessage}
                fullWidth
                id="Answer"
                inputProps={{ 'aria-label': 'Answer' }}
                helperText={currentAnswer.validationMessage}
                name="Answer"
                onChange={handleChangeAnswer}
                onKeyDown={handleKeyDown}
                variant="standard"
                value={currentAnswer.answer || ''}
              />
            </Box>
            <StyledQuestionBox>
              {question}
            </StyledQuestionBox>
          </CardContent>
          <CardActions>
            <StyledToolbarBox>
              <Fab
                variant="extended"
                color="secondary"
                sx={{ m: 1 }}
                onClick={handleAnswer}
              >
                <ReplyIcon />
                Answer
              </Fab>
              <Box>
                <IconButton
                  aria-label="previous"
                  edge="start"
                  size="small"
                  color="primary"
                  onClick={handlePrev}
                >
                  <ArrowLeftIcon />
                </IconButton>
                <IconButton
                  aria-label="next"
                  edge="start"
                  size="small"
                  color="primary"
                  onClick={handleNext}
                >
                  <ArrowRightIcon />
                </IconButton>
              </Box>
            </StyledToolbarBox>
          </CardActions>
        </Card>
      )}
      {isEmptyArray(data) && (
        <div>No data to display</div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  data: unitPhrasesDataSelector(state),
  logged: authLoggedSelector(state),
});

const mapDispatchToProps = {
  showConfirmationModal: openConfirmationModal,
  saveResult: saveUnitPhrasesResults,
  showNotLogged: showNotLoggedMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhrasesContentTest);
