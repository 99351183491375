import { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LoginIcon from '@mui/icons-material/Login';

import URLS from '../../../constants/urls';
import {
  login as loginAction,
  register as registerAction,
} from '../../../redux/actions/auth';
import { addMessage as addMessageAction } from '../../../redux/actions';
import { authRequestInProgressSelector } from '../../../redux/selectors';
import TextInput from '../../controls/TextInput';

const initialValidationData = {
  login: '',
  password: '',
  confirmPassword: '',
  email: '',
};

const initialState = {
  login: '',
  password: '',
  confirmPassword: '',
  email: '',
};

const LoginRegistrationPage = ({
  login,
  register,
  requestInProgress
}) => {
  const [validationData, setValidationData] = useState({ ...initialValidationData });
  const [state, setState] = useState({ ...initialState });
  const location = useLocation();
  const navigate = useNavigate();
  const isRegister = location.pathname === URLS.REGISTER;
  const from = location?.state?.from?.pathname || URLS.HOME;

  const handleChange = useCallback(({ target: { value, name } }) => {
    setState({ ...state, [name]: value });
  }, [state]);

  const handleCancel = useCallback(() => {
    navigate(URLS.HOME);
  }, [navigate]);

  const handleSubmit = useCallback(e => {
    e && e.preventDefault();
    const data = {
      login: state.login,
      password: state.password,
    };
    const submit = isRegister ? register : login;
    if (isRegister) {
      data.email = state.email;
      data.confirmPassword = state.confirmPassword;
    }
    submit(data, (responseData) => {
      if (responseData && responseData.isValid === false) {
        setValidationData(responseData.data);
      } else {
        navigate(from, { replace: true });
      }
    });
  }, [
    from,
    isRegister,
    login,
    navigate,
    register,
    state,
  ]);

  const handleKeyPress = useCallback(({ key }) => {
    if (key==='Enter') {
      handleSubmit(null);
    }
  }, [handleSubmit]);

  const handleChangeMode = useCallback(() => {
    setState({ ...initialState });
    setValidationData({ ...initialValidationData });
    const href = isRegister ? URLS.LOGIN : URLS.REGISTER;
    navigate(href);
  }, [isRegister, navigate]);

  const getTextInput = useCallback((field, label, type = 'text') => {
    return (
      <TextInput
        disabled={requestInProgress}
        id={field}
        label={label}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        autoComplete="off"
        type={type}
        validationMessage={validationData[field]}
        value={state[field]}
      />
  )}, [handleChange, handleKeyPress, requestInProgress, state, validationData]);

  const loginField = useMemo(() => (
    getTextInput('login', 'Login')
  ), [getTextInput]);

  const passwordField = useMemo(() => (
    getTextInput('password', 'Password', 'password')
  ), [getTextInput]);

  const confirmPasswordField = useMemo(() => (
    getTextInput('confirmPassword', 'Confirm password', 'password')
  ), [getTextInput]);

  const emailField = useMemo(() => (
    getTextInput('email', 'E-mail', 'email')
  ), [getTextInput]);

  return (
    <Card sx={{ width: '100%', maxWidth: 500, m: '0 auto', p: 2 }}>
      <form action="" name="auth">
        <CardContent>
          <Typography variant="h5">
            {isRegister ? 'Registration' : 'Login'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {loginField}
            </Grid>
            {isRegister && (
              <Grid item xs={12}>
                {emailField}
              </Grid>
            )}
            <Grid item xs={12}>
              {passwordField}
            </Grid>
            {isRegister && (
              <Grid item xs={12}>
                {confirmPasswordField}
              </Grid>
            )}
          </Grid>
        </CardContent>
        <CardActions sx={{ mt: 3 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button variant="text" onClick={handleChangeMode} size="small">
              {isRegister ? 'Already registered' : 'Not registered yet'}
            </Button>
            <Box>
              <Button
                aria-label="Cancel"
                color="inherit"
                disableElevation
                size="small"
                onClick={handleCancel}
                disabled={requestInProgress}
                sx={{ mr: 2 }}
                variant="contained"
              >
                <HighlightOffIcon sx={{ mr: 1 }} />
                Cancel
              </Button>
              <Button
                aria-label="Submit"
                color="primary"
                disableElevation
                size="small"
                onClick={handleSubmit}
                disabled={requestInProgress}
                variant="contained"
              >
                <LoginIcon sx={{ mr: 1 }} />
                Submit
              </Button>
            </Box>
          </Grid>
        </CardActions>
      </form>
    </Card>
  );
};

const mapStateToProps = state => ({
  requestInProgress: authRequestInProgressSelector(state),
});

const mapDispatchToProps = {
  login: loginAction,
  register: registerAction,
  addMessage: addMessageAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginRegistrationPage);
