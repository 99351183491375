import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import FormModes from '../../../../constants/form-modes';

const AdminWordsContext = createContext({
  mode: FormModes.NONE,
  handleEdit: () => {},
});

const useAdminWordsContext = () => useContext(AdminWordsContext);

const AdminWordsContextProvider = ({ children }) => {
  const { wordUrl } = useParams();
  const [mode, setMode] = useState(FormModes.NONE);

  const handleEdit = useCallback(() => {
    setMode(FormModes.EDIT);
  }, [setMode]);

  const handleCancel = useCallback(() => {
    setMode(FormModes.NONE);
  }, [setMode]);

  const value = useMemo(() => ({
    mode: wordUrl === 'new' ? FormModes.CREATE : mode,
    handleEdit,
    handleCancel,
  }), [wordUrl, mode, handleEdit, handleCancel]);

  return (
    <AdminWordsContext.Provider value={value}>
      {children}
    </AdminWordsContext.Provider>
  );
};

export {
  AdminWordsContext,
  AdminWordsContextProvider,
  useAdminWordsContext,
};
