import { Outlet } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import AdminPhrasesList from './AdminPhrasesList';
import { AdminPhrasesContextProvider } from './admin-phrases-context';

const AdminPhrases = () => {
  return (
    <AdminPhrasesContextProvider>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={5} sx={{ height: '100%' }}>
          <AdminPhrasesList />
        </Grid>
        <Grid item xs={7} sx={{ height: '100%' }}>
          <Outlet />
        </Grid>
      </Grid>
    </AdminPhrasesContextProvider>
  );
};

export default AdminPhrases;
