const createEndpointFilter = searchValue => ({ url, adminPageUrl, method }) => {
  if (searchValue) {
    const filter = searchValue.toLowerCase();
    const lowerUrl = url.toLowerCase();
    const lowerMethod = method.toLowerCase();
    const lowerAdminPageUrl = adminPageUrl.toLowerCase();

    return lowerUrl.includes(filter)
      || lowerMethod.includes(filter)
      || lowerAdminPageUrl.includes(filter);
  }

  return true;
};

export default createEndpointFilter;
