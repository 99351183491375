import { useTheme } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

const PhrasesContentTestResult = ({ data }) => {
  const theme = useTheme();

  const correctNumber = data.filter(({ correct }) => correct).length;
  const incorrectNumber = data.length - correctNumber;
  const totalNumber = data.length;
  const correctPercent = Math.round(correctNumber / totalNumber * 100);
  const correctPercentColor = correctPercent < 51
    ? theme.palette.error.main
    : correctPercent < 80
      ? theme.palette.secondary.main
      : theme.palette.success.main;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: '100%' }} aria-label="test result table">
        <TableHead>
          <TableRow>
            <TableCell>Phrase</TableCell>
            <TableCell align="right">Answer options</TableCell>
            <TableCell align="right">Answer</TableCell>
            <TableCell align="right">Result</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ id, question, answer, correct, answerOptions }) => {
            return (
              <TableRow
                key={id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="body1">
                    {question}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {answerOptions.map(option => <div key={option}>{option}</div>)}
                </TableCell>
                <TableCell align="right">
                  <Typography
                    variant="body1"
                    sx={{
                      color: correct
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                    }}
                  >
                    {answer}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {correct
                    ? <CheckIcon color="success" />
                    : <CloseIcon color="error" />
                  }
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell rowSpan="3" colSpan="2" align="center">
              <Typography variant="h3" sx={{ color: correctPercentColor }}>
                {correctPercent}%
              </Typography>
            </TableCell>
            <TableCell>Correct</TableCell>
            <TableCell align="right">{correctNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Incorrect</TableCell>
            <TableCell align="right">{incorrectNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell align="right">{totalNumber}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PhrasesContentTestResult.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
      answerOptions: PropTypes.arrayOf(PropTypes.string),
      correct: PropTypes.bool,
    }),
  ),
};

export default PhrasesContentTestResult;
