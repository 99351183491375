import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getStatistics } from '../../../../redux/actions';
import getTestConfig from '../../../../utils/get-test-config';
import { useEffect } from 'react';

const TestsNetworkService = ({ getStatisticsData }) => {
  const { unitUrl } = useParams();
  const { pathname } = useLocation();
  const testConfig = getTestConfig(pathname);
  const testType = testConfig ? testConfig.id : '';

  useEffect(() => {
    if (unitUrl && pathname && testType) {
      getStatisticsData(testType, unitUrl);
    }
  }, [unitUrl, pathname, testType, getStatisticsData]);

  return null;
};

const mapDispatchToProps = {
  getStatisticsData: getStatistics,
};

export default connect(null, mapDispatchToProps)(TestsNetworkService);
