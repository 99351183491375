import { Outlet } from 'react-router-dom';

import Grid from '@mui/material/Grid';

import AdminUsersList from './AdminUsersList';
import { AdminUserContextProvider } from './admin-users-context';

const AdminUsers = () => {
  return (
    <AdminUserContextProvider>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={4} sx={{ height: '100%' }}>
          <AdminUsersList />
        </Grid>
        <Grid item xs={8} sx={{ height: '100%' }}>
          <Outlet />
        </Grid>
      </Grid>
    </AdminUserContextProvider>
  );
};

export default AdminUsers;
