import { connect } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';

import TextInput from '../../../controls/TextInput';
import { updatePassword } from '../../../../redux/actions/auth';

const initialState = {
  password: '',
  confirmPassword: '',
};

const initialValidationData = {
  password: '',
  confirmPassword: '',
};

const ChangePasswordModal = ({ open, onClose, submit }) => {
  const [state, setState] = useState({ ...initialState });
  const [validationData, setValidationData] = useState({ ...initialValidationData });

  const handleChange = useCallback(({ target: { value, name } }) => {
    setState({ ...state, [name]: value });
  }, [state]);

  const getTextInput = useCallback((field, label, type = 'text') => {
    // const disabled = requestInProgress || (mode === NONE);
    const disabled = false;

    return (
      <TextInput
        disabled={disabled}
        id={field}
        label={label}
        onChange={handleChange}
        type={type}
        validationMessage={validationData[field]}
        value={state[field]}
      />
    )}, [handleChange, state, validationData]);

  const passwordField = useMemo(() => (
    getTextInput('password', 'Password', 'password')
  ), [getTextInput]);

  const confirmPasswordField = useMemo(() => (
    getTextInput('confirmPassword', 'Confirm password', 'password')
  ), [getTextInput]);

  const handleCancel = useCallback(() => {
    setState({ ...initialState });
    setValidationData({ ...initialValidationData });
    onClose();
  }, [onClose]);

  const handleSave = useCallback(() => {
    submit(state, result => {
      if (result && (result.valid === false)) {
        setValidationData(result.data);
      } else {
        handleCancel();
      }
    });
  }, [handleCancel, state, submit]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Change password form
      </DialogTitle>
      <DialogContent>
        <form action="" name="changePassword">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {passwordField}
            </Grid>
            <Grid item xs={12}>
              {confirmPasswordField}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          aria-label="Cancel"
          color="inherit"
          disableElevation
          size="small"
          onClick={handleCancel}
          disabled={false}
          sx={{ mr: 2 }}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          aria-label="Save"
          color="primary"
          disableElevation
          size="small"
          onClick={handleSave}
          disabled={false}
          sx={{ mr: 2 }}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  submit: updatePassword,
};

export default connect(null, mapDispatchToProps)(ChangePasswordModal);
