import { Outlet } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import StatisticsUnitsList from './StatisticsUnitsList';

const StatisticsType = () => {
  return (
    <Grid container spacing={2} sx={{ height: '100%' }}>
      <Grid item xs={5} sx={{ height: '100%' }}>
        <StatisticsUnitsList />
      </Grid>
      <Grid item xs={7} sx={{ height: '100%' }}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default StatisticsType;
