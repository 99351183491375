import {
  START_SCOPES_LOADING,
  ERROR_SCOPES_LOADING,
  SUCCESS_SCOPES_LOADING,
  START_SCOPE_SAVING,
  ERROR_SCOPE_SAVING,
  SUCCESS_SCOPE_SAVING,
} from '../../actions/action-types';

import {
  startEntityLoading,
  errorEntityLoading,
  successEntityLoading,
  startEntitySaving,
  errorEntitySaving,
  successEntitySaving,
  createEntityReducer,
} from '../../../utils/reducer/reducer-utils';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const resolvers = {
  [START_SCOPES_LOADING]: startEntityLoading,
  [ERROR_SCOPES_LOADING]: errorEntityLoading,
  [SUCCESS_SCOPES_LOADING]: successEntityLoading,
  [START_SCOPE_SAVING]: startEntitySaving,
  [ERROR_SCOPE_SAVING]: errorEntitySaving,
  [SUCCESS_SCOPE_SAVING]: successEntitySaving,
};

const scopesReducer = createEntityReducer({
  resolvers,
  initialState,
});

export default scopesReducer;
