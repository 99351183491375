const userScopesSelector = state => state.userScopes;

const userScopesDataSelector = state => userScopesSelector(state).data;

const userScopesLoadingSelector = state => userScopesSelector(state).loading;

const userScopesErrorSelector = state => userScopesSelector(state).error;

export {
  userScopesDataSelector,
  userScopesLoadingSelector,
  userScopesErrorSelector,
};
